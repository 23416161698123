import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTER_URL } from "../../constants/routerUrl";
import Step1 from "./components/step1";
import Step2 from "./components/step2";
import Step3 from "./components/step3";
import Step4 from "./components/step4";
import Step5 from "./components/step5";
import Step6 from "./components/step6";
import { IFinalData } from "../../models/registeration";
import Step1Header from "./components/registrationHeaders/step1Header";
import Step2Header from "./components/registrationHeaders/step2Header";
import Step3Header from "./components/registrationHeaders/step3Header";
import Step4Header from "./components/registrationHeaders/step4Header";
import Step5Header from "./components/registrationHeaders/step5Header";
import Step6Header from "./components/registrationHeaders/step6Header";
import moment from "moment";

const Registration = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  //accept data from step3
  const [dataForChild2, setDataForChild2] = useState("");
  const [dataForChild4, setDataForChild4] = useState("");
  const [dataForChild5, setDataForChild5] = useState("");
  const [dataForChild6, setDataForChild6] = useState("");
  const [verifiedData, setVerifiedData] = useState({
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  // const verifiedEmailData = () => {
  //   setVerifiedData();
  // };

  const handleDataFor2 = (step1data: any) => {
    setDataForChild2(step1data);
  };

  const handleDataFor4 = (step3data: any) => {
    setDataForChild4(step3data);
  };

  const handleDataFor5 = (step4data: any) => {
    setDataForChild5(step4data);
  };

  const handleDataFor6 = (step5data: any) => {
    setDataForChild6(step5data);
  };

  const [ready, setReady] = useState<IFinalData>({
    id: 0,
    firstName: "",
    isAgreeTerms: false,
    lastName: "",
    password: "",
    email: "",
    mobileNo: "",
    gender: 0,
    dob: "",
    weight: 0,
    focusOn: [],
    preCondition: [],
    focusOnName: "",
    feet: 0,
    inch: 0,
    height: "",
  });

  //function to verify mail id
  const verify = () => {
    navigate(ROUTER_URL.VERIFY_OTP);
  };

  //function to login, only if the user is already registered
  const login = () => {
    navigate(ROUTER_URL.LOGIN);
  };

  const [errors, setErrors] = useState<any>(undefined);
  const [data, setData] = useState<IFinalData>({
    id: 0,
    firstName: "",
    isAgreeTerms: false,
    lastName: "",
    password: "",
    email: "",
    mobileNo: "",
    gender: "",
    dob: moment(new Date()).format("MM-DD-yyyy"),
    weight: "",
    focusOn: [],
    preCondition: [],
    focusOnName: "",
    feet: 0,
    inch: 0,
    height: "",
  });

  var metaDataSaved: IFinalData = {
    firstName: data.firstName,
    isAgreeTerms: data.isAgreeTerms,
    lastName: data.lastName,
    password: data.password,
    email: data.email,
    mobileNo: data.mobileNo,
    gender: data.gender,
    dob: data.dob,
    weight: data.weight,
    focusOn: data.focusOn,
    preCondition: data.preCondition,
    focusOnName: data.focusOnName,
    feet: data.feet,
    inch: data.inch,
    height: data.height,
  };

  // useEffect(() => {
  //   // Perform page reload once the component mounts
  //   window.location.reload();
  // }, []); 

  return (
    <>
      {" "}
      <div className="login-wrap">
        <header>
          <a
            href="#"
            draggable="false"
            style={{ pointerEvents: "none" }}
            onDragStart={(e) => e.preventDefault()}
          >
            <img src="assets/images/logo.webp" alt="" draggable="false" />
          </a>
        </header>
        <main>
          {step == 1 ? (
            <Step1Header currentStep={step} changeStep={setStep} />
          ) : null}
          {step == 2 ? (
            <Step2Header currentStep={step} changeStep={setStep} />
          ) : null}
          {step == 3 ? (
            <Step3Header currentStep={step} changeStep={setStep} />
          ) : null}
          {step == 4 ? (
            <Step4Header currentStep={step} changeStep={setStep} />
          ) : null}
          {step == 5 ? (
            <Step5Header currentStep={step} changeStep={setStep} />
          ) : null}
          {step == 6 ? (
            <Step6Header currentStep={step} changeStep={setStep} />
          ) : null}
          {/* <Step1Header currentStep={step} changeStep={setStep} /> */}

          {step == 1 ? (
            <>
              <Step1
                currentStep={step}
                changeStep={setStep}
                sendDataToParent={handleDataFor2}
                verifiedEmailData={verifiedData}
              />
            </>
          ) : null}
          {step == 2 ? (
            <>
              <Step3
                passData={data}
                setMainData={setData}
                currentStep={step}
                changeStep={setStep}
                oldData={ready}
                newData={setReady}
                sendDataToParent={handleDataFor4}
                getMail={dataForChild2}

                // changeData={data}
                // metaDataSaved={metaDataSaved}
              />
              {/* <Step2
                currentStep={step}
                changeStep={setStep}
                getMail={dataForChild2}
              /> */}
            </>
          ) : null}
          {step == 3 ? (
            <>
              <Step2
                currentStep={step}
                changeStep={setStep}
                getMail={dataForChild2}
                getDataOfUserForOTP={data}
              />
              {/* <Step3
                passData={data}
                setMainData={setData}
                currentStep={step}
                changeStep={setStep}
                oldData={ready}
                newData={setReady}
                sendDataToParent={handleDataFor4}
                getMail={dataForChild2}

                // changeData={data}
                // metaDataSaved={metaDataSaved}
              /> */}
            </>
          ) : null}
          {step == 4 ? (
            <>
              <Step4
                passData={data}
                setMainData={setData}
                currentStep={step}
                changeStep={setStep}
                step3data={dataForChild4}
                sendDataToParent={handleDataFor5}
                // changeData={metaDataSaved}
                // metaDataSaved={metaDataSaved}
                oldData={data}
                newData={setReady}
              />
            </>
          ) : null}
          {step == 5 ? (
            <>
              <Step5
                passData={data}
                currentStep={step}
                setMainData={setData}
                changeStep={setStep}
                changeData={data}
                metaDataSaved={metaDataSaved}
                step4data={dataForChild5}
                sendDataToParent={handleDataFor6}
              />
            </>
          ) : null}
          {step == 6 ? (
            <>
              <Step6
                passData={metaDataSaved}
                currentStep={step}
                setMainData={setData}
                changeStep={setStep}
                changeData={metaDataSaved}
                metaDataSaved={metaDataSaved}
                step5data={dataForChild6}
              />
            </>
          ) : null}
        </main>
        <footer>
          <p> @2023 Healthogenics</p>
        </footer>
      </div>
    </>
  );
};

export default Registration;
