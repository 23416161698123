import React, { useEffect, useState } from "react";
import Header from "./component/header";
import Footer from "./component/footer";
import { connect } from "react-redux";
import DataTable from "../../common-components/dataTable";
import {
  IFilterData,
  ISurveyList,
  ISurveyListDisplay,
} from "../../models/admin/surveyList";
import { useNavigate } from "react-router-dom";
import { ROUTER_URL } from "../../constants/routerUrl";
import {
  getFullProfileAdmin,
  getSurveyList,
  getSurveyListExport,
} from "../../actions/surveyListActionCreator";
import FloatingLabelDropDown from "../../common-components/floatingLabelDropdown";
import {
  getFocuses,
  getMedicalConditions,
} from "../../actions/common/commonActionCreator";
import FullProfileAdmin from "./component/fullProfileAdmin";
import { pageSize } from "../../constants/constants";

const SurveyList = (props: ISurveyListDisplay) => {
  const {
    surveyListData,
    surveyListExportData,
    getSurveyList,
    getSurveyListExport,
    getMedicalConditions,
    getFocuses,
    focuses,
    medicalConditions,
  } = props;
  const [selectedPage, setSelectedPage] = useState(1);
  const [isFullProfileVisible, setIsFullProfileVisible] = useState(false);
  const [userid, setUserid] = useState(0);
  const [keyWord, setKeyWord] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState<IFilterData>({
    focuson: 0,
    conditions: 0,
    keyword: "",
  });

  //function to execute onChange event
  const onChange = (
    event:
      | any
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (event.keyCode == 13) {
      setKeyWord(true);
    } else {
      setKeyWord(false);
      const _data = {
        ...data,
        [event.target.name]: event.target.value,
      };

      setData(_data);
    }
  };

  // fields which are to be displayed on the table.
  const columns = [
    {
      label: "Name",
      prop: "fullName",
    },
  ];
  // executes when edit button is clicked
  const viewItem = (id: any) => {
    setUserid(id);
    setIsFullProfileVisible(true);
  };

  // executes when a page number is set or clicked
  const onPageChange = (pageNo: number) => {
    setSelectedPage(pageNo);
  };

  const exportSurveyList = () => {
    getSurveyListExport(data.keyword, data.focuson, data.conditions);
  };

  useEffect(() => {
    // if (keyWord === true) {
    //   getSurveyList(
    //     selectedPage,
    //     pageSize,
    //     data.keyword,
    //     data.focuson,
    //     data.conditions
    //   );
    // } else {
    //   getSurveyList(selectedPage, pageSize, "", data.focuson, data.conditions);
    // }

    getSurveyList(
      data.keyword == "" ? selectedPage : 1,
      pageSize,
      data.keyword,
      data.focuson,
      data.conditions
    );
  }, [selectedPage, data.conditions, data.focuson, data.keyword, keyWord]);

  useEffect(() => {
    getMedicalConditions();
    getFocuses();
  }, []);

  return (
    <>
      <Header />
      <main>
        <div className="container">
          <div className="admin-title">
            <h4>Survey List</h4>
          </div>
          <div className="filter-wrap d-flex align-items-center justify-content-between mb-3">
            <div className="search">
              <input
                type="text"
                placeholder="Search"
                value={data.keyword}
                name="keyword"
                onChange={onChange}
                onKeyUp={onChange}
              />{" "}
              <button>
                <img src="assets/images/icons/search.svg" alt="" />
              </button>
            </div>
            <div className="filter-card btn-wrap d-flex align-items-center justify-content-end gap-10">
              <div className="form-group pad-right m-0">
                <FloatingLabelDropDown
                  label={"Focus"}
                  onChange={onChange}
                  isMandatory={false}
                  value={data.focuson}
                  name={"focuson"}
                  optionsList={focuses}
                  // placeholder="Focus"
                  // id="Focus"
                />
                {/* <label htmlFor="Focus">Focus</label> */}
              </div>
              <div className="form-group pad-right m-0">
                <FloatingLabelDropDown
                  label={"Medical Conditions"}
                  onChange={onChange}
                  isMandatory={false}
                  value={data.conditions}
                  name={"conditions"}
                  optionsList={medicalConditions}
                  // placeholder="Medical"
                  // id="Medical"
                />
                {/* <label htmlFor="Medical">Medical Conditions</label> */}
              </div>
              <button className="btn-primary" onClick={exportSurveyList}>
                Export
              </button>
            </div>
          </div>
          <div className="data-table mb-3">
            <DataTable
              columns={columns}
              noDataFound={"No data found"}
              tabelData={surveyListData}
              onPageChange={onPageChange}
              showDrilldown={true}
              bgColor={"status"}
              selectedPage={selectedPage}
              viewItem={viewItem}
              isStatusRequired={true}
              isFocus={true}
              isMedicalCondition={true}
              isHeight={true}
              isWeight={true}
              paginateItem={true}
            />
          </div>
        </div>
      </main>
      <Footer />
      <FullProfileAdmin
        isProfileVisible={isFullProfileVisible}
        userid={userid}
        onClose={() => setIsFullProfileVisible(false)}
      />
    </>
  );
};

const mapDispatchToProps = {
  getSurveyList: (
    pageNumber: number,
    pageSize: number,
    keyword: string,
    focuson?: number,
    conditions?: number
  ) => getSurveyList(pageNumber, pageSize, keyword, focuson, conditions),
  getSurveyListExport: (
    keyword?: string,
    focuson?: number,
    conditions?: number
  ) => getSurveyListExport(keyword, focuson, conditions),
  getMedicalConditions: () => getMedicalConditions(),
  getFocuses: () => getFocuses(),
};

function mapStateToProps(state: any) {
  return {
    surveyListData: state.adminSurveyList?.surveyListData,
    surveyListExportData: state.adminSurveyList?.surveyListExportData,
    focuses: state.commonDropdowns?.focuses,
    medicalConditions: state.commonDropdowns?.medicalConditions,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyList);
