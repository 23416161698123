import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FocusForOthers from "./components/focusForOthers";
import FocusForOtherHeader from "./components/registerForOthersHeader/focusForOtherHeader";
import PreConditionForOtherHeader from "./components/registerForOthersHeader/preConditionForOtherHeader";
import BodyCompositionForOtherHeader from "./components/registerForOthersHeader/bodyCompositionForOtherHeader";
import { IFinalDataForOthers } from "../../models/barcodeRegister";
import PreConditionForOthers from "./components/preConditionForOthers";
import BodyCompositionForOthers from "./components/bodyCompositionForOthers";
import moment from "moment";

const RegistrationForOthers = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  var commonData: IFinalDataForOthers = {
    userId: 0,
    barcode: "",
    fullName: "",
    gender: "",
    dob: moment(new Date()).format("MM-DD-yyyy"),
    weight: "",
    focusOn: [],
    preCondition: [],
    focusOnName: "",
    height: "",
  };

  return (
    <>
      <div className="login-wrap">
        <header>
          <a
            href="#"
            draggable="false"
            style={{ pointerEvents: "none" }}
            onDragStart={(e) => e.preventDefault()}
          >
            <img src="assets/images/logo.webp" alt="" draggable="false" />
          </a>
        </header>
        <main>
          {step == 1 ? <FocusForOtherHeader /> : null}
          {step == 2 ? <PreConditionForOtherHeader /> : null}
          {step == 3 ? <BodyCompositionForOtherHeader /> : null}

          {step == 1 ? (
            <>
              <FocusForOthers
                currentStep={step}
                changeStep={setStep}
                step3data={commonData}
              />
            </>
          ) : null}
          {step == 2 ? (
            <>
              <PreConditionForOthers currentStep={step} changeStep={setStep} />
            </>
          ) : null}
          {step == 3 ? (
            <>
              <BodyCompositionForOthers
                currentStep={step}
                changeStep={setStep}
              />
            </>
          ) : null}
        </main>
        <footer>
          {/* <p>&copy; 2023 Healthogenics.</p> */}
          <p>@ 2023 Healthogenics</p>
        </footer>
      </div>
    </>
  );
};

export default RegistrationForOthers;
