import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getMedicalConditions } from "../../../actions/common/commonActionCreator";
import { ICommon } from "../../../models/registeration";
import {
  IFinalDataForOthers,
  IPreConditionForOthersDisplay,
} from "../../../models/barcodeRegister";
import { useNavigate } from "react-router-dom";
import { validateFields } from "../../../helper/joi-validation";
import Joi from "joi";

const PreConditionForOthers = (props: IPreConditionForOthersDisplay) => {
  const navigate = useNavigate();
  const { getMedicalConditions, medicalConditions, changeStep, currentStep } =
    props;
  const [items, setItems] = useState<any>([]);
  const [errors, setErrors] = useState<any>(undefined);
  let savedData: any = localStorage.getItem("savedDataForOthers");
  const step4data: any = JSON.parse(savedData);

  const [data, setData] = useState(step4data);

  useEffect(() => {
    getMedicalConditions();
  }, []);

  const saveRequest = async () => {
    var savedData: IFinalDataForOthers = {
      ...data,
      preCondition: data.preCondition,
    };
    localStorage.setItem("savedDataForOthers", JSON.stringify(savedData));

    await changeStep(currentStep + 1);
  };

  const schema = Joi.object().keys({
    gender: Joi.allow(),
    dob: Joi.allow(),
    weight: Joi.allow(),
    focusOn: Joi.array().min(1).required().label("Select atleast one option"),
    preCondition: Joi.array()
      .min(1)
      .required()
      .label("Select atleast one option"),
    focusOnName: Joi.allow(),
    height: Joi.allow(),
  });

  const onSubmit = async () => {
    data.preCondition = items;
    setData({ ...data, preCondition: [...items] });
    const validationErrors = validateFields(data, schema);
    if (validationErrors == undefined) {
      saveRequest();
    } else {
      setErrors(validationErrors);
    }
  };

  const addMedicalConditions = (id: any) => {
    if (id !== 5) {
      if (items.includes(id)) {
        setItems(items.filter((it: number) => it !== 5 && it !== id));
      } else {
        setItems([...items.filter((it: number) => it != 5), id]);
      }
    } else {
      setItems([5]);
    }
  };

  return (
    <>
      <div className="login-card">
        <div className="title">
          <h3>One More Question</h3>
          <p>
            "Do you have any of the following conditions for{" "}
            <strong>{data.fullName}</strong>? This helps our doctors recommend
            the right tests for <strong>{data.fullName}</strong>
          </p>
        </div>
        <div className="row">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            {medicalConditions?.map((element: ICommon, index: number) => {
              return (
                <>
                  <div className="checkbox-round">
                    <input
                      type="checkbox"
                      // id="multi-check1"
                      name="id"
                      checked={items && items.includes(element.id)}
                      onClick={(e) => addMedicalConditions(element.id)}
                    />
                    <label htmlFor="multi-check1">{element.name}</label>
                  </div>
                </>
              );
            })}
          </div>
          {errors && errors.preCondition && (
            <div className="errors">{errors.preCondition}</div>
          )}

          <div className="form-group mt-4 mb-3">
            <button onClick={() => onSubmit()}>Next</button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  getMedicalConditions: () => getMedicalConditions(),
};

function mapStateToProps(state: any) {
  return {
    medicalConditions: state.commonDropdowns?.medicalConditions,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreConditionForOthers);
