import { IApiResponse } from "../../models/IAPIResponse";
import { urlBinder } from "../../helper/url-binder";
import apiService from "../../helper/api-service";
import { ICommon, ITestPurchase } from "../../models/admin/testPurchased";
import { TestPurchase } from "../../constants/apiUrl";

export default new (class testPurchaseService {
  public getTestPurchases = async (
    pageNumber: number,
    pageSize: number,
    keyword?: string,
    fromDate?: string,
    toDate?: string
  ): Promise<IApiResponse<Array<ITestPurchase>>> => {
    try {
      let endPoint = urlBinder(TestPurchase.GET_TEST_PURCHASE, [
        pageNumber.toString(),
        pageSize.toString(),
        keyword,
        fromDate,
        toDate
      ]);
      const response = await apiService.get(endPoint);
      return response && (response.data as IApiResponse<Array<ITestPurchase>>);
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<Array<ITestPurchase>>;
      } else {
        throw error;
      }
    }
  };

  public getTestPurchaseOfIndividual = async (
    id: number
  ): Promise<IApiResponse<Array<ITestPurchase>>> => {
    try {
      let endPoint = urlBinder(TestPurchase.GET_TEST_PURCHASE_OF_INDIVIDUAL, [
        `${id}`,
      ]);
      const response = await apiService.get(endPoint);
      return response.data as IApiResponse<Array<ITestPurchase>>;
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<Array<ITestPurchase>>;
      } else {
        throw error;
      }
    }
  };

  public getTestPurchaseExport = async (
    pageNumber: number,
    pageSize: number,
    keyword?: string,
    fromDate?: string,
    toDate?: string
  ): Promise<IApiResponse<any>> => {
    try {
      let endPoint = urlBinder(TestPurchase.GET_TEST_PURCHASE_EXPORT, [
        pageNumber.toString(),
        pageSize.toString(),
        keyword,
        fromDate, 
        toDate
      ]);
      const response = await apiService.get(endPoint, {
        responseType: "blob",
      });
      return response?.data as IApiResponse<any>;
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<any>;
      } else {
        throw error;
      }
    }
  };
})();
