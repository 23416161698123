import { alertConstants } from "../helper/sweet-alert";
import registerKitService from "../apiServices/registerKit/registerKitService";
import {
  IBarcodeRegister,
  IFinalDataForOthers,
} from "../models/barcodeRegister";
import { setOthers } from "../store/slices/registerNewKit/registerNewKit";

export function registerBarcode(data: IBarcodeRegister, callback: Function) {
  return async function (dispatch: any, getState: any) {
    let response = await registerKitService.registerNewBarcode(data, callback);
    if (response === "needToHandleError") {
      callback(alertConstants.failed);
    } else if (
      response?.message === "Invalid Barcode Number!" &&
      response?.success === true
    ) {
      callback(response.message);
    } else if (response?.message !== "" && response?.success === true) {
      callback(response?.message);
    } else {
      callback(alertConstants.failed);
    }
  };
}

export function registerKitForSelf(
  isSelfregistered: boolean,
  name: string,
  barcode: string,
  data: IFinalDataForOthers,
  callback: any
) {
  return async function (dispatch: any, getState: any) {
    let response = await registerKitService.registerKitForSelf(
      isSelfregistered,
      name,
      barcode,
      data
    );
    if (response == true) {
      callback(response);
    } else {
      callback(false);
    }
  };
}

export function registerForOthers(
  data: IFinalDataForOthers,
  callback: Function
) {
  return async function (dispatch: any, getState: any) {
    let response: boolean = await registerKitService.registerForOthers(
      data,
      callback
    );
    if (response === true) {
      callback(alertConstants.success);
    } else {
      callback(alertConstants.errror);
    }
  };
}

export function getOthers() {
  return async function (dispatch: any, getState: any) {
    let response = await registerKitService.getOthers();
    if (response !== null) {
      dispatch(setOthers(response));
    } else {
      console.log("error");
    }
  };
}
