import { createSlice } from "@reduxjs/toolkit";
import { ITestPurchasedSlice } from "../../../models/admin/testPurchased";

const initialTestPurchasedState: ITestPurchasedSlice = {
  testPurchasedData: undefined,
  testPurchasedExportData: undefined,
  testPurchasedFilter: undefined,
  testPurchasedOfIndividual: undefined,
  testPurchasedStatusFilter: undefined,
};

const testPurchasedSlice = createSlice({
  name: "adminTestPurchase",
  initialState: initialTestPurchasedState,
  reducers: {
    setTestPurchasedData: (state, action: { payload: any }) => {
      state.testPurchasedData = action.payload;
    },
    setTestPurchasedExportData: (state, action: { payload: any }) => {
      state.testPurchasedExportData = action.payload;
    },
    setTestPurchasedFilter: (state, action: { payload: any }) => {
      state.testPurchasedFilter = action.payload;
    },
    setTestPurchasedOfIndividual: (state, action: { payload: any }) => {
      state.testPurchasedOfIndividual = action.payload;
    },
    setTestPurchasedStatusFilter: (state, action: { payload: any }) => {
      state.testPurchasedStatusFilter = action.payload;
    },
  },
});

export const {
  setTestPurchasedData,
  setTestPurchasedExportData,
  setTestPurchasedFilter,
  setTestPurchasedOfIndividual,
  setTestPurchasedStatusFilter,
} = testPurchasedSlice.actions;

const testPurchasedReducer = testPurchasedSlice.reducer;

export type testPurchasedState = ReturnType<typeof testPurchasedReducer>;

export default testPurchasedReducer;
