import { createSlice } from "@reduxjs/toolkit";
import { ICommonSlice } from "../../../models/ICommon";
import { IRegisterNewKitSlice } from "../../../models/barcodeRegister";

const initialRegisterNewKitState: IRegisterNewKitSlice = {
  others: undefined,
};

const registerNewKitSlice = createSlice({
  name: "registerNewKit",
  initialState: initialRegisterNewKitState,
  reducers: {
    setOthers: (state, action: { payload: any }) => {
      state.others = action.payload;
    },
  },
});

export const { setOthers } = registerNewKitSlice.actions;

const registerNewKitReducer = registerNewKitSlice.reducer;

export type registerNewKitSliceState = ReturnType<typeof registerNewKitReducer>;

export default registerNewKitReducer;
