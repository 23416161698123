import Axios from "axios";
import { apiConfig } from "../constants/apiConfig";
const baseURL = `${apiConfig.siteUrl}`;
class APIService {
  private axiosInstance: any;

  constructor() {
    this.axiosInstance = Axios.create({});
    this.axiosInstance.interceptors.response.use(
      (response: any) => {
        if (response.status === 401) {
          console.log("401");
          localStorage.clear();
          sessionStorage.clear();
          window.location.reload();
          window.location.href = "html-pages/Unauthorized.html";
        }
        return response;
      },
      (error: any) => {
        if (error?.response?.status === 401) {
          console.log("other");
          localStorage.clear();
          sessionStorage.clear();
          window.location.reload();
          window.location.href = "html-pages/Unauthorized.html";
        }
      }
    );
  }

  getConfig = () => {
    let loggedUser = localStorage.getItem("loggedUser");
    //trials
    // localStorage.setItem("accessToken", accessToken);
    // localStorage.setItem("loggedUser", response?.accessToken);
    // let token = sessionStorage.getItem("token");
    // console.log(token);
    let token = localStorage.getItem("access_token");

    // let token= localStorage.setItem(access)
    // if (loggedUser) {
    //   let userObj = JSON.parse(loggedUser);
    //   token = userObj.access_token;
    // }
    if (token) {
      return {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
    } else {
      return null; //TODO: SYED, need to test this, to make sure the API accepts null or emoty object.
    }
  };

  get = (url: string, additionalConfigs?: object) => {
    return this.axiosInstance.get(baseURL + url, {
      ...this.getConfig(),
      ...additionalConfigs,
    });
  };

  post = (url: string, data = {}, additionalConfigs?: object) => {
    return this.axiosInstance.post(baseURL + url, data, {
      ...this.getConfig(),
      ...additionalConfigs,
    });
  };

  patch = (url: string, data = {}, additionalConfigs?: object) => {
    return this.axiosInstance.patch(baseURL + url, data, {
      ...this.getConfig(),
      ...additionalConfigs,
    });
  };

  put = (url: string, data = {}) => {
    return this.axiosInstance.put(baseURL + url, data, this.getConfig());
  };

  delete = (url: string, data = {}) => {
    return this.axiosInstance.delete(baseURL + url, this.getConfig());
  };

  postFormData = async (url: string, formData: FormData) => {
    const options = {
      url: url,
      method: "POST",
      headers: {
        ...this.getConfig(),
        Accept: "application/json",
        "Content-Type": "multipart/form-data;",
      },
    };
    return await this.axiosInstance.post(baseURL + url, formData, options);
  };
}

export default new APIService();
