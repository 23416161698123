import loginService from "../apiServices/login/loginService";
import { alertConstants } from "../helper/sweet-alert";
import { IResetPassword } from "../models/admin/userList";
import { ILogin } from "../models/login";
import jwt from "jwt-decode";
import {
  IFinalData,
  IHubsportData,
  IHubspotCreateData,
  IRegisterMailData,
  IStep2,
  IVerifiedEmail,
} from "../models/registeration";
import { setHubSpotData } from "../store/slices/register/register";
import { setEmailData } from "../store/slices/login/login";

export function createLogin(data: ILogin, callback: Function) {
  return async function (dispatch: any, getState: any) {
    let response = await loginService.createLogin(data, callback);
    if (response === "Invalid email or password") {
      callback(alertConstants.warning);
    } else if (response.token === undefined || response.token === "") {
      callback(alertConstants.errror);
    } else if (response.token !== null) {
      localStorage.setItem("access_token", response.token);
      const tokenData: any = jwt(response.token);
      const { email, role } = tokenData;
      localStorage.setItem("Role", tokenData.role);
      localStorage.setItem("Email", tokenData.email);
      //trial
      // let secret = localStorage.getItem("access_token");
      // let secretUser = localStorage.getItem("Role");
      // let secretRole = localStorage.getItem("Email");
      callback(alertConstants.success);
    }
  };
}

export function resetPassword(email: string, callback: Function) {
  return async function (dispatch: any, getState: any) {
    let response = await loginService.resetPassword(email, callback);
    if(response===true){
      callback(true);
    }else {
      callback(false);
    }
    // if (response === "needToHandle") {
    //   callback(alertConstants.errror);
    // } else if (response?.data !== "") {
    //   localStorage.setItem("forgotPasswordToken", response?.data);
    //   callback(alertConstants.success);
    // } else {
    //   callback(alertConstants.errror);
    // }
  };
}

export function changePassword(
  email: string,
  password: string,
  forgotpasswordtoken: string | (string | null)[] | null,
  callback: Function
) {
  return async function (dispatch: any, getState: any) {
    let response = await loginService.confirmPassword(
      email,
      password,
      forgotpasswordtoken,
      callback
    );
    if (response === true) {
      callback(alertConstants.success);
    } else {
      callback(alertConstants.errror);
    }
  };
}

export function register(data: IFinalData, callback: Function) {
  return async function (dispatch: any, getState: any) {
    let response: boolean = await loginService.register(data, callback);
    if (response === true) {
      callback(response);
    } else if (response === false) {
      callback(response);
    } else {
      callback(alertConstants.failed);
    }
    // if (response === true) {
    //   callback(alertConstants.success);
    // } else {
    //   alertConstants.errror;
    // }
  };
}

export function createHubSpotData(
  email: boolean,
  sms: boolean,
  data: IHubspotCreateData,
  callback: Function
) {
  return async function (dispatch: any, getState: any) {
    let response: boolean = await loginService.hubspotDataCreation(
      email,
      sms,
      data,
      callback
    );
    if (response === true) {
      callback(response);
    } else if (response === false) {
      callback(response);
    } else {
      callback(alertConstants.failed);
    }
  };
}

export function resendOTP(
  email: string,
  data: IHubspotCreateData,
  callback: Function
) {
  return async function (dispatch: any, getState: any) {
    let response: any = await loginService.resendOTP(email, data, callback);
    if (response?.isMailidAlreadyExist === false) {
      callback(response?.isMailidAlreadyExist); //new user
    } else if (response?.isMailidAlreadyExist === false) {
      callback(response?.isMailidAlreadyExist); //already registered
    } else {
      callback(alertConstants.failed);
    }
  };
}

export function getVerifiedEmail(email: string) {
  return async function (dispatch: any, getState: any) {
    let response = await loginService.getVerifiedEmail(email);
    if (response?.status === 200) {
      if (response?.data?.isMailidAlreadyExist === true) {
        dispatch(setEmailData(response?.data));
      } else if (response?.data?.status === 200) {
        dispatch(setEmailData(response?.data?.data));
      } else {
      }
      // let _data: IVerifiedEmail = {
      //   testResultData: response?.data,
      //   message: response?.message,
      //   noDealData: "DealIds is null",
      // };
    } else if (response?.data?.isMailidAlreadyExist === false) {
      dispatch(false);
    }
  };
}

export function registerMail(email: string, callback: Function) {
  return async function (dispatch: any, getState: any) {
    let response = await loginService.registerEmail(email);
    if (response.isMailidAlreadyExist === false) {
      callback(alertConstants.success);
    } else {
      callback(alertConstants.errror);
    }
  };
}

export function verifyOTP(data: IStep2, callback: Function) {
  return async function (dispatch: any, getState: any) {
    let response: any = await loginService.verifyOTP(data, callback);
    if (response === true) {
      callback(response);
    } else if (response === false) {
      callback(response);
    } else if( response?.message==="Verification failed"){
      callback("Verification failed");
    }else{
      callback(alertConstants.failed);
    }
  };
}
