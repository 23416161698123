import supplementService from "../apiServices/supplement/supplementService";
import { setSupplementsData } from "../store/slices/dashboard/supplement";

export function getSupplements(pageNumber: number, pageSize: number) {
  return async function (dispatch: any, getState: any) {
    // let dummyData = [
    //   {
    //     id: 1,
    //     productCode: "PR001",
    //     productName: "Sethu Mind: Focus",
    //     description:
    //       "Praesent vel enim ac est bibendum pulvinar non eget neque.",
    //     price: "$15.00",
    //     imageUrl: "https://www.shopify.com/in/online",
    //   },
    //   {
    //     id: 2,
    //     productCode: "PR002",
    //     productName: "Sethu Mind: Focus",
    //     description:
    //       "Praesent vel enim ac est bibendum pulvinar non eget neque.",
    //     price: "$15.00",
    //     imageUrl: "https://www.shopify.com/in/online",
    //   },
    //   {
    //     id: 3,
    //     productCode: "PR003",
    //     productName: "Sethu Mind: Focus",
    //     description:
    //       "Praesent vel enim ac est bibendum pulvinar non eget neque.",
    //     price: "$15.00",
    //     imageUrl: "https://www.shopify.com/in/online",
    //   },
    //   {
    //     id: 4,
    //     productCode: "PR003",
    //     productName: "Sethu Mind: Focus",
    //     description:
    //       "Praesent vel enim ac est bibendum pulvinar non eget neque.",
    //     price: "$15.00",
    //     imageUrl: "https://www.shopify.com/in/online",
    //   },
    //   {
    //     id: 5,
    //     productCode: "PR003",
    //     productName: "Sethu Mind: Focus",
    //     description:
    //       "Praesent vel enim ac est bibendum pulvinar non eget neque.",
    //     price: "$15.00",
    //     imageUrl: "https://www.shopify.com/in/online",
    //   },
    //   {
    //     id: 6,
    //     productCode: "PR003",
    //     productName: "Sethu Mind: Focus",
    //     description:
    //       "Praesent vel enim ac est bibendum pulvinar non eget neque.",
    //     price: "$15.00",
    //     imageUrl: "https://www.shopify.com/in/online",
    //   },
    // ];
    let response = await supplementService.getSupplements(pageNumber, pageSize);
    if (response?.success === true) {
      // if (dummyData) {
      dispatch(setSupplementsData(response.data));
      // dispatch(setSupplementsData(dummyData));
    } else {
    }
  };
}
