import React from "react";

const ContactDetails = () => {
  return (
    <>
      <div className="col-md-6 mt-3">
        <div className="d-flex justify-content-between">
          <h4>Contact Us</h4>
        </div>
        <div className="card-wrap">
          {/**BLOCK 1 */}
          <div className="card-blk border-0 ">
            <h4 className="mb-1">Healthogenics</h4>
            <div className="card-contact d-flex justify-content-between gap-15 mt-5">
              <div className="stats stats-border w-100">
                <div className="d-flex justify-content-end mb-2">
                  <img src="assets/images/icons/clr-phone.svg" alt="" />
                </div>
                <h6>
                  678-928-9212 <span>Phone number</span>
                </h6>
              </div>
              <div className="stats stats-border w-100">
                <div className="d-flex justify-content-end mb-2">
                  <img src="assets/images/icons/clr-email.svg" alt="" />
                </div>
                <h6>
                  support@healthogenics.com<span>Email</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactDetails;
