import Modal from "react-modal";
import { IBarcodeDeleteConfirmationDisplay } from "../../../models/admin/barcodeListing.ts";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { deleteBarcode } from "../../../actions/adminBarcodeListingActionCreator";

const barcodeDeleteConfirm = (props: IBarcodeDeleteConfirmationDisplay) => {
  const { isConfirmationVisible, onClose, id, deleteBarcode, barcode } = props;

  const deleteCallback = (response: any) => {
    if (response?.status === 200) {
      successfullyRegistered(response?.data);
      onClose(false);
    } else if (response === false) {
      updateFailed(response?.data);
      onClose(false);
    } else {
      somethingWentWrong();
      onClose(false);
    }
  };
  const onSubmit = () => {
    deleteBarcode(id, deleteCallback);
  };

  const updateFailed = (message: string) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        onClose(false);
      },
    });
  };

  const somethingWentWrong = () => {
    toast.error("Something Went Wrong!!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        onClose(false);
      },
    });
  };

  const successfullyRegistered = (message: string) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        onClose(false);
      },
    });
  };
  return (
    <>
      <Modal
        isOpen={isConfirmationVisible}
        className="modal fade"
        // id="profile-edit"
        // data-bs-backdrop="static"
        // data-bs-keyboard="false"
        // tabIndex={-1}
        // aria-labelledby="profile-edit"
        // aria-hidden="true"
      >
        {/* <div
          className="modal fade"
          id="profile-edit"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="profile-edit"
          aria-hidden="true"
        > */}
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body barcode-error-modal">
              <div className="card-profile">
                <div className="profile w-100 profile-dtls">
                  <h5 className="mb-1">
                    Do you want to delete the barcode {barcode}?
                  </h5>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn-primary"
                onClick={() => {
                  onSubmit();
                }}
              >
                Confirm
              </button>
              <button
                type="button"
                className="btn-primary"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};
const mapDispatchToProps = {
  deleteBarcode: (id: number, callback: Function) =>
    deleteBarcode(id, callback),
};

export default connect(null, mapDispatchToProps)(barcodeDeleteConfirm);
