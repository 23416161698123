import React, { useEffect, useState } from "react";
import Header from "./components/header/header";
import SampleRegisteration from "./components/sampleRegisteration";
import PurchaseNewKit from "./components/purchaseNewKit/purchaseNewKit";
import TestResult from "./components/testResult/testResult";
import RecommendedSupplement from "./components/recommendedSupplement";
import HowItWorks from "./components/howItWorks";
import Instruction from "./components/instruction";
import Profile from "./components/profile/profile";
import Footer from "./components/footer/footer";
import ContactDetails from "./components/contactDetails";
import TestKitInstructions from "./components/sampleRegisteration";
import HelpCenter from "./components/helpCenter";
import { IUserDashboardDisplay } from "../../models/dashboard";
import { getSettingsList } from "../../actions/adminSettingsActionCreator";
import { connect } from "react-redux";

//import $ from 'jquery';
//import Slider from "react-slick";
//var $: any;

const Dashboard = (props: IUserDashboardDisplay) => {
  const {getSettingsList, settingsData } =props;
  const [KitInstructionURL, setKitInstructionURL] = useState<string>("");
  const [HelpCenterURL, setHelpCenterURL] = useState<string>("");
  const [ShopNowURL, setShopNowURL] = useState<string>("");
  const [videoURL, setVideoURL] = useState<string>("");

//   useEffect(() => {
//     const fetchData = () => {
//         getSettingsList(1, 100, "");
//     }; 
//     const fetchURL=()=>{
//       const KitInstruction: any = settingsData?.data &&
//       settingsData.data.find((item: any) => item.name === "KitInstruction");
//       var KitInstructionLink:string=KitInstruction?.value?KitInstruction.value:"";
//       setKitInstructionURL(KitInstructionLink);
//       const HelpCenter: any =settingsData?.data &&
//       settingsData.data.find((item: any) => item.name === "HelpCenter");
//       var HelpCenterLink:string=HelpCenter?.value?HelpCenter.value:"";
//       setHelpCenterURL(HelpCenterLink);
//       const ShopNow: any =settingsData?.data &&
//       settingsData.data.find((item: any) => item.name === "ShopNow");
//       var ShopNowLink:string=ShopNow?.value?ShopNow.value:"";
//       setShopNowURL(ShopNowLink);
//       const video: any =settingsData?.data &&
//       settingsData.data.find((item: any) => item.name === "video");
//       var videoLink:string=video?.value?video.value:"";
//       setVideoURL(videoLink);
//     }
//     fetchData();
//     fetchURL()
//   const interval = setInterval(fetchData, 5 * 1000);
//     return () => clearInterval(interval);
// }, [settingsData]);

useEffect(() => {
  const fetchData = () => {
      getSettingsList(1, 100, "");
  }; 
  // const fetchURL=()=>{
  //   const KitInstruction: any = settingsData?.data &&
  //   settingsData.data.find((item: any) => item.name === "KitInstruction");
  //   var KitInstructionLink:string=KitInstruction?.value?KitInstruction.value:"";
  //   setKitInstructionURL(KitInstructionLink);
  //   const HelpCenter: any =settingsData?.data &&
  //   settingsData.data.find((item: any) => item.name === "HelpCenter");
  //   var HelpCenterLink:string=HelpCenter?.value?HelpCenter.value:"";
  //   setHelpCenterURL(HelpCenterLink);
  //   const ShopNow: any =settingsData?.data &&
  //   settingsData.data.find((item: any) => item.name === "ShopNow");
  //   var ShopNowLink:string=ShopNow?.value?ShopNow.value:"";
  //   setShopNowURL(ShopNowLink);
  //   const video: any =settingsData?.data &&
  //   settingsData.data.find((item: any) => item.name === "video");
  //   var videoLink:string=video?.value?video.value:"";
  //   setVideoURL(videoLink);
  // }
  fetchData();
  // fetchURL()
const interval = setInterval(fetchData, 5 * 1000);
  return () => clearInterval(interval);
}, []);


useEffect(() => {
  const KitInstruction: any = settingsData?.data &&
  settingsData.data.find((item: any) => item.name === "KitInstruction");
  var KitInstructionLink:string=KitInstruction?.value?KitInstruction.value:"";
  setKitInstructionURL(KitInstructionLink);
  const HelpCenter: any =settingsData?.data &&
  settingsData.data.find((item: any) => item.name === "HelpCenter");
  var HelpCenterLink:string=HelpCenter?.value?HelpCenter.value:"";
  setHelpCenterURL(HelpCenterLink);
  const ShopNow: any =settingsData?.data &&
  settingsData.data.find((item: any) => item.name === "ShopNow");
  var ShopNowLink:string=ShopNow?.value?ShopNow.value:"";
  setShopNowURL(ShopNowLink);
  const video: any =settingsData?.data &&
  settingsData.data.find((item: any) => item.name === "video");
  var videoLink:string=video?.value?video.value:"";
  setVideoURL(videoLink);
}, [settingsData]);


  // useEffect(() => {
    //$(".caro-supplement-update")
    // require("../../../node_modules/slick-carousel/slick/slick.js");
    // let ele: any = $(".caro-supplement-update");
    // ele.slick({
    //   infinite: true,
    //   slidesToShow: 3,
    //   slidesToScroll: 3,
    //   dots: true,
    //   arrows: true,
    //   autoplay: true,
    //   autoplaySpeed: 7000,
    //   prevArrow:
    //     "<button type='button' class='slick-prev pull-left'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
    //   nextArrow:
    //     "<button type='button' class='slick-next pull-right'><i class='fa fa-angle-right' aria-hidden='true'></i></button>",
    // });
  // }, []);

  return (
    <>
      <Header />
      <main>
        <div className="container">
          <div className="dashboard">
            <div className="row">
              <Instruction url={KitInstructionURL}/>
              <TestKitInstructions />
              <HelpCenter url={HelpCenterURL}/>
              <PurchaseNewKit url={ShopNowURL}/>
              <TestResult />
              <HowItWorks video={videoURL}/>
              <Profile />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

const mapDispatchToProps = {
  getSettingsList: (pageNumber: number, pageSize: number, keyword: string) =>
    getSettingsList(pageNumber, pageSize, keyword),
};

function mapStateToProps(state: any) {
  return {
    settingsData: state.adminSettings?.settingsData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

