import { IApiResponse } from "../../models/IAPIResponse";
import { urlBinder } from "../../helper/url-binder";
import apiService from "../../helper/api-service";
import { BarcodeListing, Settings } from "../../constants/apiUrl";
import { ISettingsData } from "../../models/admin/settings";

export default new (class barcodeListingService {
  public getBarcodeListingIndex = async (
    keyword: string,
    pageNumber: number,
    pageSize: number,
    sortbyname?: boolean,
    sortbybarcode?: boolean,
    sortbycreateddate?: boolean,
    sortbyRegistred?: boolean,
    sortbyRegisteredDate?: boolean,
    sortbydealid?: boolean,
    sortbyusername?: boolean
  ): Promise<IApiResponse<Array<ISettingsData>>> => {
    try {
      let endPoint = urlBinder(BarcodeListing.GET_BARCODE_LISTING, [
        keyword,
        pageNumber.toString(),
        pageSize.toString(),
        sortbyname,
        sortbybarcode,
        sortbycreateddate,
        sortbyRegistred,
        sortbyRegisteredDate,
        sortbydealid,
        sortbyusername,
      ]);
      const response = await apiService.get(endPoint);
      return response?.data as IApiResponse<Array<ISettingsData>>;
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<Array<ISettingsData>>;
      } else {
        throw error;
      }
    }
  };

  public uploadExcel = async (data: any): Promise<IApiResponse<boolean>> => {
    try {
      let endPoint = urlBinder(BarcodeListing.POST_EXCEL_FILE_UPLOAD, []);
      // const response = await apiService.postFormData(endPoint, data);
      const response = await apiService.post(endPoint, data);
      return response as any;
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<boolean>;
      } else {
        throw error;
      }
    }
  };

  public deleteBarcode = async (id: number): Promise<any> => {
    try {
      let endPoint = urlBinder(BarcodeListing.DELETE_BARCODE, [`${id}`]);
      const response = await apiService.delete(endPoint);
      if (response?.status === 200) {
        return response;
      } else {
        return false;
      }
    } catch (error: any) {
      if (error.response) {
        return false as boolean;
      } else {
        throw error;
      }
    }
  };
})();
