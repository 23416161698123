import { IApiResponse } from "../../models/IAPIResponse";
import { ITestResult } from "../../models/testResult";
import { urlBinder } from "../../helper/url-binder";
import apiService from "../../helper/api-service";
import { TestResult } from "../../constants/apiUrl";
import { ISupplements } from "../../models/supplements";

export default new (class supplementService {
  public getSupplements = async (
    pageNumber: number,
    pageSize: number
  ): Promise<IApiResponse<Array<ISupplements>>> => {
    try {
      let endPoint = urlBinder(TestResult.GET_SUPPLEMENT, [
        pageNumber.toString(),
        pageSize.toString(),
      ]);
      const response = await apiService.get(endPoint);
      return response.data as IApiResponse<Array<ISupplements>>;
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<Array<ISupplements>>;
      } else {
        throw error;
      }
    }
  };
})();
