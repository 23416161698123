import { apiConfig } from "./apiConfig";

const dataTableConstants = {
  pageSize: 5,
  defaultpageCount: 1,
};

export const fileTypes = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "application/pdf",
  "application/vnd.ms-excel",
  ".doc",
  ".docx",
];

export const ImageFileTypes = ["image/png", "image/jpeg", "image/jpg"];
export const ExcelFileTypes = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "xls",
  "xlsx",
];
export const { pageSize, defaultpageCount } = dataTableConstants;

export const commonStatusList = [
  { id: "true", name: "true" },
  { id: "false", name: "false" },
];

var BASE_URL=apiConfig.api_url;
export const BASE_URL_IMAGE = BASE_URL&&BASE_URL.replace(/\/$/, ""); // Remove trailing slash
