import React from "react";

interface FloatingLabelTextInputProp {
  type?: string;
  label: string;
  onChange: any;
  isMandatory: boolean;
  value: any;
  name: string;
  placeholder?: string;
  disable?: boolean;
  maxLength?: number;
  minValue?: number;
  maxValue?: number;
  id?: string;
  className?: string;
  autoComplete?: any;

  // format?: any;
}

const FloatingLabelTextInput = (props: FloatingLabelTextInputProp) => {
  const { onChange } = props;
  const {
    type,
    label,
    isMandatory,
    value,
    name,
    disable,
    maxLength,
    placeholder,
    maxValue,
    minValue,
    id,
    className,
    autoComplete,
    // format,
  } = props;
  return (
    <>
      <div className="wrap-input">
        <input
          // className="className?className:inputlog"
          className={className ? className : "inputlog"}
          name={name}
          type={type != null ? type : "text"}
          value={value}
          onChange={(e) => onChange(e)}
          maxLength={maxLength}
          disabled={disable}
          placeholder={placeholder}
          min={minValue}
          max={maxValue}
          id={id}
          autoComplete={autoComplete != null ? autoComplete : "off"}
        />
        {/* {type && type == "date" ? format : null} */}
        {label && (
          <label className={`${value ? "active" : ""}`}>
            {label}
            {
              isMandatory
              // && <i>*</i>
            }
          </label>
        )}
      </div>
    </>
  );
};

export default FloatingLabelTextInput;
