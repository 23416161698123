import React from "react";

function BodyCompositionForOtherHeader() {
  return (
    <>
      <div className="login-pagination">
        <ul>
          <li className="active">
            <a href="#">step 1</a>
          </li>
          <li className="active">
            <a href="#">step 2</a>
          </li>
          <li className="active">
            <a href="#">step 3</a>
          </li>
        </ul>
        <p className="text-center">
          Step <span className="fw-bold">3 of 3</span>
        </p>
      </div>
    </>
  );
}

export default BodyCompositionForOtherHeader;
