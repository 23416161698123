const APP_PREPEND = "api";
const TEST_RESULT_PREPEND = "TestResult";
const SUPPLEMENT_PREPEND = "supplements";
const PROFILE_PREPEND = "profile";
const LOGIN_PREPEND = "Login";
const TEST_PURCHASE_PREPEND = "TestsPurchase/admin";
const USER_LIST_PREPEND = "UserList";
const SURVEY_LIST_PREPEND = "SurveyList";
const SETTINGS_PREPEND = "AdminSettings/configuration";
const BARCODE_LISTING_PREPEND = "BarcodeUpload";
const COMMON_DATA_PREPEND = "CommonData";
const REGISTER_PREPEND = "Registration";
const REGISTER_NEW_KIT_PREPEND = "KitRegister";
const USER_PROFILE_SHORT_AND_EXTENDED_PREPEND = "UserProfile";

//DASHBOARD
export const TestResult = {
  GET_SUPPLEMENT:
    TEST_RESULT_PREPEND +
    "/supplements?pageNumber={pageNumber}&pageSize={pageSize}",
  //GET_SHORT_PROFILE: TEST_RESULT_PREPEND + "/profile/short",
  GET_FULL_PROFILE: TEST_RESULT_PREPEND + "/profile/extended",
  GET_HEADER_PROFILE: TEST_RESULT_PREPEND + "/dashboarduserprofile",
  GET_TEST_RESULT:
    TEST_RESULT_PREPEND + "/tests?pageNo={pageNumber}&pageSize={pageSize}",
};

export const userProfileShortOrExtended = {
  GET_SHORT_PROFILE: USER_PROFILE_SHORT_AND_EXTENDED_PREPEND + "/profile/short",
  GET_FULL_PROFILE:
    USER_PROFILE_SHORT_AND_EXTENDED_PREPEND + "/profile/extended",
  GET_FULL_PROFILE_UPDATE:
    USER_PROFILE_SHORT_AND_EXTENDED_PREPEND + "/profile/edit",
};

export const profile = {
  // GET_FULL_PROFILE: PROFILE_PREPEND + "/{id}",
  // GET_SHORT_PROFILE: PROFILE_PREPEND + "/{id}",
  //GET_FULL_PROFILE_UPDATE: PROFILE_PREPEND + "/profile/edit",
};

//LOGIN
export const login = {
  CREATE_LOGIN: LOGIN_PREPEND + "/login",
  RESET_PASSWORD: LOGIN_PREPEND + "/forgot-password?email={email}",
  NEW_PASSWORD: LOGIN_PREPEND +  
  "/reset-password",
  };

//RESET PASSWORD
export const resetPassword = {};

//REGISTERATION
export const register = {
  POST_REGISTER: REGISTER_PREPEND + "/register",
  POST_REGISTER_MAIL: REGISTER_PREPEND + "/send-otp?email={email}",
  GET_VERIFY_EMAIL: REGISTER_PREPEND + "/EmailVerify?email={email}",
  POST_VERIFY_OTP: REGISTER_PREPEND + "/verifymail",
  POST_HUBSPOT_CREATE_DATA:
    REGISTER_PREPEND + `/HubspotContactCreate?IsEmail={email}&IsSMS={sms}`,
  POST_RESEND_OTP: REGISTER_PREPEND + `/send-otp?email={email}`,
};

//REGISTER NEW KIT
export const registerNewKit = {
  POST_REGISTER_NEW_BARCODE: REGISTER_NEW_KIT_PREPEND + "/barcode",
  // POST_RERGISTER_KIT_FOR_SELF:
  //   REGISTER_NEW_KIT_PREPEND +
  //   "/selfregistering?isSelfregistered={isSelfregistered}&name={name}&barcode={barcode}",
  POST_RERGISTER_KIT_FOR_OTHERS:
    REGISTER_NEW_KIT_PREPEND +
    "/registerkit?IsSelfRegistered={IsSelfRegistered}&name={name}&barcodeNumber={barcodeNumber}",
  GET_TEST_PURCHASED_FOR_OTHERS: REGISTER_NEW_KIT_PREPEND + "/testpurchasedfor",
};

//ADMIN
export const TestPurchase = {
  GET_TEST_PURCHASE:
    TEST_PURCHASE_PREPEND +
    "/testspurchased?pageNo={pageNumber}&pageSize={pageSize}&keyword={keyword}&fromDate={fromDate}&toDate={toDate}",
  GET_TEST_PURCHASE_EXPORT:
    TEST_PURCHASE_PREPEND +
    "/exporttoexcel?pageNo={pageNumber}&pageSize={pageSize}&keyword={keyword}&fromDate={fromDate}&toDate={toDate}",
  GET_TEST_PURCHASE_OF_INDIVIDUAL: TEST_PURCHASE_PREPEND + "/{id}",
};

export const UserList = {
  GET_USER_LIST:
    USER_LIST_PREPEND +
    "/admin/users?pageNumber={pageNumber}&pageSize={pageSize}&keyword={keyword}",
  GET_USER_LIST_EXPORT:
    USER_LIST_PREPEND + "/admin/users/export?keyword={keyword}",
  PATCH_DEACTIVATE_OR_ACTIVATE_USER:
    USER_LIST_PREPEND + "/admin/users/{userid}/status?isactive={isactive}",
  POST_RESET_PASSWORD_USER_LIST: USER_LIST_PREPEND + "/reset-password?email={email}&password={password}",
};

export const SurveyList = {
  GET_SURVEY_LIST:
    SURVEY_LIST_PREPEND +
    "/admin/surveys?pageNo={pageNumber}&pageSize={pageSize}&keyword={keyword}&focuson={focuson}&conditions={conditions}",
  GET_SURVEY_LIST_EXPORT:
    SURVEY_LIST_PREPEND +
    "/admin/surveys/export?keyword={keyword}&focuson={focuson}&conditions={conditions}",
  GET_FULL_PROFILE_ADMIN: SURVEY_LIST_PREPEND + "/viewprofile?userId={userId}",
  EDIT_FULL_PROFILE_ADMIN: SURVEY_LIST_PREPEND + "/editprofile",
};

export const BarcodeListing = {
  GET_BARCODE_LISTING:
    BARCODE_LISTING_PREPEND +
    "/getbarcodes?keyword={keyword}&pageNumber={pageNumber}&pageSize={pageSize}&sortbyname={sortbyname}&sortbybarcode={sortbybarcode}&sortbycreateddate={sortbycreateddate}&sortbyRegistred={sortbyRegistred}&sortbyRegisteredDate={sortbyRegisteredDate}&sortbydealId={sortbydealId}&sortbyusername={sortbyusername}",
  // GET_BARCODE_LISTING_SORT_BY_BARCODE:
  //   BARCODE_LISTING_PREPEND +
  //   "/getbarcodes?keyword={keyword}&pageNumber={pageNumber}&pageSize={pageSize}&sortbybarcode={sortbybarcode}",
  // GET_BARCODE_LISTING_SORT_BY_CREATED_DATE:
  //   BARCODE_LISTING_PREPEND +
  //   "/getbarcodes?keyword={keyword}&pageNumber={pageNumber}&pageSize={pageSize}&sortbycreateddate={sortbycreateddate}",
  // GET_BARCODE_LISTING_SORT_BY_REGISTERED:
  //   BARCODE_LISTING_PREPEND +
  //   "/getbarcodes?keyword={keyword}&pageNumber={pageNumber}&pageSize={pageSize}&sortbyRegistred={sortbyRegistred}",
  // GET_BARCODE_LISTING_SORT_BY_REGISTERED_DATE:
  //   BARCODE_LISTING_PREPEND +
  //   "/getbarcodes?keyword={keyword}&pageNumber={pageNumber}&pageSize={pageSize}&sortbyRegisteredDate={sortbyRegisteredDate}",
  // GET_BARCODE_LISTING_SORT_BY_DEAL_ID:
  //   BARCODE_LISTING_PREPEND +
  //   "/getbarcodes?keyword={keyword}&pageNumber={pageNumber}&pageSize={pageSize}&sortbydealId={sortbydealId}",
  // GET_BARCODE_LISTING_SORT_BY_USERNAME:
  //   BARCODE_LISTING_PREPEND +
  //   "/getbarcodes?keyword={keyword}&pageNumber={pageNumber}&pageSize={pageSize}&sortbyusername={sortbyusername}",
  POST_EXCEL_FILE_UPLOAD: BARCODE_LISTING_PREPEND + "/uploadexcel",
  DELETE_BARCODE: BARCODE_LISTING_PREPEND + "/deletebarcode?barcodeId={id}",
};

export const Settings = {
  GET_SETTINGS_LIST: SETTINGS_PREPEND + "/get-configurations?pageNumber={pageNumber}&pageSize={pageSize}&keyword={keyword}",
  PUT_SETTINGS_LIST: SETTINGS_PREPEND + "/update",
};

export const CommonData = {
  GET_FOCUS_ON: COMMON_DATA_PREPEND + "/focuson",
  GET_MEDICAL_CONDITIONS: COMMON_DATA_PREPEND + "/medicalconditions",
  GET_STATUS: COMMON_DATA_PREPEND + "/status",
  GET_TEST_NAME: COMMON_DATA_PREPEND + "/testname",
  GET_GENDER: COMMON_DATA_PREPEND + "/gender",
};
