import { ValidationErrorItem } from "joi";

export function validateFields(requestDetails: any, schema: any) {
  const result = schema.validate(requestDetails, {
    abortEarly: false,
    allowUnknown: true,
  });
  return (
    result.error &&
    result.error.details &&
    setErrorFromJoi(result.error.details)
  );
}

export function setErrorFromJoi(validationErrors: Array<ValidationErrorItem>) {
  let _erroObject = {};
  validationErrors.map((errorItem) => {
    if (errorItem.context && errorItem.context.key) {
      _erroObject = {
        ..._erroObject,
        [errorItem.context.key]: errorItem.context.label,
      };
    }
  });
  return _erroObject;
}
