import React, { useEffect, useState } from "react";
import Footer from "./component/footer";
import Header from "./component/header";
import { connect } from "react-redux";
import {
  getSettingsList,
  updateSettings,
} from "../../actions/adminSettingsActionCreator";
import {
  ISettingDataDisplay,
  ISettingsData,
  IUrl,
} from "../../models/admin/settings";
import { pageSize } from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import FloatingLabelTextInput from "../../common-components/floatingLabelTextInput";
import DataTable from "../../common-components/dataTable";
import { ROUTER_URL } from "../../constants/routerUrl";

const Settings = (props: ISettingDataDisplay) => {
  const { getSettingsList, updateSettings, settingsData } = props;
  const [data, setData] = useState<IUrl>({
    value: "",
    isEnable: false,
  });
  const [userid, setUserid] = useState(0);
  const [num, setNum] = useState(0);
  const [errors, setErrors] = useState<any>(undefined);
  const [settingsId, setSettingsId] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [keyWord, setKeyWord] = useState(false);
  const navigate = useNavigate();
  const [configData, setConfigData] = useState<any>([
    {
      id: 0,
      name: "",
      value: "",
      isEnable: false,
    },
  ]);
  // executes when a page number is set or clicked
  const onPageChange = (pageNo: number) => {
    setSelectedPage(pageNo);
  };
  const [isEdit, setEdit] = useState("NO");
  const [editId, setEditId] = useState(0);

  const columns = [
    { label: "Name", prop: "name" },
    { label: "URL", prop: "value" },
    // { label: "Status", prop: "Status" },
  ];

  const onChange = (
    event:
      | any
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    // if (event.keyCode == 13) {
    //   setKeyWord(true);
    // } else {
    //   setKeyWord(false);
    // }
    const _data = {
      ...data,
      [event.target.name]: event.target.value,
    };

    setData(_data);
  };
  // const editSettingsFuntion = async (id: number) => {
  //   setEdit("YES");
  //   setEditId(id);
  // };

  // const editSettings = (id: any) => {
  //   setUserid(id);
  //   // setIsFullProfileVisible(true);
  // };

  const editSettings = (id: any) => {
    localStorage.setItem("adminConfig", JSON.stringify(id));

    navigate(ROUTER_URL.SETTINGS_EDIT, {
      state: { id: id },
    });
  };

  let pageSize = 5;

  useEffect(() => {
    getSettingsList(selectedPage, pageSize, "");
  }, [selectedPage, pageSize]);

  return (
    <>
      <Header />
      <main>
        <div className="container">
          <div className="admin-title">
            <h4>Settings</h4>
          </div>
          <div className="data-table mb-3">
            <DataTable
              columns={columns}
              noDataFound={"No data found"}
              tabelData={settingsData && settingsData}
              onPageChange={onPageChange}
              bgColor={"status"}
              selectedPage={selectedPage}
              paginateItem={true}
              showDrilldown={true}
              editSettings={editSettings}
            />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

const mapDispatchToProps = {
  getSettingsList: (pageNumber: number, pageSize: number, keyword: string) =>
    getSettingsList(pageNumber, pageSize, keyword),
  updateSettings: (data: any, callback: Function) =>
    updateSettings(data, callback),
};

function mapStateToProps(state: any) {
  return {
    settingsData: state.adminSettings?.settingsData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
