import { createSlice } from "@reduxjs/toolkit";
import { IProfileSlice } from "../../../models/profile";
import { IHubsportSlice } from "../../../models/registeration";

const initialRegisterState: IHubsportSlice = {
  hubSpotData: undefined,
};

const registerSlice = createSlice({
  name: "register",
  initialState: initialRegisterState,
  reducers: {
    setHubSpotData: (state, action: { payload: any }) => {
      state.hubSpotData = action.payload;
    },
  },
});

export const { setHubSpotData } = registerSlice.actions;

const registerReducer = registerSlice.reducer;

export type registerState = ReturnType<typeof registerReducer>;

export default registerReducer;
