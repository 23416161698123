import { createSlice } from "@reduxjs/toolkit";
import { ITestResult, ITestResultSlice } from "../../../models/testResult";

const initialTestResultState: ITestResultSlice = {
  testResultData: undefined,
};

const testResultSlice = createSlice({
  name: "testResult",
  initialState: initialTestResultState,
  reducers: {
    setTestResultData: (state, action: { payload: any }) => {
      state.testResultData = action.payload;
    },
  },
});

export const { setTestResultData } = testResultSlice.actions;

const testResultReducer = testResultSlice.reducer;

export type testResultState = ReturnType<typeof testResultReducer>;

export default testResultReducer;
