import { MsalProvider } from "@azure/msal-react";
import "./App.scss";
import Router from "./router/router";
import { IPublicClientApplication } from "@azure/msal-browser";
import { useNavigate } from "react-router-dom";
import { CustomNavigationClient } from "./utils/navigation-client";
import { ROUTER_URL } from "./constants/routerUrl";

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);
  let token = localStorage.getItem("access_token");
  return (
    <div className="wrap">
      {/* <MsalProvider instance={pca}> */}

      <Router />
      {/* {token ? navigate(ROUTER_URL.DASHBOARD) : navigate(ROUTER_URL.DASHBOARD)} */}

      {/* </MsalProvider> */}
    </div>
  );
}

export default App;
