import { createSlice } from "@reduxjs/toolkit";
import { IUserListSlice } from "../../../models/admin/userList";

const initialUserListState: IUserListSlice = {
  userListData: undefined,
  userListExportData: undefined,
};

const userListSlice = createSlice({
  name: "adminUserList",
  initialState: initialUserListState,
  reducers: {
    setUserListData: (state, action: { payload: any }) => {
      state.userListData = action.payload;
    },
    setUserListExportData: (state, action: { payload: any }) => {
      state.userListExportData = action.payload;
    },
  },
});

export const { setUserListData, setUserListExportData } = userListSlice.actions;

const userListReducer = userListSlice.reducer;

export type userListState = ReturnType<typeof userListReducer>;

export default userListReducer;
