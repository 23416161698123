import React from "react";
import { IPurchaseNewKitDisplay } from "../../../../models/dashboard";

const PurchaseNewKit = (props: IPurchaseNewKitDisplay) => {
  const {url} =props;
  return (
    <>
      <div className="col-md-6 mt-3">
        <div className="blk-kit blk-ltgreen">
          <h4>Healthogenics Health & Wellness Tests</h4>
          <p>
            Gaining control of your health & wellness have never been easier
            with our At Home health & Wellness Tests. Explore our tests now and
            start your journey to a happier, healthier tomorrow.
          </p>
          <div className="d-flex justify-content-end">
            <a
              href={url}
              target="_blank"
            >
              Shop Now <i className="fa-solid fa-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaseNewKit;
