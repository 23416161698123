import React from "react";
import { IInstructionDisplay } from "../../../models/dashboard";

const Instruction = (props: IInstructionDisplay) => {
  const {url} =props;
  return (
    <>
      <div className="col-md-6 mt-3 position-relative">
        <div className="blk-kit blk-blue">
          <h4>Step 1: Your Test Kit Instructions</h4>
          <p>
            Follow these simple step-by-step instructions regarding your
            test kit, sample collection, and receiving your results.
          </p>
          <div className="d-flex justify-content-end">
            <a
              href={url}
              target="_blank"
            >
              Learn More <i className="fa-solid fa-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Instruction;
