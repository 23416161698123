import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Joi from "joi";
import FloatingLabelTextInput from "../../../common-components/floatingLabelTextInput";
import {
  IConsentMethod,
  IFinalData,
  IHubspotCreateData,
  IStep3Display,
} from "../../../models/registeration";
import { validateFields } from "../../../helper/joi-validation";
import moment from "moment";
import { ROUTER_URL } from "../../../constants/routerUrl";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { createHubSpotData } from "../../../actions/loginActionCreator";

const Step3 = (props: IStep3Display) => {
  const navigate = useNavigate();
  const {
    passData,
    step3Data,
    currentStep,
    changeStep,
    oldData,
    newData,
    sendDataToParent,
    getMail,
    setMainData,
    hubSpotData,
    createHubSpotData,
  } = props;

  const [errors, setErrors] = useState<any>(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [sms, setSms] = useState(false);
  const [mail, setMail] = useState(false);
  const [isAgreeTerms, setIsAgreeTerms] = useState(false);
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [agreementPolicy, setAgreementPolicy] = useState(false);
  const [consentMethodError, setConsentMethodError] = useState<any>(undefined);
  let hubspotId = localStorage.getItem("hubspot_id");
  let hubspotFirstName = localStorage.getItem("hubspot_firstName");
  let hubspotLastName = localStorage.getItem("hubspot_lastName");
  let hubspotPhone = localStorage.getItem("hubspot_phone");

  const [data, setData] = useState<IFinalData>({
    id: 0,
    firstName: hubspotFirstName ? hubspotFirstName : "",
    isAgreeTerms: false,
    lastName: hubspotLastName ? hubspotLastName : "",
    password: "",
    email: getMail,
    mobileNo: hubspotPhone ? hubspotPhone : "",
    gender: "",
    dob: "",
    weight: "",
    focusOn: [],
    preCondition: [],
    focusOnName: "",
    feet: 0,
    inch: 0,
    height: "",
  });
  const schema2 = Joi.object({
    sms: Joi.boolean(),
    mail: Joi.boolean(),
  });
  const schema = Joi.object().keys({
    firstName: Joi.string()
      .regex(/^[A-Za-z'\- ]+$/)
      .required()
      .label("Enter valid name"),
    isAgreeTerms: Joi.boolean()
      .valid(true)
      .label("Please accept agreement Policy"),
    lastName: Joi.string()
      .regex(/^[A-Za-z'\- ]+$/)
      .required()
      .label("Enter valid name"),
    password: Joi.string()
      .min(8) // Minimum length of 8 characters
      .pattern(/^(?=.*[A-Z])[A-Za-z\d~!@#$%^&*()_\-+=\\|}\][{';":\/?.><`,]+$/)
      .label(
        "Password must contain minimum 8 characters and must contain atleast an uppercase letter"
      ),
    email: Joi.string()
      .regex(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
      .required()
      .label("Enter valid email"),
    // mobileNo: Joi.number().required().label("Enter valid mobile number"),
    mobileNo: Joi.string()
      .length(10)
      .pattern(/^[0-9]+$/)
      .required()
      .label(
        "Please enter the mobile number in 10 digit format without space or any separation. eg : 1234567890"
      ),
    gender: Joi.allow(),
    dob: Joi.allow(),
    weight: Joi.allow(),
    focusOn: Joi.allow(),
    preCondition: Joi.allow(),
    focusOnName: Joi.allow(),
    feet: Joi.allow(),
    inch: Joi.allow(),
    height: Joi.allow(),
  });

  // const schemaAgreement = Joi.object().keys({

  //   agreementPolicy:
  // });

  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    errors && delete errors[event.target.name];
    const _data = {
      ...data,
      [event.target.name]: event.target.value,
    };
    setData(_data);
  };

  const onChangeFirstName = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    errors && delete errors[event.target.name];
    const _data = {
      ...data,
      [event.target.name]: event.target.value,
      firstName: hubspotFirstName ? hubspotFirstName : event.target.value,
    };
    setData(_data);
  };

  const onChangeLastName = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    errors && delete errors[event.target.name];
    const _data = {
      ...data,
      [event.target.name]: event.target.value,
      lastName: hubspotLastName ? hubspotLastName : event.target.value,
    };
    setData(_data);
  };

  const onChangeMobileNo = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    debugger;
    errors && delete errors[event.target.name];
    const _data = {
      ...data,
      [event.target.name]: event.target.value,
      mobileNo: event.target.value,
      //  hubspotPhone
      //   ? hubspotPhone.replace(/-/g, "").toString()
      //   :
    };
    setData(_data);
  };

  const onChangeAgreementPolicy = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    errors && delete errors[event.target.name];
    const _data = {
      ...data,
      [event.target.name]: event.target.value,
      isAgreeTerms: true,
    };
    setData(_data);
  };

  const onChangeAgreement = (e: any) => {
    // errors && delete errors[e.target.name];
    const { name, checked } = e.target;
    setIsAgreeTerms(checked);
    const _data = {
      ...data,
      isAgreeTerms: checked,
    };
    setData(_data);
    // errors && delete errors[e.target];
    // const { name, checked } = e.target;
    // console.log(e.target)
    // if (name === "isAgreeTerms") {
    //   setIsAgreeTerms(checked);
    //   const _data = {
    //     ...data,
    //     isAgreeTerms: checked,
    //   };
    //   setData(_data);
    // }
  };

  const handleCheckboxChange = (e: any) => {
    const { name, checked } = e.target;

    if (name === "sms") {
      setSms(checked);
    } else if (name === "mail") {
      setMail(checked);
    }

    setError("");
  };

  const callback = (response: any) => {
    if (response === true) {
      localStorage.clear();
      localStorage.removeItem("hubspot_email");
      localStorage.removeItem("emailData?.firstName");
      localStorage.removeItem(" emailData?.lastName");
      localStorage.removeItem("emailData?.phone");
      let trial = localStorage.getItem("hubspot_email");
      callbackSuccessMessage("Please check your Mail !!");
    } else {
      callbackFailedMessage("Something Went Wrong !!");
    }
  };

  const callbackSuccessMessage = (message: string) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        changeStep(currentStep + 1);
        setDisableButton(false);
      },
    });
  };

  const callbackFailedMessage = (message: string) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        setDisableButton(false);
      },
    });
  };

  const saveRequest = async () => {
    let step3Out = data;
    setMainData({
      ...passData,
      firstName: data.firstName,
      lastName: data.lastName,
      email: getMail,
      mobileNo: data.mobileNo,
      password: data.password,
      isAgreeTerms: data.isAgreeTerms,
    });
    var properties: any = {
      email: getMail,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.mobileNo,
      otp_code: "",
      consent_method: "",
    };

    var contact = { properties };
    props.sendDataToParent(step3Out);
    await createHubSpotData(mail, sms, contact, callback); //API call to create hubspot contact
  };

  const onSubmit = async () => {
    setDisableButton(true);
    if (!sms && !mail) {
      setError("Please select any of the consent method");
      setDisableButton(false);
      // return;
    }

    const consentMethod = { sms, mail };
    const { error } = schema2.validate(consentMethod);
    const trial = validateFields(consentMethod, schema2);

    if (error) {
      setError("Validation error: " + error.message);
      return;
    }
    const validationErrors = validateFields(data, schema);

    if (
      validationErrors == undefined &&
      (consentMethod.mail === true || consentMethod.sms === true)
    ) {
      await saveRequest();
    } else {
      setDisableButton(false);
      setError("Please select any of the consent method");
      setErrors(validationErrors);
      setConsentMethodError(consentMethodError);
    }
  };

  const login = () => {
    navigate(ROUTER_URL.LOGIN);
  };

  // const isButtonDisabled = data.isAgreeTerms === false;

  useEffect(() => {
    setData({
      ...passData,
      firstName: hubspotFirstName,
      lastName: hubspotLastName,
      email: getMail,
      mobileNo: hubspotPhone && hubspotPhone.replace(/-/g, "").toString(),
    });
  }, [getMail, hubSpotData]);

  return (
    <>
      <div className="login-card">
        <div className="title">
          <h3>Fill in your details</h3>
          <p>
            To complete the registration, please fill out the required
            information.
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <FloatingLabelTextInput
                label={"First Name"}
                onChange={onChangeFirstName}
                isMandatory={true}
                value={data?.firstName}
                name={"firstName"}
                placeholder={"First Name"}
              />
              {errors && errors.firstName && (
                <div className="errors">{errors.firstName}</div>
              )}
              {/* <input type="text" placeholder="First Name" id="firstname" />
              <label htmlFor="firstname">First Name</label> */}
              <div className="icon">
                <img src="assets/images/icons/lock.svg" alt="" />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group pad-2">
              <FloatingLabelTextInput
                label={"Last Name"}
                onChange={onChangeLastName}
                isMandatory={true}
                value={data?.lastName}
                name={"lastName"}
                placeholder={"Last Name"}
              />
              {errors && errors.lastName && (
                <div className="errors">{errors.lastName}</div>
              )}
              {/* <input type="text" placeholder="Last Name" id="lastname" />
              <label htmlFor="lastname">Last Name</label> */}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <FloatingLabelTextInput
                label={"Email"}
                onChange={onChange}
                isMandatory={true}
                value={data.email.trim()}
                // value={getMail}
                name={"email"}
                placeholder={"Email"}
                disable
              />
              {errors && errors.email && (
                <div className="errors">{errors.email}</div>
              )}
              {/* <input type="text" placeholder="Email" id="email" />
              <label htmlFor="email">Email</label> */}
              <div className="icon">
                <img src="assets/images/icons/email.svg" alt="" />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <FloatingLabelTextInput
                label={"Mobile Number"}
                onChange={onChangeMobileNo}
                isMandatory={true}
                value={data?.mobileNo}
                name={"mobileNo"}
                placeholder={"Mobile Number"}
                autoComplete="off"
              />
              {errors && errors.mobileNo && (
                <div className="errors">{errors.mobileNo}</div>
              )}
              {/* <input type="number" placeholder="Mobile Number" id="number" autoComplete="off" />
              <label htmlFor="number">Mobile Number</label> */}
              <div className="icon">
                <img src="assets/images/icons/phone.svg" alt="" />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <FloatingLabelTextInput
                label={"Password"}
                onChange={onChange}
                isMandatory={true}
                value={data.password}
                name={"password"}
                placeholder={"Password"}
                type={`${showPassword ? "text" : "password"}`}
                autoComplete="off"
              />
              {errors && errors.password && (
                <div className="errors">{errors.password}</div>
              )}
              {/* <input type="Password" placeholder="Password" id="password" />
              <label htmlFor="password">Password</label> */}
              <div className="icon">
                <img src="assets/images/icons/lock.svg" alt="" />
              </div>
              <div
                className="show-password"
                onClick={() => setShowPassword(!showPassword)}
              >
                <a>
                  {showPassword == true ? (
                    <img
                      src="assets/images/icons/eye.svg"
                      className="password-show"
                      alt=""
                    />
                  ) : (
                    <img
                      src="assets/images/icons/eye-off.svg"
                      className="password-hide"
                      alt=""
                    />
                  )}
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="d-flex align-items-center justify-content-between">
              <div className="checkbox-wrap d-flex align-items-start gap-2">
                {/* <FloatingLabelTextInput
                  label={""}
                  onChange={onChangeAgreementPolicy}
                  isMandatory={true}
                  value={data.isAgreeTerms}
                  name={"isAgreeTerms"}
                  type="checkbox"
                  className="mt-1 me-1"
                /> */}

                <input
                  type="checkbox"
                  name="isAgreeTerms"
                  checked={isAgreeTerms}
                  onChange={onChangeAgreement}
                />

                <label className="mtm-4" htmlFor="rememberme">
                  {" "}
                  I agree to the{" "}
                  <span className="terms">
                    <a
                      href={
                        "https://www.healthogenics.com/policies/terms-of-service"
                      }
                      className="fw-semibold txt-primary"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of services{" "}
                    </a>
                    {/* <div className="tooltip-login">
                      <h6>Terms of services</h6>
                      <p>
                        "Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit. Mauris id tortor enim. Ut luctus varius quam id
                        ornare. Morbi laoreet ex a laoreet blandit. Morbi
                        interdum eu elit vel maximus. Ut interdum sed elit a
                        volutpat. In hac habitasse platea dictumst. Nulla
                        facilisi. In vitae dapibus magna."
                      </p>
                    </div> */}
                  </span>
                  and the{" "}
                  <span className="policy">
                    <a
                      href={
                        "https://www.healthogenics.com/policies/privacy-policy"
                      }
                      className="fw-semibold txt-primary"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy policy
                    </a>
                    {/* <div className="tooltip-login">
                      <h6>Privacy policy</h6>
                      <p>
                        "Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit. Mauris id tortor enim. Ut luctus varius quam id
                        ornare. Morbi laoreet ex a laoreet blandit. Morbi
                        interdum eu elit vel maximus. Ut interdum sed elit a
                        volutpat. In hac habitasse platea dictumst. Nulla
                        facilisi. In vitae dapibus magna."
                      </p>
                    </div> */}
                  </span>
                </label>
              </div>
              {/* <p>
                {errors && errors.isAgreeTerms && (
                  <div className="errors">{errors.isAgreeTerms}</div>
                )}
              </p> */}
            </div>
            <p>
              {errors && errors.isAgreeTerms && isAgreeTerms == false && (
                <div className="errors">{errors.isAgreeTerms}</div>
              )}
            </p>
            {/* SMS INTEGRATION */}

            <div className="d-flex align-items-center border-top gap-3 pt-3">
              <span>
                <label>
                  <input
                    type="checkbox"
                    name="mail"
                    checked={mail}
                    onChange={handleCheckboxChange}
                  />
                  &nbsp;&nbsp;EMAIL
                </label>
              </span>
              <span>
                <label>
                  <input
                    type="checkbox"
                    name="sms"
                    checked={sms}
                    onChange={handleCheckboxChange}
                  />
                  &nbsp;&nbsp;SMS
                </label>
              </span>
            </div>

            {/* <small className="text-secondary">
              By selecting the above options you are providing us the consent to
              contact you
            </small> */}
            <p className="mt-2">
              By selecting the above options you are providing us the consent to
              contact you
            </p>
          </div>

          {error && sms == false && mail == false && (
            <p className="errors">{error}</p>
          )}
          {/* {consentMethodError===undefined && (consentMethod.mail===false)&&(consentMethod.sms===false)&&(
            <div style={{ color: "red" }}>
              Please select any of the consent method
            </div>
          )} */}
          {disableButton === true ? (
            <div className="form-group mt-4 mb-3">
              <button onClick={() => onSubmit()} disabled>
                Create
              </button>
            </div>
          ) : (
            <div className="form-group mt-4 mb-3">
              <button onClick={() => onSubmit()}>Create</button>
            </div>
          )}

          <div className="d-flex align-items-center justify-content-center">
            <span className="txt-secondary fs-14">
              Already have an account?
              <a
                href="#"
                className="fw-semibold txt-primary m-1"
                onClick={login}
              >
                Sign in
              </a>
            </span>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

const mapDispatchToProps = {
  createHubSpotData: (
    email: boolean,
    sms: boolean,
    data: IHubspotCreateData,
    callback: Function
  ) => createHubSpotData(email, sms, data, callback),
};

function mapStateToProps(state: any) {
  return {
    hubSpotData: state.register?.hubSpotData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Step3);
