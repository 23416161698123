import { combineReducers } from "redux";
import testResultReducer from "./slices/dashboard/testResult";
import supplementReducer from "./slices/dashboard/supplement";
import profileReducer from "./slices/dashboard/profile";
import testPurchasedReducer from "./slices/admin/testPurchase";
import userListReducer from "./slices/admin/userList";
import surveyListReducer from "./slices/admin/surveyList";
import commonReducer from "./slices/common/commonSlice";
import registerReducer from "./slices/register/register";
import registerNewKitReducer from "./slices/registerNewKit/registerNewKit";
import settingsReducer from "./slices/admin/settings";
import barcodeListingReducer from "./slices/admin/barcodeListing";
import emailLoginReducer from "./slices/login/login";

const reducers = {
  testResult: testResultReducer,
  supplement: supplementReducer,
  profile: profileReducer,
  adminTestPurchase: testPurchasedReducer,
  adminUserList: userListReducer,
  adminSurveyList: surveyListReducer,
  adminBarcodeList: barcodeListingReducer,
  adminSettings: settingsReducer,
  register: registerReducer,
  commonDropdowns: commonReducer,
  registerNewKit: registerNewKitReducer,
  loginEmail: emailLoginReducer,
};

export const appReducer = combineReducers(reducers);
