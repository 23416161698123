import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTER_URL } from "../../../constants/routerUrl";

const SampleRegisteration = () => {
  const navigate = useNavigate();

  const register = () => {
    navigate(ROUTER_URL.BARCODE_REGISTER);
  };
  return (
    <>
      <div className="col-md-6 mt-3">
        <div className="blk-kit blk-green">
          <h4>Step 2: Register Your Test</h4>
          <p>
            Register your Test Kit here. Your test must be registered
            before your sample is submitted for testing.
          </p>
          <div className="d-flex justify-content-end">
            <a href="#" onClick={register}>
              Register <i className="fa-solid fa-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default SampleRegisteration;
