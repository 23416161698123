import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IFullProfileDisplay } from "../../../../../models/profile";
import { ROUTER_URL } from "../../../../../constants/routerUrl";
import Modal from "react-modal";
import Joi from "joi";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import {
  getFullProfile,
  updateFullProfile,
} from "../../../../../actions/profileActionCreator";
import { toast } from "react-toastify";
import { validateFields } from "../../../../../helper/joi-validation";
import { BASE_URL_IMAGE } from "../../../../../constants/constants";
import FloatingLabelTextInput from "../../../../../common-components/floatingLabelTextInput";
import FloatingLabelDropDown from "../../../../../common-components/floatingLabelDropdown";
import DatePicker from "react-datepicker";
import { getGender } from "../../../../../actions/common/commonActionCreator";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const FullProfile = (props: IFullProfileDisplay) => {
  const navigate = useNavigate();
  const {
    fullProfileData,
    getFullProfile,
    isProfileVisible,
    onClose,
    updateFullProfile,
    getGender,
    gender,
  } = props;
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [errors, setErrors] = useState<any>(undefined);
  const [file, setFile] = useState<any>([]);
  const [date, setDate] = useState("");
  const [enableSubmit, setEnableSubmit] = useState(false);
  // const [isClose, setClose] = useState(false);

  // let id = 1;
  useEffect(() => {
    getFullProfile();
  }, []);

  const [data, setData] = useState<any>([
    {
      id: 0,
      fullName: "",
      email: "",
      mobileNo: 0,
      gender: 0,
      // dateOfBirth: moment(new Date()).format("MM-dd-yyyy"),
     dateOfBirth: new Date(),
      height: "",
      weight: 0,
      focus: [],
      conditions: [],
      file: [],
      feet: 0,
      inch: 0,
    },
  ]);

  const edit = () => {
    setEditModalOpen(true);
    setEdit(true);
  };

  const schema = Joi.object().keys({
    email:Joi.string()
    .regex(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
    .required()
    .label("Enter valid email"),
    mobileNo: Joi.string()
      .length(10)
      .pattern(/^[0-9]+$/)
      .required()
      .label("Enter valid mobile number"),
    gender: Joi.number().min(1).required().label("Enter valid gender"),
    dateOfBirth: Joi.allow(),
    weight: Joi.number().min(1).required().label("Enter valid weight"),
    feet: Joi.number().min(1).max(9).required().label("Enter valid feet"),
    inch: Joi.number().min(1).max(12).required().label("Enter valid inch"),
  });

  const feetValues = Array.from({ length: 8 }, (_, index) => index + 1);
  const inchValues = Array.from({ length: 11 }, (_, index) => index + 1);
  
  const saveRequest = async () => {
   var email=data.email;
   localStorage.setItem("UpdatedEmail", email);
    // new Date(moment(data.dateOfBirth).format("MM-dd-yyyy")).toString()
    const formData = new FormData();
    formData.append("id", data.id);
    formData.append("email", data.email);
    formData.append("mobileNo", data.mobileNo);
    formData.append("gender", data.gender);
    // formData.append(
    //   "dOB",
    //   data?.dateOfBirth
    //     ?(moment(data?.dateOfBirth?data?.dateOfBirth:"")?.format("MM-DD-yyyy"))?.toString() 
    //     :"-",
    // );
    let formattedDateOfBirth = '';
if (data && data.dateOfBirth) {
    formattedDateOfBirth = moment(data.dateOfBirth).format("MM-DD-yyyy");
}
formData.append("dOB", formattedDateOfBirth);

    //  formData.append(
    //   "dOB",
    //   data?.dateOfBirth
    //     ?(moment(data?.dateOfBirth?data?.dateOfBirth:"")?.format("MM-DD-yyyy"))?.toString() 
    //     :"-",
    // );
    formData.append(
      "height",
      data.feet.toString() + " Feet " + data.inch.toString() + " Inches "
    );
    formData.append("weight", data.weight);
    formData.append(
      "file",
      data.file && data.file
    );
    await updateFullProfile(formData, callBack);
  };

  const handleEmailChange=()=>{
    var currentEmail=localStorage.getItem("Email");
    var newEmail=localStorage.getItem("UpdatedEmail");
    if(newEmail){
      if(newEmail==currentEmail){
        getFullProfile();
      }else{
        localStorage.clear();  
        navigate(ROUTER_URL.LOGIN);
      } 
    }
  }

  const callBack = (response: any) => {
    if (response === true) {
      successfullyRegistered();
    } else if(response === false){
     updateFailed();
    }else{
      somethingWentWrong();
    }
  };

  //validating the request
  const onSubmit = async () => {
    setEnableSubmit(true);
    // setEdit(false);
    const validationErrors = validateFields(data, schema);
    if (validationErrors === undefined) {
      await saveRequest();
    } else {
      setErrors(validationErrors);
      setEnableSubmit(false);
    }
  };


  const dobChange = (event: any) => {
    if (event) {
      setData({
        ...data,
        dateOfBirth: event,
      });
    }
  };

  const onCancel = async () => {
    setEdit(false);
  };

  useEffect(() => {
    if(isEdit===false){
      const initial_height =
      fullProfileData &&
      fullProfileData?.height &&
      fullProfileData?.height.split(" ");
    const initial_inch = Number(initial_height && initial_height[2]);
    const initial_feet = Number(initial_height && initial_height[0]);
    if(fullProfileData){
      setData({
        id: fullProfileData?.id ? fullProfileData?.id : 0,
        fullName: fullProfileData?.fullName,
        email: fullProfileData?.email,
        mobileNo: fullProfileData?.mobileNo,
        gender: fullProfileData?.gender,
        dateOfBirth:
          fullProfileData?.dateOfBirth? new Date(fullProfileData?.dateOfBirth):new Date(),
        height: fullProfileData?.height,
        focus: fullProfileData?.focus,
        weight: fullProfileData?.weight,
        conditions: fullProfileData?.conditions,
        file: [],
        fileUrl: fullProfileData?.imagePath,
        feet: initial_feet,
        inch: initial_inch,
      });
    }
    }
    
  }, [fullProfileData, isEdit]);

  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    errors && delete errors[event.target.name];
    const _requestDetails = {
      ...data,
      [event.target.name]: event.target.value,
    };

    setData(_requestDetails);
  };

  const onDrop = (acceptedFiles: any) => {
    acceptedFiles.forEach((file: any) => {
      if (file.size > 1024 * 1024) {
        alert("File size exceeds 1 MB limit");
        return;
      }
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        if (img.width > 300 || img.height > 300) {
          alert("Image dimensions exceed 300x300 limit");
        } else {
          const _fileData = {
            ...JSON.parse(JSON.stringify(data)),
            file: file,
            fileUrl: img.src,
          };
          setData(_fileData);
        }
      };
    });
    let item = acceptedFiles[0];
  };
  useEffect(() => {
    getGender();
  }, []);




  const { getInputProps } = useDropzone({ onDrop });

  const updateFailed = () => {
    toast.error("Update Failed !!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        setEnableSubmit(false);
      },
    });
  };

  const somethingWentWrong = () => {
    toast.error("Something Went Wrong !!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        setEnableSubmit(false);
      },
    });
  };

  const successfullyRegistered = () => {
    toast.success("Updated Successfully !!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        setEdit(false);
        setEnableSubmit(false);
        handleEmailChange();

      },
    });
  };

 

  return (
    <>
      <Modal
        isOpen={isProfileVisible}
        className="modal fade"
        // id="profile-edit"
        // data-bs-backdrop="static"
        // data-bs-keyboard="false"
        // tabIndex={-1}
        // aria-labelledby="profile-edit"
        // aria-hidden="true"
      >
        {/* <div
          className="modal fade"
          id="profile-edit"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="profile-edit"
          aria-hidden="true"
        > */}
        <div
          className="modal fade"
          id="profile-edit"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          // tabIndex="-1"
          aria-labelledby="profile-edit"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body p-0">
                <div className="card-profile">
                  <div className="profile-top d-flex align-items-start justify-content-start">
                    {/* <button
                    className="modal-close"
                    onClick={() => {
                      setShowSampleNumber(false);

                      document.body.classList.remove("flow-hidden");
                    }}
                  /> */}
                    {isEdit === false ? (
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          onClose();
                          // document.body.classList.remove("flow-hidden");
                        }}
                      ></button>
                    ) : (
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          setEdit(false);
                          // document.body.classList.remove("flow-hidden");
                        }}
                      ></button>
                    )}

                    <div className="img">
                      <img src="assets/images/profile.jpg" alt="" />
                    </div>
                  </div>
                  {isEdit === false ? (
                    <div className="btn-edit-profile">
                      <button
                        type="button"
                        className="btn-primary"
                        onClick={edit}
                      >
                        Edit Profile
                      </button>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* PROFILE */}
                  {isEdit === false ? (
                    <div className="profile-btm d-flex align-items-center justify-content-between">
                      <div className="profile w-100 profile-dtls">
                        <div className="img">
                          <img
                            src={
                              data.fileUrl? BASE_URL_IMAGE +
                              fullProfileData?.imagePath
                                : "assets/images/profile.jpg"
                              // ? "https://md-dev-be.azurewebsites.net/" +
                              //   fullProfileData?.imagePath
                              // : "assets/images/profile.jpg"
                            }
                            alt=""
                          />{" "}
                        </div>
                        <h5 className="mb-1">
                          {fullProfileData && fullProfileData?.fullName
                            ? fullProfileData?.fullName
                            : "-"}
                        </h5>
                        <div className="row mt-4">
                          <div className="col-md-6 mb-3">
                            <p>Email</p>
                            <span>
                              {fullProfileData && fullProfileData?.email
                                ? fullProfileData?.email
                                : "-"}
                            </span>
                          </div>
                          <div className="col-md-6 mb-3">
                            <p>Mobile Number</p>
                            <span>
                              {fullProfileData && fullProfileData?.mobileNo
                                ? fullProfileData?.mobileNo
                                    .toString()
                                    .slice(0, 3) +
                                  "-" +
                                  fullProfileData?.mobileNo
                                    .toString()
                                    .slice(3, 6) +
                                  "-" +
                                  fullProfileData?.mobileNo
                                    .toString()
                                    .slice(6, 10)
                                : "-"}
                            </span>
                          </div>
                          <div className="col-md-6 mb-3">
                            <p>Gender</p>
                            {fullProfileData &&
                            fullProfileData?.gender === 1 ? (
                              <span>Male</span>
                            ) : fullProfileData?.gender === 2 ? (
                              <span>Female</span>
                            ) : fullProfileData?.gender === 3 ? (
                              <span>Other</span>
                            ) : (
                              "-"
                            )}
                          </div>
                          <div className="col-md-6 mb-3">
                            <p>Date of Birth</p>
                            <span>
                              {fullProfileData?.dateOfBirth?fullProfileData?.dateOfBirth:"-"}
                            </span>
                          </div>
                          <div className="col-md-6 mb-3">
                            <p>Height</p>
                            <span>
                              {fullProfileData && fullProfileData?.height
                                ? fullProfileData?.height
                                : "-"}
                            </span>
                          </div>
                          <div className="col-md-6 mb-3">
                            <p>Weight</p>
                            <span>
                              {fullProfileData && fullProfileData?.weight
                                ? fullProfileData?.weight + " lbs"
                                : "-"}
                            </span>
                          </div>
                        </div>
                        <div className="addtag mb-3">
                          <div className="title">
                            <h6>Focusing on</h6>
                            <ul className="tag-wrap">
                              {fullProfileData?.focus.map((element, index) => (
                                <li key={index}>{element}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <div className="addtag">
                          <div className="title">
                            <h6>Pre-existing condition(s)</h6>
                            <ul className="tag-wrap">
                              {fullProfileData?.conditions.map(
                                (item, index) => (
                                  <li key={index}>{item}</li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* EDIT PROFILE */}
                  {isEdit === true ? (
                    <div className="profile-btm d-flex align-items-center justify-content-between">
                      <div className="profile w-100 profile-dtls">
                        <div className="avatar-upload img">
                          <div className="avatar-edit">
                            <input
                              type="file"
                              id="imageUpload"
                              {...getInputProps()}
                              name="file"
                              accept=".png, .jpg, .jpeg"
                            />
                            <label htmlFor="imageUpload"></label>
                          </div>
                          <div className="avatar-preview">
                            {/* style="background-image: url(assets/images/profile.jpg);" */}
                            {/* <img src={data.fileUrl} alt="" /> */}
                            <img
                              src={
                                 data?.fileUrl === null || ""
                                  ? "assets/images/profile.jpg"
                                  : data?.fileUrl === fullProfileData?.imagePath
                                  ? BASE_URL_IMAGE +
                                    fullProfileData?.imagePath
                                  : data?.fileUrl
                              }
                              alt=""
                            />
                          </div>
                        </div>

                        <h5 className="mb-1">
                          {fullProfileData && fullProfileData?.fullName
                            ? fullProfileData?.fullName
                            : "-"}
                        </h5>
                        <div className="row mt-4 profile-edit-wrap">
                          <div className="col-md-6 mb-3">
                            <div className="form-group profile-email">
                              <FloatingLabelTextInput
                                label={"Email"}
                                onChange={onChange}
                                isMandatory={true}
                                value={data?.email}
                                name={"email"}
                                placeholder={"Email"}
                              />
                              {errors && errors.email && (
                                <div className="errors">{errors.email}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <div className="form-group">
                              <FloatingLabelTextInput
                                label={"Mobile Number"}
                                onChange={onChange}
                                isMandatory={true}
                                value={data?.mobileNo}
                                name={"mobileNo"}
                                placeholder={"Mobile Number"}
                              />
                              {errors && errors.mobileNo && (
                                <div className="errors">{errors.mobileNo}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <div className="form-group">
                              <FloatingLabelDropDown
                                label={"Gender"}
                                onChange={onChange}
                                isMandatory={false}
                                value={data?.gender}
                                name={"gender"}
                                optionsList={gender}
                              />
                              {errors && errors.gender && (
                                <div className="errors">{errors.gender}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <div className="form-group">
                              <DatePicker
                                dateFormat={"MM-dd-yyyy"}
                                selected={data?.dateOfBirth?new Date(data?.dateOfBirth):new Date()}
                                className="date"
                                name="dateOfBirth"
                                onChange={(date) => dobChange(date)}
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                dropdownMode="select"
                                maxDate={moment().toDate()}
                              ></DatePicker>
                              {errors && errors.dateOfBirth && (
                                <div className="errors">
                                  {errors.dateOfBirth}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-6 mb-3">
                                <div className="form-group pad-2">
                                  <select
                                    value={data?.feet}
                                    placeholder={"Feet"}
                                    onChange={onChange}
                                    name={"feet"}
                                  >
                                    <option value={0}> </option>
                                    {feetValues.map((number) => (
                                      <option key={number} value={number}>
                                        {number}
                                      </option>
                                    ))}
                                  </select>
                                  {errors && errors.feet && (
                                    <div className="errors">{errors.feet}</div>
                                  )}

                                  <label htmlFor="feet">Feet</label>
                                </div>
                              </div>

                              <div className="col-md-6 mb-3">
                                <div className="form-group pad-2">
                                  <select
                                    value={data?.inch}
                                    placeholder={"Inch"}
                                    onChange={onChange}
                                    name={"inch"}
                                  >
                                    <option value={0}> </option>
                                    {inchValues.map((number) => (
                                      <option key={number} value={number}>
                                        {number}
                                      </option>
                                    ))}
                                  </select>
                                  {errors && errors.inch && (
                                    <div className="errors">{errors.inch}</div>
                                  )}
                                  <label htmlFor="inch">Inch</label>
                                </div>
                                <span>
                                  {/* {fullProfileData ? fullProfileData.height : "-"} */}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 mb-3">
                            <div className="form-group">
                              <FloatingLabelTextInput
                                type="number"
                                label={"Weight"}
                                onChange={onChange}
                                isMandatory={true}
                                value={data?.weight}
                                name={"weight"}
                                placeholder={"Weight"}
                                minValue={1}
                                maxValue={9}
                              />
                              {errors && errors.weight && (
                                <div className="errors">{errors.weight}</div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="addtag mb-3">
                          <div className="title">
                            <h6>Focusing on</h6>
                            <ul className="tag-wrap">
                              {fullProfileData?.focus?.map((element, index) => (
                                <li key={index}>{element}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <div className="addtag">
                          <div className="title">
                            <h6>Pre-existing condition(s)</h6>
                            <ul className="tag-wrap">
                              {fullProfileData?.conditions?.map(
                                (item, index) => (
                                  <li key={index}>{item}</li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                        {isEdit === true ? (
                          <div className="d-flex gap-2">
                            {enableSubmit === true ? (
                              <button
                                type="button"
                                className="btn-primary"
                                onClick={() => {
                                  onSubmit();
                                }}
                                disabled
                              >
                                Save
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn-primary"
                                onClick={() => {
                                  onSubmit();
                                }}
                              >
                                Save
                              </button>
                            )}
                            <button
                              type="button"
                              className="btn-secondary"
                              onClick={() => {
                                onCancel();
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* </div> */}
      </Modal>
      {/* <EditFullProfile
        isEditProfileVisible={isEditModalOpen}
        modalClose={() => {
          setEditModalOpen(false);
        }}
        initialProfileData={fullProfileData}
      /> */}
    </>
  );
};

const mapDispatchToProps = {
  getFullProfile: () => getFullProfile(),
  updateFullProfile: (data: any, callback: Function) =>
    updateFullProfile(data, callback),
  getGender: () => getGender(),
};

function mapStateToProps(state: any) {
  return {
    fullProfileData: state.profile?.fullProfileData,
    gender: state.commonDropdowns?.gender,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FullProfile);
