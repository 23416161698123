import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTER_URL } from "../../constants/routerUrl";

const VerifyOTP = () => {
  const navigate = useNavigate();

  const verify = () => {
    navigate(ROUTER_URL.USER_DETAILS);
  };
  return (
    <>
      <div className="login-wrap">
        <header>
          <a href="">
            <img src="assets/images/logo.webp" alt="" />
          </a>
        </header>
        <main>
          <div className="login-card">
            <div className="title">
              <h3>Verify OTP</h3>
              <p>
                Check your email
                <span className="fw-bold text-dark">
                  johndoe@example.com
                </span>{" "}
                for the OTP.
              </p>
            </div>
            <div className="form-group">
              <input type="Password" placeholder="OTP" id="otp" />
              <label
              //    for="otp"
              >
                OTP
              </label>
              <div className="icon">
                <img src="assets/images/icons/lock.svg" alt="" />
              </div>
              <div className="show-password">
                <a href="">
                  <img src="assets/images/icons/eye.svg" alt="" />
                </a>
              </div>
            </div>
            <div className="form-group mt-4 mb-3">
              <button onClick={verify}>Verify Email</button>
            </div>
            <div className="d-flex align-items-center justify-content-between position-relative">
              <div className="txt-secondary fs-14">
                Didn't get the code?
                <a href="login.html" className="fw-semibold txt-primary m-1">
                  Resend
                </a>
              </div>
              <div className="forgot">
                <a
                  href="javascript:void(0)"
                  className="fw-semibold txt-primary"
                >
                  Need Help?
                </a>
                <div className="tooltip-login">
                  <h6>Finding the OTP</h6>
                  <p>
                    "If you are unable to locate your OTP Code, please check you
                    Junk and Spam folders. The email will be sent from
                    verify@mdhealth.com. You can also search your inbox for this
                    address to locate the email. Please be sure to mark as "Not
                    Spam" or "Not Junk" or add to your contact list as important
                    updates to your account will also be sent from this email
                    address"
                  </p>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center"></div>
          </div>
        </main>
        <footer>
          <p>&copy; 2023 Medical Weight Loss by Healthogenics.</p>
        </footer>
      </div>
    </>
  );
};

export default VerifyOTP;
