import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ROUTER_URL } from "../../../constants/routerUrl";
import { connect } from "react-redux";
import { IHeaderProfileDisplay } from "../../../models/profile";
import { getHeaderProfile } from "../../../actions/profileActionCreator";

const Header = (props: IHeaderProfileDisplay) => {
  const navigate = useNavigate();
  const { getHeaderProfile, headerProfileData } = props;

  const logout = () => {
    window.location.href = "/";
    localStorage.clear();
    // window.location.href = "http://localhost:3000/login";
  };

  useEffect(() => {
    getHeaderProfile();
  }, []);

  return (
    <>
      <header>
        <div className="logo">
          <a
            href="#"
            draggable="false"
            style={{ pointerEvents: "none" }}
            onDragStart={(e) => e.preventDefault()}
          >
            <img src="assets/images/logo.webp" alt="" draggable="false" />
          </a>
        </div>
        <div className="nav-wrapper">
          <input
            type="checkbox"
            id="primary-toggle"
            aria-controls="primary-navigation"
            hidden
          />
          <label htmlFor="primary-toggle" className="primary-toggle">
            <div className="line" aria-hidden="true"></div>
            <span className="visually-hidden">Menu</span>
          </label>
          <nav className="dashboard primary-navigation" id="primary-navigation">
            <div className="nav-list" aria-label="Primary" role="list">
              <NavLink to={ROUTER_URL.ADMIN_TEST_PURCHASED}>
                Test's Purchased
              </NavLink>
              <NavLink to={ROUTER_URL.ADMIN_USER_LIST}>User List</NavLink>
              <NavLink to={ROUTER_URL.ADMIN_SURVEY_LIST}>Survey List</NavLink>
              <NavLink to={ROUTER_URL.ADMIN_BARCODE_LISTING}>Barcode</NavLink>
              <NavLink to={ROUTER_URL.ADMIN_SETTINGS}>Settings</NavLink>
            </div>
          </nav>
        </div>
        <div className="nav-item dropdown profile-drop">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div className="img">
              <img src="assets/images/profile.jpg" alt="" draggable="false" />
              <div className="check">
                <i className="fa-sharp fa-solid fa-circle-check"></i>
              </div>
            </div>
            <span>
              {headerProfileData && headerProfileData?.fullName
                ? headerProfileData && headerProfileData?.fullName
                : "-"}
            </span>
          </a>
          <ul className="dropdown-menu">
            {/* <li>
              <a className="dropdown-item" href="#">
                Profile
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Setting
              </a>
            </li> */}
            {/* <li>
              <hr className="dropdown-divider" />
            </li> */}
            <li>
              <a className="dropdown-item" href="#" onClick={() => logout()}>
                Logout
              </a>
            </li>
          </ul>
        </div>
      </header>
    </>
  );
};

const mapDispatchToProps = {
  getHeaderProfile: () => getHeaderProfile(),
};

function mapStateToProps(state: any) {
  return {
    headerProfileData: state.profile?.headerProfileData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
