import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTER_URL } from "../../constants/routerUrl";

const BodyComposition = () => {
  const navigate = useNavigate();

  const finish = () => {
    navigate(ROUTER_URL.DASHBOARD);
  };
  return (
    <>
      <div className="login-wrap">
        <header>
          <a href="">
            <img src="assets/images/logo.webp" alt="" />
          </a>
        </header>
        <main>
          <div className="login-card">
            <div className="title">
              <h3>Your Body Composition</h3>
              <p>Update your body composition to help us understand more.</p>
            </div>
            <div className="row">
              <div className="col-md-12 mb-2">
                <h4 className="text-center mb-3">How tall are you?</h4>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group pad-2">
                      <input
                        type="number"
                        min="1"
                        max="9"
                        placeholder="Feet"
                        id="feet"
                      />
                      <label
                      // for="feet"
                      >
                        Feet
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group pad-2">
                      <input
                        type="number"
                        min="1"
                        max="9"
                        placeholder="Inch"
                        id="inch"
                      />
                      <label
                      // for="inch"
                      >
                        Inch
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mb-2">
                <h4 className="text-center mb-3">
                  What is your current weight?
                </h4>
                <div className="form-group pad-right">
                  <input
                    type="number"
                    min="1"
                    max="9"
                    placeholder="Weight"
                    id="Weight"
                  />
                  <label
                  // for="Weight"
                  >
                    Weight
                  </label>
                  <span className="highlights">Kg</span>
                </div>
              </div>

              <div className="col-md-12 mb-2">
                <h4 className="text-center mb-3">Few more details</h4>
                <div className="form-group pad-right">
                  <input
                    className="date"
                    type="date"
                    placeholder="dob"
                    id="dob"
                  />
                  <label
                  // for="dob"
                  >
                    Date of Birth
                  </label>
                </div>
                <div className="form-group pad-right">
                  <select
                    className="floating-select"
                    //   onclick="this.setAttribute('value', this.value);"
                    value=""
                    placeholder="gender"
                    id="gender"
                  >
                    <option value=""></option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                  </select>
                  <label
                  // for="gender"
                  >
                    Gender
                  </label>
                </div>
              </div>
              <div className="form-group mt-4 mb-3" onClick={finish}>
                <button>Finish</button>
              </div>
            </div>
          </div>
        </main>
        <footer>
          <p>&copy; 2023 Medical Weight Loss by Healthogenics.</p>
        </footer>
        <script src="assets/js/jquery-3.7.0.min.js"></script>
        <script src="assets/js/bootstrap.min.js"></script>
        <script></script>
      </div>
    </>
  );
};

export default BodyComposition;
