import { createSlice } from "@reduxjs/toolkit";
import { ISettingsSlice } from "../../../models/admin/settings";

const initialSettingsState: ISettingsSlice = {
  settingsData: undefined,
};

const settingsSlice = createSlice({
  name: "adminSettings",
  initialState: initialSettingsState,
  reducers: {
    setSettingsData: (state, action: { payload: any }) => {
      state.settingsData = action.payload;
    },
  },
});

export const { setSettingsData } = settingsSlice.actions;

const settingsReducer = settingsSlice.reducer;

export type SettingsState = ReturnType<typeof settingsReducer>;

export default settingsReducer;
