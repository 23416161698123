import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  IHeaderProfileDisplay,
  IHeaderProfileUserDisplay,
} from "../../../../models/profile";
import { getHeaderProfile } from "../../../../actions/profileActionCreator";
import FullProfile from "../profile/component/fullProfile";
import { ROUTER_URL } from "../../../../constants/routerUrl";
import { useNavigate } from "react-router-dom";
import { getSettingsList } from "../../../../actions/adminSettingsActionCreator";
import { BASE_URL_IMAGE } from "../../../../constants/constants";

const Header = (props: IHeaderProfileUserDisplay) => {
  const navigate = useNavigate();

  const { getHeaderProfile, headerProfileData, getSettingsList, settingsData } =
    props;
  const [isFullProfileVisible, setIsFullProfileVisible] = useState(false);

  const [ourStoreURL, setOurStoreURL] = useState<string>("");
  const [myEliminationDietURL, setMyEliminationDietURL] = useState<string>("");
  const [ContactUsURL, setContactUsURL] = useState<string>("");
  const [testingURL, setTestingURL] = useState<string>("");

  // useEffect(() => {
  //   getHeaderProfile();

  // }, []);

  useEffect(() => {
    const fetchData = () => {
      getHeaderProfile();
      getSettingsList(1, 100, "");
    };
    // const fetchURL=()=>{
    //   const KitInstruction: any = settingsData?.data &&
    //   settingsData.data.find((item: any) => item.name === "KitInstruction");
    //   var KitInstructionLink:string=KitInstruction?.value?KitInstruction.value:"";
    //   setKitInstructionURL(KitInstructionLink);
    //   const HelpCenter: any =settingsData?.data &&
    //   settingsData.data.find((item: any) => item.name === "HelpCenter");
    //   var HelpCenterLink:string=HelpCenter?.value?HelpCenter.value:"";
    //   setHelpCenterURL(HelpCenterLink);
    //   const ShopNow: any =settingsData?.data &&
    //   settingsData.data.find((item: any) => item.name === "ShopNow");
    //   var ShopNowLink:string=ShopNow?.value?ShopNow.value:"";
    //   setShopNowURL(ShopNowLink);
    //   const video: any =settingsData?.data &&
    //   settingsData.data.find((item: any) => item.name === "video");
    //   var videoLink:string=video?.value?video.value:"";
    //   setVideoURL(videoLink);
    // }
    fetchData();
    // fetchURL()
    const interval = setInterval(fetchData, 5 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const ourStore: any =
      settingsData?.data &&
      settingsData.data.find((item: any) => item.name === "ourStore");
    var ourStoreLink: string = ourStore?.value ? ourStore.value : "";
    setOurStoreURL(ourStoreLink);
    const myEliminationDiet: any =
      settingsData?.data &&
      settingsData.data.find((item: any) => item.name === "myEliminationDiet");
    var myEliminationDietLink: string = myEliminationDiet?.value
      ? myEliminationDiet.value
      : "";
    setMyEliminationDietURL(myEliminationDietLink);
    const ContactUs: any =
      settingsData?.data &&
      settingsData.data.find((item: any) => item.name === "ContactUs");
    var ContactUsLink: string = ContactUs?.value ? ContactUs.value : "";
    setContactUsURL(ContactUsLink);
    const Testing: any =
      settingsData?.data &&
      settingsData.data.find((item: any) => item.name === "testing");
    var testingLink: string = Testing?.value ? Testing.value : "";
    setTestingURL(testingLink);
  }, [settingsData]);

  const applyProfileVisible = () => {
    setIsFullProfileVisible(true);
  };

  const logout = () => {
    // window.location.href = "/login";
    localStorage.clear();

    navigate(ROUTER_URL.LOGIN);
  };

  let token: any = localStorage.getItem("access_token");
  return (
    <>
      <header>
        <div className="logo">
          <a
            href="#"
            draggable="false"
            style={{ pointerEvents: "none" }}
            onDragStart={(e) => e.preventDefault()}
          >
            <img src="assets/images/logo.webp" alt="" draggable="false" />
          </a>
        </div>
        <div className="nav-wrapper">
          <input
            type="checkbox"
            id="primary-toggle"
            aria-controls="primary-navigation"
            hidden
          />
          <label htmlFor="primary-toggle" className="primary-toggle">
            <div className="line" aria-hidden="true"></div>
            <span className="visually-hidden">Menu</span>
          </label>
          <nav className="dashboard primary-navigation" id="primary-navigation">
            <div className="nav-list" aria-label="Primary" role="list">
              <a href={ourStoreURL} target="_blank">
                Our Store
              </a>

              <a href={myEliminationDietURL} target="_blank">
                My Elimination Diet
              </a>

              <a href={ContactUsURL} target="_blank">
                Contact Us
              </a>
            </div>
          </nav>
        </div>
        <div className="nav-item dropdown profile-drop">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div className="img">
              <img
                src={
                  headerProfileData?.profileImage
                    ? BASE_URL_IMAGE + headerProfileData?.profileImage
                    : "assets/images/profile.jpg"
                }
                alt=""
                draggable="false"
              />
              <div className="check">
                <i className="fa-sharp fa-solid fa-circle-check"></i>
              </div>
            </div>{" "}
            <span>
              {headerProfileData && headerProfileData.fullName
                ? headerProfileData && headerProfileData.fullName
                : "-"}
            </span>
          </a>
          <ul className="dropdown-menu">
            <li>
              <a
                className="dropdown-item"
                onClick={(event) => applyProfileVisible()}
              >
                Profile
              </a>
            </li>
            {/* <li>
              <a className="dropdown-item" href="#">
                Setting
              </a>
            </li> */}
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <a className="dropdown-item" href="" onClick={() => logout()}>
                Logout
              </a>
            </li>
          </ul>
        </div>
      </header>
      <FullProfile
        isProfileVisible={isFullProfileVisible}
        onClose={() => setIsFullProfileVisible(false)}
      />
    </>
  );
};

const mapDispatchToProps = {
  getHeaderProfile: () => getHeaderProfile(),
  getSettingsList: (pageNumber: number, pageSize: number, keyword: string) =>
    getSettingsList(pageNumber, pageSize, keyword),
};

function mapStateToProps(state: any) {
  return {
    headerProfileData: state.profile?.headerProfileData,
    settingsData: state.adminSettings?.settingsData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
