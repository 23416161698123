import userListService from "../apiServices/admin/userListService";
import { alertConstants } from "../helper/sweet-alert";
import {
  IActivateOrDeactivate,
  IResetPassword,
} from "../models/admin/userList";
import { setTestPurchasedData } from "../store/slices/admin/testPurchase";
import {
  setUserListData,
  setUserListExportData,
} from "../store/slices/admin/userList";

export function getUserList(
  pageNumber: number,
  pageSize: number,
  keyword: string
) {
  return async function (dispatch: any, getState: any) {
    // const data = {
    //   data: [
    //     {
    //       id: 1,
    //       userId: 10029,
    //       firstName: "Theresa",
    //       lastName: "Webb",
    //       email: "theresa.webb@example.com",
    //       mobileNumber: "(208) 555-0112",
    //       active: true,
    //       fileUrl: "string",
    //     },
    //     {
    //       id: 2,
    //       userId: 10029,
    //       firstName: "Theresa",
    //       lastName: "Webb",
    //       email: "theresa.webb@example.com",
    //       mobileNumber: "(208) 555-0112",
    //       active: false,
    //       fileUrl: "string",
    //     },
    //     {
    //       id: 3,
    //       userId: 10029,
    //       firstName: "Theresa",
    //       lastName: "Webb",
    //       email: "theresa.webb@example.com",
    //       mobileNumber: "(208) 555-0112",
    //       active: false,
    //       fileUrl: "string",
    //     },
    //     {
    //       id: 4,
    //       userId: 10029,
    //       firstName: "Theresa",
    //       lastName: "Webb",
    //       email: "theresa.webb@example.com",
    //       mobileNumber: "(208) 555-0112",
    //       active: true,
    //       fileUrl: "string",
    //     },
    //   ],
    //   totalRecords: 4,
    // };

    let response = await userListService.getUserList(
      pageNumber,
      pageSize,
      keyword
    );
    if (response?.success === true) {
      // if (data) {
      dispatch(setUserListData(response.data));
      // dispatch(setUserListData(data));
    } else {
    }
  };
}

function downloadFile(response: any, name: string) {
  var blob = new Blob([response]);

  let a = document.createElement("a");

  var url = window.URL.createObjectURL(blob);

  a.href = url;

  a.download = `${name} ${new Date()}.xlsx`;

  a.click();

  window.URL.revokeObjectURL(url);

  return true;
}

export function getUserListExport(keyword: string) {
  return async function (dispatch: any, getState: any) {
    let response = await userListService.getUserListExport(keyword);
    downloadFile(response, "file");
  };
}

export function activateOrDeactivate(
  userId: number,
  isactive: boolean,
  callback: Function
) {
  return async function (dispatch: any, getState: any) {
    let response = await userListService.activateOrDeactivate(userId, isactive);
    if (response?.success === true) {
      callback(alertConstants.success);
    } else {
      callback(alertConstants.errror);
    }
  };
}

export function resetPasswordUserList(email:string, password:string, callback:Function) {
  return async function (dispatch: any, getState: any) {
    let response = await userListService.resetPasswordUserList(email, password, callback);
    if (response === true) {
      callback(true);
    } else if(response===false){
      callback(false);
    }else{
      callback(alertConstants.errror);
    }
  };
}
