import { IApiResponse } from "../../models/IAPIResponse";
import APIService from "../../helper/api-service";
import { registerNewKit } from "../../constants/apiUrl";
import { urlBinder } from "../../helper/url-binder";
import {
  IBarcodeRegister,
  IFinalDataForOthers,
} from "../../models/barcodeRegister";
import { ICommon } from "../../models/registeration";

export default new (class RegisterKitService {
  public registerNewBarcode = async (
    data: IBarcodeRegister,
    callback: Function
  ): Promise<any> => {
    try {
      let endPoint = registerNewKit.POST_REGISTER_NEW_BARCODE;
      const response = await APIService.post(endPoint, data);
      if (response !== undefined || null) {
        if (response?.data?.success === true) {
          return response?.data as any;
        } else if (response?.data?.success === false) {
          return "needToHandleError";
        } else {
          return "needToHandleError";
        }
      } else {
        return "needToHandleError";
      }
    } catch (error: any) {
      if (error.response) {
        return error.response as string;
      } else {
        throw error;
      }
    }
  };

  public registerKitForSelf = async (
    isSelfregistered: Boolean,
    name: string,
    barcode: string,
    data: IFinalDataForOthers
  ): Promise<boolean> => {
    try {
      let endPoint = urlBinder(registerNewKit.POST_RERGISTER_KIT_FOR_OTHERS, [
        isSelfregistered,
        name,
        barcode,
      ]);
      const response = await APIService.post(endPoint, data);
      if(response?.data==true){
        return true;
       }else{
        return false;
      }
    } catch (error: any) {
      if (error.response) {
        return error.response as boolean;
      } else {
        throw error;
      }
    }
  };

  public registerForOthers = async (
    data: IFinalDataForOthers,
    callback: Function
  ): Promise<boolean> => {
    try {
      let endPoint = registerNewKit.POST_RERGISTER_KIT_FOR_OTHERS;
      const response = await APIService.post(endPoint, data);
      return response?.data as boolean;
    } catch (error: any) {
      if (error.response) {
        return error.response as boolean;
      } else {
        throw error;
      }
    }
  };

  public getOthers = async (): Promise<IApiResponse<ICommon>> => {
    try {
      let endPoint = registerNewKit.GET_TEST_PURCHASED_FOR_OTHERS;
      // const response = await APIService.get(endPoint);
      const response = await APIService.get(endPoint);
      return response?.data as IApiResponse<ICommon>;
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<ICommon>;
      } else {
        throw error;
      }
    }
  };
})();
