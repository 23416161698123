import settingsService from "../apiServices/admin/settingsService";
import surveyListService from "../apiServices/admin/surveyListService";
import { alertConstants } from "../helper/sweet-alert";
import { ISettingEdit, ISettingUpdate } from "../models/ICommon";
import { setSettingsData } from "../store/slices/admin/settings";

export function getSettingsList(  
  pageNumber: number,
  pageSize: number,
  keyword: string) {
  return async function (dispatch: any, getState: any) {
    let response = await settingsService.getSettingsIndex(pageNumber, pageSize, keyword);
    if (response?.status === 200) {
      dispatch(setSettingsData(response?.data));
    } else {
    }
  };
}

export function updateSettings(data:ISettingUpdate, callback: Function) {
  return async function (dispatch: any, getState: any) {
    let response = await settingsService.editSettings(data);
    if (response === undefined) {
      callback(alertConstants.failed);
    } else if (response?.data === true) {
      callback(alertConstants.success);
    } else {
      callback(alertConstants.failed);
    }
  };
}

// function downloadFile(response: any, name: string) {
//   var blob = new Blob([response]);

//   let a = document.createElement("a");

//   var url = window.URL.createObjectURL(blob);

//   a.href = url;

//   a.download = `${name} ${new Date()}.xlsx`;

//   a.click();

//   window.URL.revokeObjectURL(url);

//   return true;
// }
