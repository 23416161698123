import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTER_URL } from "../../constants/routerUrl";

const MedicalConditions = () => {
  const navigate = useNavigate();

  //function to redirect to body composition
  const next = () => {
    navigate(ROUTER_URL.BODY_COMPOSITION);
  };
  return (
    <>
      {" "}
      <div className="login-wrap">
        <header>
          <a href="">
            <img src="assets/images/logo.webp" alt="" />
          </a>
        </header>
        <main>
          <div className="login-card">
            <div className="title">
              <h3>One More Question</h3>
              <p>
                Do you have any of the following conditions? This helps our
                doctors recommend the right tests for you.
              </p>
            </div>
            <div className="row">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <div className="checkbox-round">
                  <input
                    type="checkbox"
                    id="multi-check1"
                    name="multi-check1"
                    value="multi-check"
                  />
                  <label
                  // for="multi-check1"
                  >
                    None
                  </label>
                </div>

                <div className="checkbox-round">
                  <input
                    type="checkbox"
                    id="multi-check2"
                    name="multi-check2"
                    value="multi-check"
                  />
                  <label
                  // for="multi-check2"
                  >
                    High Cholesterol
                  </label>
                </div>

                <div className="checkbox-round">
                  <input
                    type="checkbox"
                    id="multi-check3"
                    name="multi-check3"
                    value="multi-check"
                  />
                  <label
                  // for="multi-check3"
                  >
                    Diabetes Type II
                  </label>
                </div>

                <div className="checkbox-round">
                  <input
                    type="checkbox"
                    id="multi-check4"
                    name="multi-check4"
                    value="multi-check"
                  />
                  <label
                  // for="multi-check4"
                  >
                    High Blood Pressure
                  </label>
                </div>

                <div className="checkbox-round">
                  <input
                    type="checkbox"
                    id="multi-check5"
                    name="multi-check5"
                    value="multi-check"
                  />
                  <label
                  // for="multi-check5"
                  >
                    Joint Pain
                  </label>
                </div>
              </div>
              <div className="form-group mt-4 mb-3" onClick={next}>
                <button>Next</button>
              </div>
            </div>
          </div>
        </main>
        <footer>
          <p>&copy; 2023 Medical Weight Loss by Healthogenics.</p>
        </footer>
        <script src="assets/js/jquery-3.7.0.min.js"></script>
        <script src="assets/js/bootstrap.min.js"></script>
      </div>
    </>
  );
};

export default MedicalConditions;
