import { Route, Routes } from "react-router-dom";
import { ROUTER_URL } from "../constants/routerUrl";
import SampleRegisteration from "../pages/dashboard/components/sampleRegisteration";
import Dashboard from "../pages/dashboard/dashboard";
import TestResult from "../pages/dashboard/components/testResult/testResult";
import RecommendedSupplement from "../pages/dashboard/components/recommendedSupplement";
import HowItWorks from "../pages/dashboard/components/howItWorks";
import Instruction from "../pages/dashboard/components/instruction";
import Profile from "../pages/dashboard/components/profile/profile";
import ContactDetails from "../pages/dashboard/components/contactDetails";
import FullProfile from "../pages/dashboard/components/profile/component/fullProfile";
import Login from "../pages/login/login";
import CreateAccount from "../pages/registration/registration";
import VerifyOTP from "../pages/registration/verifyOTP";
import UserDetails from "../pages/registration/userDetails";
import Focus from "../pages/registration/focus";
import MedicalConditions from "../pages/registration/medicalConditions";
import BodyComposition from "../pages/registration/bodyComposition";
import EditFullProfile from "../pages/dashboard/components/profile/component/editFullProfile";
import TestPurchased from "../pages/admin/testPurchase";
import UserList from "../pages/admin/userList";
import SurveyList from "../pages/admin/surveyList";
import TestResultDownload from "../pages/admin/component/testResultDownload";
import { AuthCallback } from "../pages/callback/callback";
import Registration from "../pages/registration/registration";
import ForgotPassword from "../pages/login/forgotPassword";
import BarcodeRegister from "../pages/dashboard/components/purchaseNewKit/components/barcodeRegister";
import KitRegisterForSelf from "../pages/dashboard/components/purchaseNewKit/components/kitRegisterForSelf";
import RegistrationForOthers from "../pages/registrationForOthers/registrationForOthers";
import path from "path";
import EmailForForgotPassword from "../pages/login/emailForForgotPassword";
import Settings from "../pages/admin/settings";
import BarcodeListing from "../pages/admin/barcodeListing";
import SettingsEdit from "../pages/admin/settingsEdit";

export default function Router() {
  let token = localStorage.getItem("access_token");

  return (
    <Routes>
      {/* ****************************************************LOGIN**************************************************** */}
      {/* <Route path={ROUTER_URL.LOGIN} element={<Login />} /> */}
      <Route path={ROUTER_URL.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route
        path={ROUTER_URL.EMAIL_FOR_FORGOT_PASSWORD}
        element={<EmailForForgotPassword />}
      />

      <Route path={ROUTER_URL.AUTHCALLBACK} element={<AuthCallback />} />
      {/* ****************************************************REGISTRATION PAGES**************************************************** */}
      <Route path={ROUTER_URL.CREATE_ACCOUNT} element={<CreateAccount />} />
      <Route path={ROUTER_URL.VERIFY_OTP} element={<VerifyOTP />} />
      <Route path={ROUTER_URL.USER_DETAILS} element={<UserDetails />} />
      <Route path={ROUTER_URL.FOCUS} element={<Focus />} />
      <Route
        path={ROUTER_URL.MEDICAL_CONDITIONS}
        element={<MedicalConditions />}
      />
      <Route path={ROUTER_URL.BODY_COMPOSITION} element={<BodyComposition />} />
      <Route path={ROUTER_URL.USER_REGISTER} element={<Registration />} />
      {/* ****************************************************DASHBOARD**************************************************** */}
      <Route path={ROUTER_URL.DASHBOARD} element={<Dashboard />} />
      <Route
        path="/"
        element={
          localStorage.getItem("access_token") &&
          localStorage.getItem("Role") === "User" ? (
            <Dashboard />
          ) : localStorage.getItem("Role") === "Admin" ? (
            <TestPurchased />
          ) : (
            <Login />
          )
        }
      />
      <Route path="*" element={<Login />} />
      <Route
        path={ROUTER_URL.SAMPLE_REGISTRATION}
        element={<SampleRegisteration />}
      />
      <Route path={ROUTER_URL.TEST_RESULT} element={<TestResult />} />
      <Route
        path={ROUTER_URL.RECOMMENDED_SUPPLEMENT}
        element={<RecommendedSupplement />}
      />
      {/* <Route path={ROUTER_URL.HOW_IT_WORKS} element={<HowItWorks />} /> */}
      {/* <Route path={ROUTER_URL.INSTRUCTION} element={<Instruction />} /> */}
      <Route path={ROUTER_URL.PROFILE} element={<Profile />} />
      <Route path={ROUTER_URL.CONTACT_DETAIL} element={<ContactDetails />} />
      <Route path={ROUTER_URL.BARCODE_REGISTER} element={<BarcodeRegister />} />
      <Route
        path={ROUTER_URL.KIT_REGISTER_FOR_SELF}
        element={<KitRegisterForSelf />}
      />
      <Route
        path={ROUTER_URL.REGISTRATION_FOR_OTHERS}
        element={<RegistrationForOthers />}
      />
      {/* ****************************************************ADMIN**************************************************** */}
      <Route
        path={ROUTER_URL.ADMIN_TEST_PURCHASED}
        element={<TestPurchased />}
      />
      <Route path={ROUTER_URL.ADMIN_USER_LIST} element={<UserList />} />
      <Route path={ROUTER_URL.ADMIN_SURVEY_LIST} element={<SurveyList />} />
      <Route
        path={ROUTER_URL.ADMIN_TEST_RESULT_DOWNLOAD}
        element={<TestResultDownload />}
      />
      <Route
        path={ROUTER_URL.ADMIN_BARCODE_LISTING}
        element={<BarcodeListing />}
      />
      <Route path={ROUTER_URL.ADMIN_SETTINGS} element={<Settings />} />
      <Route path={ROUTER_URL.SETTINGS_EDIT} element={<SettingsEdit />} />
    </Routes>
  );
}
