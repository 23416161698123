import React from "react";

interface FloatingLabelDropDownProp {
  label: string;
  onChange: any;
  isMandatory: boolean;
  value: any;
  name: string;
  optionsList: any;
  hideBlankRow?: boolean;
  disable?: boolean;
  placeholder?: string;
  id?: any;
}

const FloatingLabelDropDown = (props: FloatingLabelDropDownProp) => {
  const { onChange } = props;
  const {
    label,
    isMandatory,
    value,
    name,
    optionsList,
    hideBlankRow,
    disable,
    placeholder,
    id,
  } = props;

  return (
    <>
      <div className="wrap-input">
        <select
          className="floating-select"
          // id="newSpecialize"
          name={name}
          // title="Status"
          onChange={(e) => onChange(e)}
          value={value}
          placeholder={placeholder}
          disabled={disable}
        >
          {!hideBlankRow && <option value=""></option>}
          {optionsList != undefined
            ? optionsList.map(function (
                object: {
                  id: string | number | readonly string[] | undefined;
                  name:
                    | boolean
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined
                    | string;
                },
                index: number
              ) {
                return (
                  <option key={index} value={object.id}>
                    {object.name}
                  </option>
                );
              })
            : null}
        </select>
        {label && (
          <label className={`${value != -1 ? "active" : ""}`}>{label}</label>
        )}
      </div>
    </>
  );
};

export default FloatingLabelDropDown;
