import React from "react";
import { IHelpCenterDisplay } from "../../../models/dashboard";

const HelpCenter = (props: IHelpCenterDisplay) => {
  const {url} =props;
  return (
    <>
      <div className="col-md-6 mt-3 position-relative">
        <div className="blk-kit blk-yellow">
          <h4>Step 3: Understanding Your Results</h4>
          <p>
            Visit our Help Center to learn more about your results, access your
            E-Books, find answers to some common questions about our tests, and
            more!
          </p>
          <div className="d-flex justify-content-end">
            <a href={url}target="_blank">
              Help Center <i className="fa-solid fa-arrow-right"/>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpCenter;
