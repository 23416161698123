import React, { useEffect, useState } from "react";
import FloatingLabelTextInput from "../../../common-components/floatingLabelTextInput";
import { getFocuses } from "../../../actions/common/commonActionCreator";
import { connect } from "react-redux";
import { ICommon } from "../../../models/registeration";
import { validateFields } from "../../../helper/joi-validation";
import { useNavigate } from "react-router-dom";
import Joi from "joi";
import {
  IFinalDataForOthers,
  IFocusForOthersDisplay,
} from "../../../models/barcodeRegister";

const FocusForOthers = (props: IFocusForOthersDisplay) => {
  const navigate = useNavigate();
  const { getFocuses, focuses, step3data, changeStep, currentStep } = props;
  const [errors, setErrors] = useState<any>(undefined);
  const [items, setItems] = useState<any>([]);
  const [data, setData] = useState(step3data);
  let barcodeData: any = localStorage.getItem("barcode_data");
  const barcodeDataParsed: any = JSON.parse(barcodeData);
  const [numbers, setNumbers] = useState([]);
  const [hideWelcome, setHideWelcome] = useState(false);
  const [specifyOtherField, setSpecifyOtherField] = useState(false);

  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    errors && delete errors[event.target.name];
    const _data = {
      ...data,
      [event.target.name]: event.target.value,
    };
    // data.focusOn = data.focusOn.push();
    setData(_data);
  };

  const schema = Joi.object().keys({
    gender: Joi.allow(),
    dob: Joi.allow(),
    weight: Joi.allow(),
    focusOn: Joi.array().min(1).required().label("Select atleast one option"),
    preCondition: Joi.allow(),
    focusOnName: Joi.allow(),
    height: Joi.allow(),
  });

  const addFoccus = (id: any) => {
    if (id !== 7) {
      if (items.includes(id)) {
        setItems(items.filter((it: number) => it !== 7 && it !== id));
      } else {
        setItems([...items.filter((it: number) => it != 7), id]);
      }
      if (id == 6) {
        if (items.includes(6)) {
          setSpecifyOtherField(false);
          const _data = {
            ...data,
            focusOnName: "",
          };
          setData(_data);
        } else {
          setSpecifyOtherField(true);
        }
      } else {
        !items.includes(6) && setSpecifyOtherField(false);
      }
    } else {
      setItems([7]);
      setSpecifyOtherField(false);
      const _data = {
        ...data,
        focusOnName: "",
      };
      setData(_data);
    }
  };

  const saveRequest = async () => {
    var savedData: IFinalDataForOthers = {
      ...data,
      userId: barcodeDataParsed && barcodeDataParsed.userId,
      barcode: barcodeDataParsed && barcodeDataParsed.barcode,
      fullName: barcodeDataParsed && barcodeDataParsed.personName,
      focusOn: data.focusOn,
      focusOnName: data.focusOnName,
    };
    localStorage.setItem("savedDataForOthers", JSON.stringify(savedData));

    await changeStep(currentStep + 1);
  };

  const onSubmit = async () => {
    data.focusOn = items;
    setData({ ...data, focusOn: [...items] });
    const validationErrors = validateFields(data, schema);
    if (validationErrors == undefined) {
      saveRequest();
    } else {
      setErrors(validationErrors);
    }
  };
  useEffect(() => {
    getFocuses();
  }, []);

  return (
    <>
      <div className="login-card">
        <div className="title">
          <h3>Tell Us More</h3>
          <p>
            Which of the following would you like to focus on for{" "}
            <strong>
              {barcodeDataParsed && barcodeDataParsed.personName
                ? barcodeDataParsed.personName
                : null}
            </strong>
          </p>
        </div>
        <div className="row">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            {focuses?.map((element: ICommon, index: number) => {
              return (
                <>
                  <div className="checkbox-round">
                    <input
                      type="checkbox"
                      // id="multi-check1"
                      name="id"
                      checked={items && items.includes(element.id)}
                      // onChange={() => onChange}

                      onClick={(e) => addFoccus(element.id)}
                    />
                    <label htmlFor="multi-check1">{element.name}</label>
                  </div>
                  {element.id && element.id === 6
                    ? specifyOtherField && (
                        // <div id="others-textbox" style={{ display: "none" }}>
                        <div className="w-100">
                          <div className="form-group pad-2">
                            <FloatingLabelTextInput
                              label={"Specify other"}
                              onChange={onChange}
                              // onChange={handleRadioButtonChange}
                              isMandatory={false}
                              value={data.focusOnName}
                              name={"focusOnName"}
                            />
                            {errors && errors.focusOnName && (
                              <div className="errors">{errors.focusOnName}</div>
                            )}
                          </div>
                        </div>
                      ) // </div>
                    : null}
                </>
              );
            })}
          </div>
          {errors && errors.focusOn && (
            <div className="errors">{errors.focusOn}</div>
          )}

          <div className="form-group mt-4 mb-3">
            <button onClick={() => onSubmit()}>Next</button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  //   createLogin: (data: ILogin, callback: Function) =>
  //     createLogin(data, callback),
  getFocuses: () => getFocuses(),
};

function mapStateToProps(state: any) {
  return {
    focuses: state.commonDropdowns?.focuses,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FocusForOthers);
