import { createSlice } from "@reduxjs/toolkit";
import { ISurveyListSlice } from "../../../models/admin/surveyList";

const initialSurveyListState: ISurveyListSlice = {
  surveyListData: undefined,
  surveyListExportData: undefined,
  focusFilter: undefined,
  medicalConditionFilter: undefined,
  fullProfileAdminData: undefined,
};

const surveyListSlice = createSlice({
  name: "adminSurveyList",
  initialState: initialSurveyListState,
  reducers: {
    setSurveyListData: (state, action: { payload: any }) => {
      state.surveyListData = action.payload;
    },
    setSurveyListExportData: (state, action: { payload: any }) => {
      state.surveyListExportData = action.payload;
    },
    setFocusFilter: (state, action: { payload: any }) => {
      state.focusFilter = action.payload;
    },
    setMedicalConditionFilter: (state, action: { payload: any }) => {
      state.medicalConditionFilter = action.payload;
    },
    setFullProfileAdminData: (state, action: { payload: any }) => {
      state.fullProfileAdminData = action.payload;
    },
  },
});

export const {
  setSurveyListData,
  setSurveyListExportData,
  setFocusFilter,
  setMedicalConditionFilter,
  setFullProfileAdminData,
} = surveyListSlice.actions;

const surveyListReducer = surveyListSlice.reducer;

export type surveyListState = ReturnType<typeof surveyListReducer>;

export default surveyListReducer;
