import { IApiResponse } from "../../models/IAPIResponse";
import APIService from "../../helper/api-service";
import { login, register, resetPassword } from "../../constants/apiUrl";
import { ILogin } from "../../models/login";
import {
  IFinalData,
  IHubsportData,
  IHubspotCreateData,
  IRegisterMailData,
  IStep2,
  IVerifiedEmail,
} from "../../models/registeration";
import { IResetPassword } from "../../models/admin/userList";
import { urlBinder } from "../../helper/url-binder";
import { alertConstants } from "../../helper/sweet-alert";

export default new (class LoginService {
  public createLogin = async (
    data: ILogin,
    callback: Function
  ): Promise<any> => {
    try {
      let endPoint = login.CREATE_LOGIN;
      const response = await APIService.post(endPoint, data);
      if (response !== undefined || null) {
        return response && response.data && (response.data as any);
      } else {
        return "needToHandleError";
      }
    } catch (error: any) {
      if (error.response) {
        return error as any;
      } else {
        throw error;
      }
    }
  };

  public resetPassword = async (
    email: string,
    callback: Function
  ): Promise<any> => {
    try {
      let endPoint = urlBinder(login.RESET_PASSWORD, [email]);
      const response = await APIService.post(endPoint);
      // if(response?.data?.data===""){
      //   return false;
      // }else if(response?.data!==""&&response?.status===200){
      //   return true;
      // }else{
      //   return "needToBeHandled"
      // }
      if(response?.data?.success==true){
        return response?.data?.success
      }else{
        return false;
      }
      
      // if (response === undefined) {
      //   return "needToHandle";
      // } else {
      //   return response?.data as any;
      // }
    } catch (error: any) {
      if (error.response) {
        return error.response as any;
      } else {
        throw error;
      }
    }
  };

  public confirmPassword = async (
    email: string,
    password: string,
    forgotpasswordtoken: string | (string | null)[] | null,
    callback: Function
  ): Promise<boolean> => {
    debugger;
    try {
      let data = {
        email,
        password,
        forgotpasswordtoken,
            }
      let endPoint = login.NEW_PASSWORD;      
      const response = await APIService.post(endPoint, data); 
      return response?.data as boolean;
    } catch (error: any) {
      if (error.response) {
        return error.response as boolean;
      } else {
        throw error;
      }
    }
  };

  public register = async (
    data: IFinalData,
    callback: Function
  ): Promise<any> => {
    try {
      let endPoint = register.POST_REGISTER;
      const response = await APIService.post(endPoint, data);
      if (response !== undefined || null) {
        return response.data as boolean;
      } else {
        return "needToHandleError";
      }
    } catch (error: any) {
      if (error.response) {
        return error.response as boolean;
      } else {
        throw error;
      }
    }
  };

  public hubspotDataCreation = async (
    email: boolean,
    sms: boolean,
    data: IHubspotCreateData,
    callback: Function
  ): Promise<any> => {
    try {
      const endpointTemplate = register.POST_HUBSPOT_CREATE_DATA;
      const endPoint = endpointTemplate
        .replace("{email}", email.toString())
        .replace("{sms}", sms.toString());
      const response = await APIService.post(endPoint, data);
      if (response?.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error: any) {
      if (error.response) {
        return error.response as boolean;
      } else {
        throw error;
      }
    }
  };

  public resendOTP = async (
    email: string,
    data: IHubspotCreateData,
    callback: Function
  ): Promise<any> => {
    try {
      const endpointTemplate = register.POST_RESEND_OTP;
      const endPoint = endpointTemplate.replace("{email}", email.toString());
      const response = await APIService.post(endPoint, data);
      if (response?.status === 200) {
        return response?.data as any;
      } else {
        return false;
      }
    } catch (error: any) {
      if (error.response) {
        return error.response as boolean;
      } else {
        throw error;
      }
    }
  };

  // public registerEmail = async (email: string): Promise<string> => {
  //   try {
  //     let endPoint = register.POST_REGISTER_MAIL,
  //       email;
  //     const response = await APIService.post(endPoint);
  //     return response.message as string;
  //   } catch (error: any) {
  //     if (error.response) {
  //       return error.response as string;
  //     } else {
  //       throw error;
  //     }
  //   }
  // };

  public getVerifiedEmail = async (email: string): Promise<any> => {
    try {
      let endPoint = urlBinder(register.GET_VERIFY_EMAIL, [`${email}`]);
      const response = await APIService.get(endPoint);
      if (response?.data?.isMailidAlreadyExist === true) {
        return response;
      } else {
        return response as IApiResponse<Array<IVerifiedEmail>>;
      }
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<Array<IVerifiedEmail>>;
      } else {
        throw error;
      }
    }
  };

  public registerEmail = async (email: string): Promise<any> => {
    try {
      let endPoint = urlBinder(register.POST_REGISTER_MAIL, [email]);
      const response = await APIService.post(endPoint);
      return response.data as any;
    } catch (error: any) {
      if (error.response) {
        return error.response as any;
      } else {
        throw error;
      }
    }
  };

  public verifyOTP = async (data: IStep2, callback: Function): Promise<any> => {
    try {
      let endPoint = register.POST_VERIFY_OTP;
      const response = await APIService.post(endPoint, data);
      //also checking hubspot data
      if (response?.status === 200) {
        return response.data as boolean;
      } else if (response?.data?.message === true) {
        return false;
      } else {
      }
    } catch (error: any) {
      if (error.response) {
        return error.response as any;
      } else {
        throw error;
      }
    }
  };
})();
