import testResultService from "../apiServices/testResult/testResultService";
import { alertConstants } from "../helper/sweet-alert";
import { ITestResultDataWithMessage } from "../models/testResult";
import { setTestResultData } from "../store/slices/dashboard/testResult";

export function getTestResults(pageNumber: number, pageSize: number) {
  return async function (dispatch: any, getState: any) {
    let response = await testResultService.getTestResults(pageNumber, pageSize);
    if (response?.status === 200) {
      let _data: ITestResultDataWithMessage = {
        testResultData: response?.data,
        message: response?.message,
        noDealData: "DealIds is null",
      };
      dispatch(setTestResultData(_data));
    } else {
    }
  };
}
