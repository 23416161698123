import { createSlice } from "@reduxjs/toolkit";
import { ISupplementSlice } from "../../../models/supplements";

const initialSupplementState: ISupplementSlice = {
  supplementsData: undefined,
};

const supplementSlice = createSlice({
  name: "supplements",
  initialState: initialSupplementState,
  reducers: {
    setSupplementsData: (state, action: { payload: any }) => {
      state.supplementsData = action.payload;
    },
  },
});

export const { setSupplementsData } = supplementSlice.actions;

const supplementReducer = supplementSlice.reducer;

export type supplementState = ReturnType<typeof supplementReducer>;

export default supplementReducer;
