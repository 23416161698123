
import { loginRequest } from "../auth-config";
import { msalInstance } from "../index";

export async function authToken() {
    return new Promise<any>(async (resolve, reject) => {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
    
        await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        }).then((response: any) => {
            resolve(response);
        }).catch(() => {
            reject();
        });

    });
}