import { createSlice } from "@reduxjs/toolkit";
import { ISurveyListSlice } from "../../../models/admin/surveyList";
import { IBarcodeListingSlice } from "../../../models/admin/barcodeListing.ts";

const initialBarcodeListingState: IBarcodeListingSlice = {
  barcodeListingData: undefined,
};

const barcodeListingSlice = createSlice({
  name: "adminBarcodeList",
  initialState: initialBarcodeListingState,
  reducers: {
    setBarcodeListingData: (state, action: { payload: any }) => {
      state.barcodeListingData = action.payload;
    },
  },
});

export const { setBarcodeListingData } = barcodeListingSlice.actions;

const barcodeListingReducer = barcodeListingSlice.reducer;

export type barcodeListingState = ReturnType<typeof barcodeListingReducer>;

export default barcodeListingReducer;
