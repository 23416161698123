import React from "react";
import { useNavigate } from "react-router-dom";
interface IHeader {
  currentStep: number;
  changeStep: any;
}
const Step1Header = (props: IHeader) => {
  const navigate = useNavigate();
  const { currentStep, changeStep } = props;

  return (
    <>
      <div className="login-pagination">
        <ul>
          <li className="active">
            <a>step 1</a>
          </li>
          <li>
            <a>step 2</a>
          </li>
          <li>
            <a>step 3</a>
          </li>
          <li>
            <a>step 4</a>
          </li>
          <li>
            <a>step 5</a>
          </li>
          <li>
            <a>step 6</a>
          </li>
        </ul>
        <p className="text-center">
          Step <span className="fw-bold">{currentStep} of 6</span>
        </p>
      </div>
    </>
  );
};

export default Step1Header;
