import testPurchaseService from "../apiServices/admin/testPurchaseService";
import testResultService from "../apiServices/testResult/testResultService";
import { ITableData } from "../models/ICommon";
import { ITestPurchase } from "../models/admin/testPurchased";
import {
  setTestPurchasedData,
  setTestPurchasedExportData,
  setTestPurchasedFilter,
  setTestPurchasedStatusFilter,
} from "../store/slices/admin/testPurchase";

export function getTestPurchased(
  pageNumber: number,
  pageSize: number,
  keyword?: string,
  fromDate?: string,
  toDate?: string
) {
  return async function (dispatch: any, getState: any) {
    let response = await testPurchaseService.getTestPurchases(
      pageNumber,
      pageSize,
      keyword,
      fromDate,
      toDate
    );
    if (response?.success === true) {
      dispatch(setTestPurchasedData(response.data));
    } else {
    }
  };
}

export function getTestPurchasedOfIndividual(id: number) {
  return async function (dispatch: any, getState: any) {
    let dummyData = [
      {
        id: 1,
        userId: 10029,
        purchasedBy: "Theresa Webb",
        testName: "Essential",
        purcahsedOn: "10/6/2013",
        status: "Sample Received",
        fileUrl: "string",
      },
    ];
    // let response = await testResultService.getTestResults(
    //   pageNumber,
    //   pageSize,
    //   keyword
    // );
    // if (response?.success === true) {
    if (dummyData) {
      // dispatch(setTestResultData(response.data));
      //dispatch(setTestPurchasedData(dummyData));
    } else {
    }
  };
}
function downloadFile(response: any, name: string) {
  var blob = new Blob([response]);

  let a = document.createElement("a");

  var url = window.URL.createObjectURL(blob);

  a.href = url;

  a.download = `${name} ${new Date()}.xlsx`;

  a.click();

  window.URL.revokeObjectURL(url);

  return true;
}

export function getTestPurchasedExport(
  pageNumber: number,
  pageSize: number,
  keyword?: string,
  fromDate?: string,
  toDate?: string) {
  return async function (dispatch: any, getState: any) {
    let response = await testPurchaseService.getTestPurchaseExport(
      pageNumber,
      pageSize,
      keyword,
      fromDate, 
      toDate
    );
    downloadFile(response, "file");
  };
}

export function getTestPurchasedKeyValuePair() {
  return async function (dispatch: any, getState: any) {
    // let response = await testPurchaseService.getTestPurchasedKeyValuePairs();
    let dummyData = [
      {
        id: 2,
        name: "Advanced",
      },
      {
        id: 3,
        name: "Elite",
      },
      {
        id: 1,
        name: "Essentials",
      },
    ]; // if (response?.success === true) {
    if (dummyData) {
      // dispatch(setTestPurchasedFilter(response.data));
      dispatch(setTestPurchasedFilter(dummyData));
    } else {
    }
  };
}

export function getStatusKeyValuPair() {
  return async function (dispatch: any, getState: any) {
    // let response = await testPurchaseService.getStatusKeyValuePairs();
    let dummyData = [
      {
        id: 1,
        name: "Sample Received",
      },
      {
        id: 2,
        name: "Order Received",
      },
      {
        id: 3,
        name: "Order Shipped",
      },
      {
        id: 3,
        name: "Results Ready",
      },
      {
        id: 3,
        name: "Sample in Process",
      },
    ];
    // if (response?.success === true) {
    if (dummyData) {
      // dispatch(setTestPurchasedStatusFilter(response.data));
      dispatch(setTestPurchasedStatusFilter(dummyData));
    } else {
    }
  };
}
