import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Joi from "joi";
import FloatingLabelTextInput from "../../../common-components/floatingLabelTextInput";
import { IFinalData, IStep6Display } from "../../../models/registeration";
import { getGender } from "../../../actions/common/commonActionCreator";
import FloatingLabelDropDown from "../../../common-components/floatingLabelDropdown";
import { register } from "../../../actions/loginActionCreator";
import { validateFields } from "../../../helper/joi-validation";
import ButtonSpinner from "../../../common-components/buttonSpinner";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { ROUTER_URL } from "../../../constants/routerUrl";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { alertConstants } from "../../../helper/sweet-alert";
const Step6 = (props: IStep6Display) => {
  const navigate = useNavigate();
  const {
    passData,
    getGender,
    gender,
    register,
    currentStep,
    changeStep,
    metaDataSaved,
    step5data,
    setMainData,
  } = props;
  const [isLoading, showLoading] = useState(false);
  const [errors, setErrors] = useState<any>(undefined);
  const [data, setData] = useState({
    ...step5data,
    feet: -1, 
    inch: -1, 
  }); 
  const [disableButton, setDisableButton] = useState(false);

  const schema = Joi.object().keys({
    firstName: Joi.string()
      .regex(/^[A-Za-z'\- ]+$/)
      .required()
      .label("Enter valid name"),
    isAgreeTerms: Joi.allow(),
    lastName: Joi.string()
      .regex(/^[A-Za-z'\- ]+$/)
      .required()
      .label("Enter valid name"),
    password: Joi.string()
      .min(8) // Minimum length of 8 characters
      .pattern(/^(?=.*[A-Z])[A-Za-z\d~!@#$%^&*()_\-+=\\|}\][{';":\/?.><`,]+$/)
      .label(
        "Password must contain minimum 8 characters and must contain atleast an uppercase letter"
      ),
    email: Joi.string().required().label("Enter valid email"),
    mobileNo: Joi.string()
      .length(10)
      .pattern(/^[0-9]+$/)
      .required()
      .label("Enter valid mobile number"),
    gender: Joi.number().min(1).required().label("Enter valid gender"),
    dob: Joi.allow(),
    // dob: Joi.date()
    //   .max(moment().toDate().toString())
    //   .required()
    //   .label("Enter valid dob"),
    weight: Joi.number().min(1).required().label("Enter valid weight"),
    focusOn: Joi.array().min(1).required().label("Select atleast one option"),
    preCondition: Joi.array()
      .min(1)
      .required()
      .label("Select atleast one option"),
    focusOnName: Joi.allow(),
    feet: Joi.number().min(1).max(9).required().label("Enter valid feet"),
    inch: Joi.number().min(0).max(12).required().label("Enter valid inch"),
    height: Joi.allow(),
  });
  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    errors && delete errors[event.target.name];
    const _data = {
      ...data,
      [event.target.name]: event.target.value,

      // dOB: moment(data.dOB).format("DD-MM-YYYY").toString(),
    };
    setData(_data);
  };

  const onChangeDate = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    errors && delete errors[event.target.name];
    const _data = {
      ...data,
      [event.target.name]: event.target.value,
      dob: moment(data.dob).format("MM-DD-YYYY"),

      // dob: moment(event.target.value).format("MM-DD-YYYY"),

      // dOB: moment(data.dOB).format("DD-MM-YYYY").toString(),
    };
    setData(_data);
  };

  useEffect(() => {
    getGender();
    setData({
      ...data,
      dob: new Date(),
    });
  }, []);

  const dateChange = () => {};

  const feetValues = Array.from({ length: 8 }, (_, index) => index + 1);
  const inchValues = Array.from({ length: 12 }, (_, index) => index);

  const saveRequest = async () => {
    var metaData: IFinalData = {
      firstName: data.firstName,
      isAgreeTerms: data.isAgreeTerms,
      lastName: data.lastName,
      password: data.password,
      email: data.email,
      mobileNo: data.mobileNo,
      gender: parseInt(data.gender),
      dob: moment(data.dob).format("YYYY-MM-DD").toString(),
      // dOB: moment(data.dOB).format("DD-MM-YYYY").toString(),
      weight: parseInt(data.weight),
      focusOn: data.focusOn,
      preCondition: data.preCondition,
      focusOnName: data.focusOnName,
      feet: 0,
      inch: 0,
      height:
        data.feet.toString() + " Feet " + data.inch.toString() + " Inches ",
    };
    await localStorage.clear();
    await register(metaData, callback);
  };

  const callback = (response: any) => {
    if (response) {
      setDisableButton(true);
      UserSuccessfullyRegistered();
      // navigate(ROUTER_URL.LOGIN);
      // changeStep(props.currentStep + 1);

      // if (isContinue) {
      //   isContinue = false;
      //   props.changeStep(props.currentStep + 1);
      // } else {
      //   setMessage(language.profileUpdatedSuccessfully);
      //   setSuccessCode(1);
      // }
    } else if (response === false) {
      setDisableButton(false);
      showToastMessage();
      // changeStep(props.currentStep + 1);
    } else {
      setDisableButton(false);
      somethingWentWrong();
    }
  };

  const onSubmit = async () => {
    setDisableButton(true);
    const validationErrors = validateFields(data, schema);
    if (validationErrors == undefined) {
      saveRequest();
    } else {
      setErrors(validationErrors);
      setDisableButton(false);
    }
  };

  // const dobChange = (event: any) => {
  //   setData({
  //     ...data,
  //     dOB: new Date(moment(event).format("YYYY-MM-DD")),
  //   });
  // };

  const showToastMessage = () => {
    toast.error("Error in Registering!!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const UserSuccessfullyRegistered = () => {
    toast.success("User Successfully Registered!!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        navigate(ROUTER_URL.LOGIN);
      },
    });
  };

  const somethingWentWrong = () => {
    toast.error("Something Went Wrong !!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        setDisableButton(false);
      },
    });
  };
  const dobChange = (event: any) => {
    // setData({
    //   ...data,
    //   dob: new Date(moment(event).format("MM-DD-YYYY")),
    // });

    if (event) {
      setData({
        ...data,
        dob: event,
      });
    }
    //validateFields();
  };

  return (
    <>
      <div className="login-card">
        <div className="title">
          <h3>Your body composition</h3>
          <p>Update your body compositions to help us understand more.</p>
        </div>
        <div className="row">
          <div className="col-md-12 mb-2">
            <h4 className="text-center mb-3">How tall are you?</h4>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group pad-2">
                  {/* <FloatingLabelTextInput
                    type="number"
                    label={"Feet"}
                    onChange={onChange}
                    isMandatory={true}
                    value={data.feet}
                    name={"feet"}
                    placeholder={"Feet"}
                    minValue={1}
                    maxLength={9}
                  /> */}
                  <select
                    value={data.feet}
                    placeholder={"Feet"}
                    onChange={onChange}
                    name={"feet"}
                  >
                    <option value={0}> </option>
                    {feetValues.map((number) => (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="feet">Feet</label>
                  {errors && errors.feet && (
                    <div className="errors">{errors.feet}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group pad-2">
                  {/* <FloatingLabelTextInput
                    type="number"
                    label={"Inch"}
                    onChange={onChange}
                    isMandatory={true}
                    value={data.inch}
                    name={"inch"}
                    placeholder={"Inch"}
                    minValue={1}
                    maxValue={9}
                  /> */}
                  <select
                    value={data.inch}
                    placeholder={"Inch"}
                    onChange={onChange}
                    name={"inch"}
                  >
                   <option value={-1}></option>
                    {inchValues.map((number) => (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="inch">Inch</label>

                  {errors && errors.inch && (
                    <div className="errors">{errors.inch}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mb-2">
            <h4 className="text-center mb-3">What is your current weight?</h4>
            <div className="form-group pad-right">
              <FloatingLabelTextInput
                type="number"
                label={"Weight"}
                onChange={onChange}
                isMandatory={true}
                value={data.weight}
                name={"weight"}
                placeholder={"Weight"}
                minValue={1}
                maxValue={9}
              />
              {errors && errors.weight && (
                <div className="errors">{errors.weight}</div>
              )}

              <span className="highlights">lbs</span>
            </div>
          </div>

          <div className="col-md-12 mb-2">
            <h4 className="text-center mb-3">Few more details</h4>
            <div className="form-group pad-right">
              <DatePicker
                dateFormat={"MM-dd-yyyy"}
                placeholderText="mm-dd-yyyy"
                // selected={new Date(data.dob)}
                selected={data?.dob ? new Date(data?.dob) : new Date()}
                className="date"
                name="dob"
                onChange={(date) => dobChange(date)}
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                dropdownMode="select"
                // autoComplete="off"
                maxDate={moment().toDate()}
              ></DatePicker>

              {errors && errors.dob && (
                <div className="errors">{errors.dob}</div>
              )}
              {/* <input className="date" type="date" placeholder="dob" id="dob" />
              <label htmlFor="dob">Date of Birth</label> */}
              <label htmlFor="dob" className="active">
                Date of Birth
              </label>
            </div>
            <div className="form-group pad-right">
              <FloatingLabelDropDown
                label={"Gender"}
                onChange={onChange}
                isMandatory={false}
                value={data.gender}
                name={"gender"}
                optionsList={gender}
                // placeholder="Purchased"
                // id="Purchased"
              />
              {errors && errors.gender && (
                <div className="errors">{errors.gender}</div>
              )}
              {/* <label htmlFor="gender">Gender</label> */}
            </div>
          </div>
          {disableButton === true ? (
            <div className="form-group mt-4 mb-3">
              <button onClick={() => onSubmit()} disabled>
                <ButtonSpinner isLoading={isLoading} />
                Finish
              </button>
            </div>
          ) : (
            <div className="form-group mt-4 mb-3">
              <button onClick={() => onSubmit()}>
                <ButtonSpinner isLoading={isLoading} />
                Finish
              </button>
            </div>
          )}
          {/* <div className="form-group mt-4 mb-3">
            <button onClick={() => onSubmit()}>
              <ButtonSpinner isLoading={isLoading} />
              Finish
            </button>
          </div> */}
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

const mapDispatchToProps = {
  getGender: () => getGender(),
  register: (data: IFinalData, callback: Function) => register(data, callback),
};

function mapStateToProps(state: any) {
  return {
    gender: state.commonDropdowns?.gender,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Step6);
