import { useEffect, useState } from "react";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import {
  InteractionStatus,
  InteractionType,
  InteractionRequiredAuthError,
  AccountInfo,
} from "@azure/msal-browser";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "../../auth-config";
import { authToken } from "../../utils/auth-token";

const ProfileContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instance, inProgress } = useMsal();
  const [graphData, setGraphData] = useState<null | any>(null);

  useEffect(() => {
    if (!graphData && inProgress === InteractionStatus.None) {
      authToken()
        .then((response: any) => {
          setGraphData(response);
          console.log(response);

          const jwtObj: any = parseJwt(response?.accessToken);
          localStorage.setItem("loggedUser", response?.accessToken);
          console.log(jwtObj);

          // UserService.getUser().then((data: any) => {
          //     console.log(data);

          //   if(data?.data) {
          //     // dispatch(
          //     //     userDetails({
          //     //         email: jwtObj['unique_name'],
          //     //         firstName: jwtObj["given_name"],
          //     //         lastName: jwtObj["family_name"],
          //     //         avtarURL: jwtObj["avatar-url"],
          //     //         username: jwtObj.unique_name,
          //     //         userId: data?.data?._id?.$oid,
          //     //     })
          //     // );
          //     // navigate("/chat");
          //   } else {
          //     // ToastMessage.error(data?.message);
          //     // const interval: any = setInterval(() => {
          //     //     dispatch(userDetails({
          //     //         email: undefined,
          //     //         firstName: undefined,
          //     //         lastName: undefined,
          //     //         avtarURL: undefined,
          //     //         username: undefined,
          //     //         userId: undefined,
          //     //     }));
          //     //     instance.logoutRedirect({
          //     //         onRedirectNavigate: (url) => {
          //     //             return true;
          //     //         }
          //     //     });
          //     //     navigate("/login");
          //     //     clearInterval(interval);
          //     // }, 5000)
          //     return;
          //   }
          // });
        })
        .catch((e: any) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount() as AccountInfo,
            });
          }
        });
    }
  }, [inProgress, graphData, instance]);

  const parseJwt = (token: any) => {
    if (token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    }
  };

  return <></>;
};

export function AuthCallback() {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      // errorComponent={ErrorComponent}
      // loadingComponent={Loading}
    >
      <ProfileContent />
    </MsalAuthenticationTemplate>
  );
}
