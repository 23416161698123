import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Joi from "joi";
import FloatingLabelTextInput from "../../../common-components/floatingLabelTextInput";
import {
  ICommon,
  IFinalData,
  IStep5Display,
} from "../../../models/registeration";
import { getMedicalConditions } from "../../../actions/common/commonActionCreator";
import { validateFields } from "../../../helper/joi-validation";

const Step5 = (props: IStep5Display) => {
  const navigate = useNavigate();
  const {
    getMedicalConditions,
    medicalConditions,
    passData,
    currentStep,
    changeStep,
    changeData,
    metaDataSaved,
    step4data,
    setMainData,
  } = props;

  const [errors, setErrors] = useState<any>(undefined);
  const [items, setItems] = useState<any>([]);
  const [data, setData] = useState(step4data);

  // const [data, setData] = useState<IFinalData>({
  //   id: 0,
  //   firstName: metaDataSaved.firstName,
  //   isAgreeTerms: metaDataSaved.isAgreeTerms,
  //   lastName: metaDataSaved.lastName,
  //   password: metaDataSaved.password,
  //   email: metaDataSaved.email,
  //   mobileNo: metaDataSaved.mobileNo,
  //   gender: 0,
  //   dOB: "",
  //   weight: 0,
  //   focusOn: metaDataSaved.focusOn,
  //   preCondition: [],
  //   focusOnName: metaDataSaved.focusOnName,
  //   feet: 0,
  //   inch: 0,
  // });
  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    errors && delete errors[event.target.name];
    const _data = {
      ...data,
      [event.target.name]: event.target.value,
    };
    setData(_data);
  };

  useEffect(() => {
    getMedicalConditions();
  }, []);

  const saveRequest = async () => {
    // var metaData: IFinalData = {
    //   firstName: data.firstName,
    //   isAgreeTerms: data.isAgreeTerms,
    //   lastName: data.lastName,
    //   password: data.password,
    //   email: data.email,
    //   mobileNo: data.mobileNo,
    //   gender: data.gender,
    //   dOB: data.dOB,
    //   weight: data.weight,
    //   focusOn: data.focusOn,
    //   preCondition: data.preCondition,
    //   focusOnName: data.focusOnName,
    //   feet: data.feet,
    //   inch: data.inch,
    // };
    // let metaDataSaved = metaData;
    let step5Out = data;
    setMainData({
      ...passData,
      preCondition: data.preCondition,
    });
    props.sendDataToParent(step5Out);

    // changeData(metaDataSaved);
    await changeStep(currentStep + 1);
  };

  const schema = Joi.object().keys({
    // firstName: Joi.allow(),
    firstName: Joi.string()
      .regex(/^[A-Za-z'\- ]+$/)
      .required()
      .label("Enter valid name"),
    isAgreeTerms: Joi.allow(),
    lastName: Joi.string()
      .regex(/^[A-Za-z'\- ]+$/)
      .required()
      .label("Enter valid name"),
    // password: Joi.string()
    //   .regex(/^[a-z0-9]+$/)
    //   .required()
    //   .label("Enter valid password"),
    password: Joi.string()
      .min(8) // Minimum length of 8 characters
      .pattern(/^(?=.*[A-Z])[A-Za-z\d~!@#$%^&*()_\-+=\\|}\][{';":\/?.><`,]+$/)
      .label(
        "Password must contain minimum 8 characters and must contain atleast an uppercase letter"
      ),
    email: Joi.string().required().label("Enter valid email"),
    mobileNo: Joi.string()
      .length(10)
      .pattern(/^[0-9]+$/)
      .required()
      .label("Enter valid mobile number"),
    gender: Joi.allow(),
    dob: Joi.allow(),
    weight: Joi.allow(),
    focusOn: Joi.array().min(1).required().label("Select atleast one option"),
    preCondition: Joi.array()
      .min(1)
      .required()
      .label("Select atleast one option"),
    focusOnName: Joi.allow(),
    feet: Joi.allow(),
    inch: Joi.allow(),
    height: Joi.allow(),
  });

  const onSubmit = async () => {
    data.preCondition = items;
    setData({ ...data, preCondition: [...items] });
    const validationErrors = validateFields(data, schema);
    if (validationErrors == undefined) {
      saveRequest();
    } else {
      setErrors(validationErrors);
    }
  };

  const addMedicalConditions = (id: any) => {
    if (id !== 5) {
      if (items.includes(id)) {
        setItems(items.filter((it: number) => it !== 5 && it !== id));
      } else {
        setItems([...items.filter((it: number) => it != 5), id]);
      }
    } else {
      setItems([5]);
    }
  };

  const addFoccus = (id: any) => {
    if (id !== 5) {
      if (items.includes(id)) {
        setItems(items.filter((it: number) => it !== 5 && it !== id));
      } else {
        setItems([...items.filter((it: number) => it != 5), id]);
      }
    } else {
      setItems([5]);
    }
  };

  useEffect(() => {
    setData({
      ...passData,
      dob: new Date(),
    });
  }, []);

  return (
    <>
      <div className="login-card">
        <div className="title">
          <h3>One more question</h3>
          <p>
            Do you have any of the following conditions? This helps our doctors
            recommend the right tests for you.
          </p>
        </div>
        <div className="row">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            {medicalConditions?.map((element: ICommon, index: number) => {
              return (
                <>
                  <div className="checkbox-round">
                    <input
                      type="checkbox"
                      // id="multi-check1"
                      name="id"
                      checked={items && items.includes(element.id)}
                      onClick={(e) => addMedicalConditions(element.id)}
                    />
                    <label htmlFor="multi-check1">{element.name}</label>
                  </div>
                </>
              );
            })}
          </div>
          {errors && errors.preCondition && (
            <div className="errors">{errors.preCondition}</div>
          )}

          <div className="form-group mt-4 mb-3">
            <button onClick={() => onSubmit()}>Next</button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  getMedicalConditions: () => getMedicalConditions(),
};

function mapStateToProps(state: any) {
  return {
    medicalConditions: state.commonDropdowns?.medicalConditions,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Step5);
