import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IBarcodeDataToState,
  IBarcodeRegister,
  IFinalDataForOthers,
  IKitRegisterForSelf,
  IKitRegisterForSelfDisplay,
  IKitRegisterOtherDetails,
  IKitRegisterOtherName,
  ISelfName,
} from "../../../../../models/barcodeRegister";
import { event } from "jquery";
import { connect } from "react-redux";
import Joi from "joi";
import { validateFields } from "../../../../../helper/joi-validation";
import { alertConstants } from "../../../../../helper/sweet-alert";
import { ROUTER_URL } from "../../../../../constants/routerUrl";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import {
  getOthers,
  registerForOthers,
  registerKitForSelf,
} from "../../../../../actions/registerKitActionCreator";
import FloatingLabelTextInput from "../../../../../common-components/floatingLabelTextInput";

const KitRegisterForSelf = (props: IKitRegisterForSelfDisplay) => {
  const {
    registerKitForSelf,
    fullProfileData,
    getOthers,
    others,
    registerForOthers,
  } = props;
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>(undefined);
  const [isVisible, setIsVisible] = useState(false);
  const [isOtherNameVisible, setIsOtherNameVisible] = useState("");
  const [nameFieldVisible, SetnameFieldVisible] = useState(false);
  const [sendRequest, setSendRequest] = useState(false);
  const [data, setData] = useState<IKitRegisterForSelf>({
    isSelfregistered: false,
  });
  const [other, setOther] = useState<IKitRegisterOtherDetails>({
    otherId: 0,
    name: "",
  });

  const [otherName, setOtherName] = useState<IKitRegisterOtherName>({
    name: "",
  });

  const [self, setSelf] = useState<ISelfName>({
    selfName: null,
  });

  const [alreadyRegistered, setAlreadyRegistered] = useState<ISelfName>({
    selfName: null,
  });

  let barcodeData: any = localStorage.getItem("barcode_data");
  const barcodeDataParsed: any = JSON.parse(barcodeData);
  const [newBarcodeData, setNewBarcodeData] = useState<IBarcodeDataToState>({
    userId:
      barcodeDataParsed && barcodeDataParsed.userId
        ? barcodeDataParsed.userId
        : 0,
    barcode:
      barcodeDataParsed && barcodeDataParsed.barcode
        ? barcodeDataParsed.barcode
        : "",
    personName: "",
  });

  const schema = Joi.object().keys({
    isSelfregistered: Joi.allow(),
  });

  var question = [
    { register: "true", name: "Self" },
    { register: "false", name: "Others" },
  ];
  var alreadyRegisteredData = "";
  var stringToBool = (value: any) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };
  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    errors && delete errors[event.target.name];
    const _data = {
      isSelfregistered: event,
    };
    if (data.isSelfregistered == false) {
      const _others = {
        ...other,
        otherId: 0,
      };
      setOther(_others);
      const _otherName = {
        name: "",
      };
      setOtherName(_otherName);
      setIsVisible(true);
      setIsOtherNameVisible("newName");
    }
    setData(_data);
  };

  const onChangeOthers = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    errors && delete errors[event.target.name];
    const _others = {
      ...other,
      otherId: Number(event.target.value),
    };
    setOther(_others);

    if (other.otherId === 0) {
      SetnameFieldVisible(true);
      setIsOtherNameVisible("newName");
    } else {
      SetnameFieldVisible(false);
      const _otherName = {
        name: "",
      };
      setOtherName(_otherName);
      for (const obj of others) {
        if (obj.id === other.otherId) {
          return setAlreadyRegistered(obj.name);
        }
      }
    }
  };

  const onChangeName = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    errors && delete errors[event.target.name];
    const _otherName = {
      ...otherName,
      [event.target.name]: event.target.value,
    };
    setOtherName(_otherName);
  };

  const emptyData = {};
  const saveRequest = async () => {
    if (data.isSelfregistered === true) {
      //registering for self
      await registerKitForSelf(
        data.isSelfregistered,
        self.selfName,
        barcodeDataParsed.barcode,
        emptyData,
        callback
      );
    } else {
      //registering for others
      if (other.otherId === 0) {
        navigate(ROUTER_URL.REGISTRATION_FOR_OTHERS);
        //only when selected others from dropdown and provided name in the field
        // await registerKitForSelf(
        //   data.isSelfregistered,
        //   otherName.name,
        //   barcodeDataParsed.barcode,
        //   emptyData,
        //   callback
        // );
      } else if (other.otherId > 0) {
        //only when selected any of the name from dropdown
        // let selectedName = others.filter(
        //   (item: any) => item.id === other.otherId
        // );
        // const otherNameToRegister = findNameById(other.otherId);
        for (const obj of others) {
          if (obj.id === other.otherId) {
            // return (alreadyRegisteredData = obj.name);
            await registerKitForSelf(
              data.isSelfregistered,
              obj.name,
              barcodeDataParsed.barcode,
              emptyData,
              callback
            );
          }
        }
        // await registerKitForSelf(
        //   data.isSelfregistered,
        //   alreadyRegisteredData,
        //   barcodeDataParsed.barcode,
        //   callback
        // );
      }
    }

    // await registerForOthers();
  };

  const callback = (response: any) => {
    if (response===true && data.isSelfregistered == true) {
      successfullyRegistered();
    } else if (response === true && data.isSelfregistered === false) {
      successfullyRegistered();
    }else if(response===false){
somethingWentWrong();
    }
    else if (other.otherId == 0) {
      navigate(ROUTER_URL.REGISTRATION_FOR_OTHERS);
    } else if (other.otherId > 0) {
      navigate(ROUTER_URL.DASHBOARD);
    } else {
      somethingWentWrong();
    }
  };
  const successfullyRegistered = () => {
    toast.success("Registered Successfully!!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        navigate(ROUTER_URL.DASHBOARD);
      },
    });
    // navigate(ROUTER_URL.DASHBOARD);
  };

  const somethingWentWrong = () => {
    toast.error("Something went wrong!!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        navigate(ROUTER_URL.DASHBOARD);
      },
    });
  };
  const onSubmit = async () => {
    const validationErrors = validateFields(data, schema);
    if (validationErrors == undefined) {
      setSendRequest(true);
      saveRequest();
    } else {
      setErrors(validationErrors);
    }
  };

  var barcodeDataUpdated: IBarcodeDataToState = {
    ...newBarcodeData,
    personName: otherName.name ? otherName.name : "",
  };
  localStorage.setItem("barcode_data", JSON.stringify(barcodeDataUpdated));

  useEffect(() => {
    getOthers();
  }, []);

  return (
    <>
      <div className="login-wrap">
        <main>
          <div className="login-card">
            <div className="logo">
              <img src="assets/images/logo.webp" alt="" draggable="false" />
            </div>
            <div className="title">
              <h4 className="text-center mt-4 mb-1">
                Have you purchased this test for?
              </h4>
            </div>

            <div className="d-flex align-items-center justify-content-center gap-15">
              <div className="input-radio">
                <label className="control control-radio">
                  <input
                    type="radio"
                    value={question[0].register}
                    name="question"
                    onChange={() =>
                      onChange(stringToBool(question[0].register))
                    }
                  />
                  <div className="control_indicator">Self</div>
                </label>
              </div>
              <div className="input-radio">
                <label className="control control-radio">
                  <input
                    type="radio"
                    value={question[1].register}
                    name="question"
                    onChange={() =>
                      onChange(stringToBool(question[1].register))
                    }
                  />
                  <div className="control_indicator">For Others</div>
                </label>
              </div>
            </div>
            <div id="radio-others" className="otherCode">
              {isVisible && data.isSelfregistered === false && (
                <div className="form-group pad-right mt-4">
                  <select
                    className="floating-select"
                    value={other.otherId}
                    placeholder="personname"
                    onChange={onChangeOthers}
                    name="otherId"
                  >
                    {others &&
                      others.length > 0 &&
                      others.map((element: any, key: number) => {
                        return (
                          <option value={element.id}>{element.name}</option>
                        );
                      })}

                    <option value="0" id="custom" selected>
                      Others
                    </option>
                  </select>
                  <label htmlFor="gender">Select the person</label>
                </div>
              )}
              {isOtherNameVisible === "newName" &&
              data.isSelfregistered === false &&
              other.otherId === 0 ? (
                <div className="text-person form-group pad-2 mt-2">
                  <FloatingLabelTextInput
                    // type="Password"
                    label={"Person Name"}
                    onChange={onChangeName}
                    isMandatory={true}
                    value={otherName.name}
                    name={"name"}
                    placeholder={"Person Name"}
                  />
                  {/* <input type="text" placeholder="person" id="person" />
                  <label htmlFor="person">Person Name</label> */}
                </div>
              ) : null}
            </div>
            {data.isSelfregistered === true ||
            (data.isSelfregistered === false &&
              other.otherId === 0 &&
              otherName.name !== "") ||
            (data.isSelfregistered === false && other.otherId > 0) ? (
              sendRequest === true ? (
                <div className="form-group mt-4 mb-3">
                  <button disabled onClick={() => onSubmit()}>
                    Continue
                  </button>
                </div>
              ) : (
                <div className="form-group mt-4 mb-3">
                  <button onClick={() => onSubmit()}>Continue</button>
                </div>
              )
            ) : (
              <div className="form-group mt-4 mb-3">
                <button onClick={() => onSubmit()} disabled>
                  Continue
                </button>
              </div>
            )}
          </div>
        </main>
        {/* <footer>
      <p>&copy; 2023, Healthogenics</p>
  </footer> */}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

const mapDispatchToProps = {
  registerKitForSelf: (
    isSelfregistered: boolean,
    name: string,
    barcode: string,
    data: IFinalDataForOthers,
    callback: any
  ) => registerKitForSelf(isSelfregistered, name, barcode, data, callback),
  registerForOthers: (data: IFinalDataForOthers, callback: Function) =>
    registerForOthers(data, callback),
  getOthers: () => getOthers(),
};

function mapStateToProps(state: any) {
  return {
    fullProfileData: state.profile?.fullProfileData,
    others: state.registerNewKit?.others,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KitRegisterForSelf);
