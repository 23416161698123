import React from "react";
import Modal from "react-modal";
import { IBarcodeUploadResponseDisplay } from "../../../models/admin/barcodeListing.ts";

const barcodeUploadResponse = (props: IBarcodeUploadResponseDisplay) => {
  const { isResponseVisible, onClose, message } = props;
  return (
    <>
      <Modal
        isOpen={isResponseVisible}
        className="modal fade"
        // id="profile-edit"
        // data-bs-backdrop="static"
        // data-bs-keyboard="false"
        // tabIndex={-1}
        // aria-labelledby="profile-edit"
        // aria-hidden="true"
      >
        {/* <div
          className="modal fade"
          id="profile-edit"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="profile-edit"
          aria-hidden="true"
        > */}
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body barcode-error-modal">
              <div className="card-profile">
                <div className="profile w-100 profile-dtls">
                  <h4>
                    <center>Barcode Validation Messages</center>
                  </h4>
                  {message.map((c: any) => (
                    <p className="mt-1">
                      <strong>{c}</strong>
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn-primary"
                onClick={() => {
                  onClose();
                  // document.body.classList.remove("flow-hidden");
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </Modal>
    </>
  );
};

export default barcodeUploadResponse;
