import { IApiResponse } from "../../models/IAPIResponse";
import { urlBinder } from "../../helper/url-binder";
import apiService from "../../helper/api-service";
import {
  IActivateOrDeactivate,
  IResetPassword,
  IUserList,
} from "../../models/admin/userList";
import { UserList } from "../../constants/apiUrl";

export default new (class userListService {
  public getUserList = async (
    pageNumber: number,
    pageSize: number,
    keyword: string
  ): Promise<IApiResponse<Array<IUserList>>> => {
    try {
      let endPoint = urlBinder(UserList.GET_USER_LIST, [
        pageNumber.toString(),
        pageSize.toString(),
        keyword,
      ]);
      const response = await apiService.get(endPoint);
      return response.data as IApiResponse<Array<IUserList>>;
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<Array<IUserList>>;
      } else {
        throw error;
      }
    }
  };

  public getUserListExport = async (
    keyword: string
  ): Promise<IApiResponse<any>> => {
    try {
      let endPoint = urlBinder(UserList.GET_USER_LIST_EXPORT, [keyword]);
      const response = await apiService.get(endPoint, {
        responseType: "blob",
      });
      return response.data as IApiResponse<any>;
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<any>;
      } else {
        throw error;
      }
    }
  };

  public activateOrDeactivate = async (
    userid: number,
    isactive: boolean
  ): Promise<IApiResponse<boolean>> => {
    try {
      let endPoint = urlBinder(UserList.PATCH_DEACTIVATE_OR_ACTIVATE_USER, [
        userid,
        isactive,
      ]);
      const response = await apiService.patch(endPoint);
      return response.data as IApiResponse<boolean>;
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<boolean>;
      } else {
        throw error;
      }
    }
  };

  // public resetPassword = async (
  //   data: IResetPassword
  // ): Promise<IApiResponse<boolean>> => {
  //   try {
  //     let endPoint = UserList.POST_FORGET_PASSWORD;
  //     const response = await apiService.post(endPoint, data);
  //     return response.data as IApiResponse<boolean>;
  //   } catch (error: any) {
  //     if (error.response) {
  //       return error.response as IApiResponse<boolean>;
  //     } else {
  //       throw error;
  //     }
  //   }
  // };

  public resetPasswordUserList = async (
    email:string, password:string, callback:Function
  ): Promise<boolean> => {
    try {
      let endPoint = urlBinder(UserList.POST_RESET_PASSWORD_USER_LIST, [
        email, password
      ]);
      const response = await apiService.post(endPoint);
        return response?.data as boolean;
    
    } catch (error: any) {
      if (error.response) {
        return error.response as boolean;
      } else {
        throw error;
      }
    }
  };
})();
