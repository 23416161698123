import React from "react";

interface SpinnerProp {
  isLoading: boolean;
}

const ButtonSpinner = (props: SpinnerProp) => {
  return (
    <>
      {props.isLoading ? (
        <>
          <div className="data-loader">
            <span>
              <i className="fa fa-spinner fa-spin"></i>
            </span>
          </div>
        </>
      ) : null}
    </>
  );
};

export default ButtonSpinner;
