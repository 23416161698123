import { IApiResponse } from "../../models/IAPIResponse";
import { urlBinder } from "../../helper/url-binder";
import apiService from "../../helper/api-service";
import { Settings } from "../../constants/apiUrl";
import { ISettingsData } from "../../models/admin/settings";
import { ISettingUpdate } from "../../models/ICommon";

export default new (class settingsService {
  public getSettingsIndex = async (  
    pageNumber: number,
    pageSize: number,
    keyword: string): Promise<
    IApiResponse<Array<ISettingsData>>
  > => {
    try {
      let endPoint = urlBinder(Settings.GET_SETTINGS_LIST,[
        pageNumber?.toString(),
        pageSize?.toString(),
        keyword,
      ]);
      const response = await apiService.get(endPoint);
      return response?.data as IApiResponse<Array<ISettingsData>>;
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<Array<ISettingsData>>;
      } else {
        throw error;
      }
    }
  };

  public editSettings = async (data: ISettingUpdate): Promise<IApiResponse<boolean>> => {
    try {
      let endPoint = urlBinder(Settings.PUT_SETTINGS_LIST, []);
      const response = await apiService.put(endPoint, data);
      return response as IApiResponse<boolean>;
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<boolean>;
      } else {
        throw error;
      }
    }
  };
})();
