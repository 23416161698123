import { createSlice } from "@reduxjs/toolkit";
import { ILoginEmailSlice } from "../../../models/registeration";

const initialLoginEmailState: ILoginEmailSlice = {
  emailData: undefined,
};

const emailLoginSlice = createSlice({
  name: "loginEmail",
  initialState: initialLoginEmailState,
  reducers: {
    setEmailData: (state, action: { payload: any }) => {
      state.emailData = action.payload;
    },
  },
});

export const { setEmailData } = emailLoginSlice.actions;

const emailLoginReducer = emailLoginSlice.reducer;

export type emailLoginState = ReturnType<typeof emailLoginReducer>;

export default emailLoginReducer;
