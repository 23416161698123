import { createSlice } from "@reduxjs/toolkit";
import { ICommonSlice } from "../../../models/ICommon";

const initialState: ICommonSlice = {
  focuses: undefined,
  medicalConditions: undefined,
  statuses: undefined,
  testNames: undefined,
  gender: undefined,
};

const commonSlice = createSlice({
  name: "dropdownData",
  initialState: initialState,
  reducers: {
    setFocuses: (state, action: { payload: any }) => {
      state.focuses = action.payload;
    },
    setMedicalConditions: (state, action: { payload: any }) => {
      state.medicalConditions = action.payload;
    },
    setStatuses: (state, action: { payload: any }) => {
      state.statuses = action.payload;
    },
    setTestNames: (state, action: { payload: any }) => {
      state.testNames = action.payload;
    },
    setGender: (state, action: { payload: any }) => {
      state.gender = action.payload;
    },
  },
});

export const {
  setFocuses,
  setMedicalConditions,
  setStatuses,
  setTestNames,
  setGender,
} = commonSlice.actions;

const commonReducer = commonSlice.reducer;

export type commonSliceState = ReturnType<typeof commonReducer>;

export default commonReducer;
