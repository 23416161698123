import { IApiResponse } from "../../models/IAPIResponse";
import APIService from "../../helper/api-service";
import { urlBinder } from "../../helper/url-binder";
import {
  IFullProfile,
  IHeaderProfile,
  IShortProfile,
} from "../../models/profile";
import {
  TestResult,
  profile,
  userProfileShortOrExtended,
} from "../../constants/apiUrl";
import { url } from "inspector";

export default new (class profileService {
  public editFullProfile = async (
    data: FormData
  ): Promise<boolean> => {
    try {
      let endPoint = urlBinder(
        userProfileShortOrExtended.GET_FULL_PROFILE_UPDATE,
        []
      );
      const response = await APIService.put(endPoint, data);
      return response?.data as boolean;
    } catch (error: any) {
      if (error.response) {
        return error.response as boolean;
      } else {
        throw error;
      }
    }
  };

  public getShortProfile = async (): Promise<
    IApiResponse<Array<IShortProfile>>
  > => {
    try {
      let endPoint = urlBinder(
        userProfileShortOrExtended.GET_SHORT_PROFILE,
        []
      );
      const response = await APIService.get(endPoint);
      return response?.data as IApiResponse<Array<IShortProfile>>;
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<Array<IShortProfile>>;
      } else {
        throw error;
      }
    }
  };

  public getFullProfile = async (): Promise<
    IApiResponse<Array<IFullProfile>>
  > => {
    try {
      let endPoint = urlBinder(userProfileShortOrExtended.GET_FULL_PROFILE, []);
      const response = await APIService.get(endPoint);
      return response?.data as IApiResponse<Array<IFullProfile>>;
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<Array<IFullProfile>>;
      } else {
        throw error;
      }
    }
  };

  public getHeaderProfile = async (): Promise<
    IApiResponse<Array<IHeaderProfile>>
  > => {
    try {
      let endPoint = urlBinder(TestResult.GET_HEADER_PROFILE, []);
      const response = await APIService.get(endPoint);
      return response?.data as IApiResponse<Array<IHeaderProfile>>;
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<Array<IHeaderProfile>>;
      } else {
        throw error;
      }
    }
  };
})();
