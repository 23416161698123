import commonService from "../../apiServices/common/commonService";
import {
  setFocuses,
  setGender,
  setMedicalConditions,
  setStatuses,
  setTestNames,
} from "../../store/slices/common/commonSlice";

export function getFocuses() {
  return async function (dispatch: any, getState: any) {
    let response = await commonService.getFocuses();
    if (response?.success === true) {
      dispatch(setFocuses(response.data));
    } else {
    }
  };
}

export function getMedicalConditions() {
  return async function (dispatch: any, getState: any) {
    let response = await commonService.getMedicalConditions();
    if (response?.success === true) {
      dispatch(setMedicalConditions(response.data));
    } else {
    }
  };
}

export function getStatuses() {
  return async function (dispatch: any, getState: any) {
    let response = await commonService.getStatuses();
    if (response?.success === true) {
      dispatch(setStatuses(response?.data));
    } else {
    }
  };
}

export function getTestNames() {
  return async function (dispatch: any, getState: any) {
    let response = await commonService.getTestNames();
    if (response?.success === true) {
      dispatch(setTestNames(response.data));
    } else {
    }
  };
}

export function getGender() {
  return async function (dispatch: any, getState: any) {
    let response = await commonService.getGender();
    if (response?.success === true) {
      dispatch(setGender(response.data));
    } else {
    }
  };
}
