import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  IRecommendedSupplements,
  ISupplements,
} from "../../../models/supplements";
import { pageSize } from "../../../constants/constants";
import { getSupplements } from "../../../actions/supplementActionCreator";
//import Slider from "react-slick";

const RecommendedSupplement = (props: IRecommendedSupplements) => {
  const { getSupplements, supplements } = props;

  const [selectedPage, setSelectedPage] = useState(1);
  const [keyword, setKeyword] = useState<string>("");

  // executes when a page number is set or clicked
  const onPageChange = (pageNo: number) => {
    setSelectedPage(pageNo);
  };
  var $: any;
  useEffect(() => {
    getSupplements(selectedPage, pageSize);
    // const carousal = () => {
    // $(function () {
    //   $(".caro-supplement-update").slick({
    //     infinite: true,
    //     slidesToShow: 3,
    //     slidesToScroll: 3,
    //     dots: true,
    //     arrows: true,
    //     autoplay: true,
    //     autoplaySpeed: 7000,
    //     prevArrow:
    //       "<button type='button' class='slick-prev pull-left'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
    //     nextArrow:
    //       "<button type='button' class='slick-next pull-right'><i class='fa fa-angle-right' aria-hidden='true'></i></button>",
    //   });
    // });
    // };
  }, [selectedPage, pageSize]);

  // const settings = {
  //   // dots: true,
  //   slidesToShow: 3,
  //   slidesToScroll: 3,
  //   autoplay: true,
  //   autoplaySpeed: 8000,
  //   infinite: true,
  // };

  return (
    <>
      <div className="col-md-6 mt-3">
        <div className="d-flex justify-content-between">
          <h4>Recommended Supplements</h4>
          <a
            href="https://www.shopify.com/in/online"
            target="_blank"
            className="btn-more"
          >
            View All <i className="fa-solid fa-arrow-right"></i>
          </a>
        </div>
        <div className="caro-supplement-update">
          {/* <Slider {...settings}> */}
          {supplements
            ? supplements.map((element: ISupplements, index: number) => {
                return (
                  <>
                    <div className="items">
                      <div className="card">
                        <div className="img">
                          <img
                            className="card-img-top"
                            src={element.imageUrl}
                            // src={"assets/images/prod_01.jpg"}
                          />
                        </div>
                        <div className="card-body">
                          <h5>{element?.productName}</h5>
                          <p>{element?.description}</p>
                          <h6 className="rate">{element?.price}</h6>
                          <a
                            href="https://www.shopify.com/in/online"
                            target="_blank"
                          >
                            <button className="btn btn-primary w-100">
                              Buy
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            : null}
          {/* </Slider> */}
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  getSupplements: (pageNumber: number, pageSize: number) =>
    getSupplements(pageNumber, pageSize),
};

function mapStateToProps(state: any) {
  return {
    supplements: state.supplement?.supplementsData
      ? state.supplement?.supplementsData?.data
      : null,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecommendedSupplement);
