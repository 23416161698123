import React from "react";
interface IHeader {
  currentStep: number;
  changeStep: any;
}

const Step4Header = (props: IHeader) => {
  const { currentStep, changeStep } = props;
  const changePageTo3 = () => {
    changeStep(currentStep - 1);
  };

  const changePageTo4 = () => {
    changeStep(currentStep);
  };

  return (
    <>
      <div className="login-pagination">
        <ul>
          <li className="active">
            <a>step 1</a>
          </li>
          <li className="active">
            <a>step 2</a>
          </li>
          <li className="active">
            <a
            // onClick={changePageTo3}
            >
              step 3
            </a>
          </li>
          <li className="active">
            <a onClick={changePageTo4}>step 4</a>
          </li>
          <li>
            <a>step 5</a>
          </li>
          <li>
            <a>step 6</a>
          </li>
        </ul>
        <p className="text-center">
          Step <span className="fw-bold">{currentStep} of 6</span>
        </p>
      </div>
    </>
  );
};

export default Step4Header;
