import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { defaultpageCount, pageSize } from "../constants/constants";
import { IDataTable } from "../models/ICommon";
import moment from "moment";
import FloatingLabelTextInput from "./floatingLabelTextInput";
import { data } from "jquery";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import FloatingLabelDropDown from "./floatingLabelDropdown";
import { ISettingsData, IUrl } from "../models/admin/settings";
import ButtonSpinner from "./buttonSpinner";

const DataTable = (props: IDataTable) => {
  const { noDataFound, bgColor, selectedPage } = props;

  const [adminSettingsdata, setAdminSettingsdata] = useState<any>({
    value: "",
    isEnable: false,
  });

  // executes on page number on click
  const handlePageClick = (data: any) => {
    props.onPageChange(++data.selected);
  };

  useEffect(() => {
    //executes function which should be triggered on page number change
    props.onPageChange(selectedPage);
  }, []);

  return (
    <>
      <div className="main-table">
        {/* <div className="main-table loader"> */}
        <table id="masterTable">
          <thead>
            <tr role="row">
              {props.isIndexRequired && <th>Sl No</th>}
              {props.columns
                ? props.columns.map((c: any, index: number) => {
                    return <th key={index}>{c.label}</th>;
                  })
                : null}
              {props.isToSortBarcodeNumber === "sortbyBarcodeNumberFlag" && (
                <th>
                  <div className="d-flex align-items-center justify-content-between">
                    <label className="text-nowrap">Barcode No.</label>
                    <div className="arrows ps-4">
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          props.isToSortBarcodeNumberFunAsc(
                            "ASC",
                            "sortbyBarcodeNumberFlag"
                          );
                        }}
                      >
                        <i className="fa-solid fa-sort-up"></i>
                      </span>
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          props.isToSortBarcodeNumberFunDesc(
                            "DESC",
                            "sortbyBarcodeNumberFlag"
                          );
                        }}
                      >
                        <i className="fa-solid fa-sort-down"></i>
                      </span>
                    </div>
                  </div>
                </th>
              )}
              {props.isToSortProductName === "sortbyProductNameFlag" && (
                <th>
                  <div className="d-flex align-items-center justify-content-between">
                    <label className="text-nowrap">Product Name</label>
                    <div className="arrows ps-4">
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          props.isToSortProductNameFunAsc(
                            "ASC",
                            "sortbyProductNameFlag"
                          );
                        }}
                      >
                        <i className="fa-solid fa-sort-up"></i>
                      </span>
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          props.isToSortProductNameFunDesc(
                            "DESC",
                            "sortbyProductNameFlag"
                          );
                        }}
                      >
                        <i className="fa-solid fa-sort-down"></i>
                      </span>
                    </div>
                  </div>
                </th>
              )}
              {props.isToSortRegistered === "sortbyRegisterationStatusFlag" && (
                <th>
                  <div className="d-flex align-items-center justify-content-between">
                    <label className="text-nowrap">Registration Status</label>

                    <div className="arrows ps-4">
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          props.isToSortRegisteredFunAsc(
                            "ASC",
                            "sortbyRegisterationStatusFlag"
                          );
                        }}
                      >
                        <i className="fa-solid fa-sort-up"></i>
                      </span>
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          props.isToSortRegisteredFunDesc(
                            "DESC",
                            "sortbyRegisterationStatusFlag"
                          );
                        }}
                      >
                        <i className="fa-solid fa-sort-down"></i>
                      </span>
                    </div>
                  </div>
                </th>
              )}
              {props.isToSortCreatedDate === "sortbyCreatedDateFlag" && (
                <th>
                  <div className="d-flex align-items-center justify-content-between">
                    <label className="text-nowrap">Created Date</label>
                    <div className="arrows ps-4">
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          props.isToSortCreatedDateFunAsc(
                            "ASC",
                            "sortbyCreatedDateFlag"
                          );
                        }}
                      >
                        <i className="fa-solid fa-sort-up"></i>
                      </span>
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          props.isToSortCreatedDateFunDesc(
                            "DESC",
                            "sortbyCreatedDateFlag"
                          );
                        }}
                      >
                        <i className="fa-solid fa-sort-down"></i>
                      </span>
                    </div>
                  </div>
                </th>
              )}
              {props.isToSortRegisteredDate === "sortbyRegisteredDateFlag" && (
                <th>
                  <div className="d-flex align-items-center justify-content-between">
                    <label className="text-nowrap">Registered Date</label>
                    <div className="arrows ps-4">
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          props.isToSortRegisteredDateFunAsc(
                            "ASC",
                            "sortbyRegisteredDateFlag"
                          );
                        }}
                      >
                        <i className="fa-solid fa-sort-up"></i>
                      </span>
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          props.isToSortRegisteredDateFunDesc(
                            "DESC",
                            "sortbyRegisteredDateFlag"
                          );
                        }}
                      >
                        <i className="fa-solid fa-sort-down"></i>
                      </span>
                    </div>
                  </div>
                </th>
              )}
              {props.isToSortDealId === "sortbyDealIdFlag" && (
                <th>
                  <div className="d-flex align-items-center justify-content-between">
                    <label className="text-nowrap">Deal Id</label>
                    <div className="arrows ps-4">
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          props.isToSortDealIdFunAsc("ASC", "sortbyDealIdFlag");
                        }}
                      >
                        <i className="fa-solid fa-sort-up"></i>
                      </span>
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          props.isToSortDealIdFunDesc(
                            "DESC",
                            "sortbyDealIdFlag"
                          );
                        }}
                      >
                        <i className="fa-solid fa-sort-down"></i>
                      </span>
                    </div>
                  </div>
                </th>
              )}
              {props.isToSortRegisteredBy === "sortbyRegisteredByFlag" && (
                <th>
                  <div className="d-flex align-items-center justify-content-between">
                    <label className="text-nowrap">Registered By</label>
                    <div className="arrows ps-4">
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          props.isToSortRegisteredByFunAsc(
                            "ASC",
                            "sortbyRegisteredByFlag"
                          );
                        }}
                      >
                        <i className="fa-solid fa-sort-up"></i>
                      </span>
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          props.isToSortRegisteredByFunDesc(
                            "DESC",
                            "sortbyRegisteredByFlag"
                          );
                        }}
                      >
                        <i className="fa-solid fa-sort-down"></i>
                      </span>
                    </div>
                  </div>
                </th>
              )}
              {props.buttons && <td></td>}
              {props.editURL && <th>URL</th>}
              {props.isTestPurchasedRegisteredDate && <th>Date</th>}
              {props.isTestPurchasedDetails && <th>Details</th>}
              {props.isFocus && <th>Focus</th>}
              {props.isMedicalCondition && <th>Medical Conditions</th>}
              {props.isHeight && <th>Height</th>}
              {props.isWeight && <th>Weight</th>}
              {props.showDrilldown && <th style={{ width: "120px" }}></th>}
              {props.resetItem && <th style={{ width: "120px" }}></th>}
              {props.isMultiProduct && (
                <>
                  <th>Test Purchased</th>
                  <th style={{ width: "120px" }}></th>
                </>
              )}
              {/* {props.isToSortBarcodeNumber === true && (
                <div className="arrows ps-4">
                  <span>
                    <i
                      className="fa-solid fa-sort-up"
                      onClick={(e) => {
                        e.preventDefault();
                        // props.viewItem(element.id);
                      }}
                    ></i>
                  </span>
                  <span>
                    <i className="fa-solid fa-sort-down"></i>
                  </span>
                </div>
              )} */}
              {/* DELETE */}
              {props.isDeleteItem && <th></th>}
            </tr>
          </thead>

          {props.tabelData?.data ? (
            props.tabelData.data.length > 0 ? (
              <tbody id="filterinfo">
                {props.tabelData.data
                  ? props.tabelData.data.map((element: any, index: number) => {
                      return (
                        <tr role="row" key={index}>
                          {props.isIndexRequired && (
                            <td>
                              {selectedPage > 1
                                ? index + 1 + (selectedPage - 1) * pageSize
                                : index + 1}
                            </td>
                          )}
                          {/* STATUS */}
                          {props.columns
                            ? props.columns.map((c: any, index: number) => {
                                return (
                                  <td key={index}>
                                    {element[c.prop] == 0 ||
                                    element[c.prop] == null ? (
                                      <>-</>
                                    ) : c.prop === bgColor ? (
                                      <>
                                        {element[c.prop] ===
                                          "Sample Received" && (
                                          <span
                                            data-attr={`${element[c.prop]}`}
                                            className="badge bg-yellow"
                                          >
                                            {element[c.prop]}
                                          </span>
                                        )}
                                        {element[c.prop] ===
                                          "Order Received" && (
                                          <span
                                            data-attr={`${element[c.prop]}`}
                                            className="badge bg-skyblue"
                                          >
                                            {element[c.prop]}
                                          </span>
                                        )}
                                        {/*PENDING should be changed*/}
                                        {element[c.prop] === "Pending" && (
                                          <span
                                            data-attr={`${element[c.prop]}`}
                                            className="badge bg-blue"
                                          >
                                            {element[c.prop]}
                                          </span>
                                        )}
                                        {element[c.prop] ===
                                          "Order Shipped" && (
                                          <span
                                            data-attr={`${element[c.prop]}`}
                                            className="badge bg-blue"
                                          >
                                            {element[c.prop]}
                                          </span>
                                        )}

                                        {element[c.prop] ===
                                          "Results Ready" && (
                                          <span
                                            data-attr={`${element[c.prop]}`}
                                            className="badge bg-green"
                                          >
                                            {element[c.prop]}
                                          </span>
                                        )}
                                        {element[c.prop] === "In Progress" && (
                                          <span
                                            data-attr={`${element[c.prop]}`}
                                            className="badge bg-orange"
                                          >
                                            {element[c.prop]}
                                          </span>
                                        )}
                                      </>
                                    ) : (
                                      element[c.prop].toString()
                                    )}
                                  </td>
                                );
                              })
                            : null}

                          {props.editURL && (
                            <td>
                              {
                                props.editURL === "Y" && element.value
                                // :
                                // (
                                //   <a
                                //     style={{ color: "red" }}
                                //     onClick={(e) => {
                                //       e.preventDefault();
                                //       props.editURLFun(element.id);
                                //     }}
                                //   ></a>
                                // )
                              }
                            </td>
                          )}

                          {props.editSettings && props.editURL && (
                            <td>{props.editURL && element.barcodeNumber}</td>
                          )}
                          {/*SORTING BARCODE NUMBER */}

                          {props.isToSortBarcodeNumber && (
                            <td>
                              {props.isToSortBarcodeNumber &&
                              element.barcodeNumber
                                ? element.barcodeNumber
                                : "-"}
                            </td>
                          )}
                          {/*SORTING PRODUCT NAME */}
                          {props.isToSortProductName && (
                            <td>
                              {props.isToSortProductName && element.productName
                                ? element.productName
                                : "-"}
                            </td>
                          )}
                          {/*SORTING REGISTRATION STATUS */}
                          {props.isToSortRegistered && (
                            <td>
                              {props.isToSortRegistered && element.isRegistered
                                ? element.isRegistered
                                : "-"}
                            </td>
                          )}
                          {/*SORTING CREATED DATE */}
                          {props.isToSortCreatedDate && (
                            <td>
                              {props.isToSortCreatedDate && element.createdDate
                                ? moment(
                                    element.createdDate?.split("T")[0]
                                  ).format("MM-DD-YYYY")
                                : "-"}
                            </td>
                          )}
                          {/*SORTING REGISTERED DATE */}
                          {props.isToSortRegisteredDate && (
                            <td>
                              {props.isToSortRegisteredDate &&
                              element.registerDate === "0001-01-01T00:00:00"
                                ? "-"
                                : element.registerDate != "0001-01-01T00:00:00"
                                ? moment(
                                    element.registerDate?.split("T")[0]
                                  ).format("MM-DD-YYYY")
                                : "-"}
                            </td>
                          )}
                          {/*SORTING DEAL ID */}
                          {props.isToSortDealId && (
                            <td>
                              {props.isToSortDealId && element.dealId
                                ? element.dealId
                                : "-"}
                            </td>
                          )}
                          {/*SORTING REGISTERED BY */}
                          {props.isToSortRegisteredBy && (
                            <td>
                              {props.isToSortRegisteredBy &&
                              element.registeredBy
                                ? element.registeredBy
                                : "-"}
                            </td>
                          )}

                          {/* DELETE */}
                          {/* {props.isDeleteItem &&
                          element.isRegistered === "Not Registered" ? (
                            <td>
                              <button
                                className="btn-delete"
                                onClick={(e) => {
                                  e.preventDefault();
                                  props.isDeleteItem(element.barcodeId);
                                }}
                              >
                                <i className="fa-solid fa-trash"></i>
                              </button>
                            </td>
                          ) : (
                            <td>
                              <button className="btn-delete" disabled>
                                <i className="fa-solid fa-trash"></i>
                              </button>
                            </td>
                          )} */}
                          {props.buttons && (
                            <td>
                              <button
                                className="btn btn-secondary btn-sm w-auto"
                                onClick={() => props.buttonClick()}
                              >
                                {props.buttons.label}
                              </button>
                            </td>
                          )}
                          {/* {ISMULTIPRODUCT} */}
                          {props.isMultiProduct === "isMultiProduct" &&
                            props.isMultiProduct && (
                              <td colSpan={2}>
                                <table>
                                  {element.status === "Results Ready"
                                    ? element.productDetails?.map(
                                        (product: any, index: number) => {
                                          return (
                                            <>
                                              <tr key={index}>
                                                <td>
                                                  {product.productName
                                                    ? product.productName
                                                    : "-"}
                                                </td>
                                                <td>
                                                  <div className="btn-group gap-15">
                                                    {product.resultFileUrl !==
                                                      null ||
                                                    product.resultFileUrl !==
                                                      "" ? (
                                                      <a
                                                        className={
                                                          product.resultFileUrl ===
                                                            "" ||
                                                          product.resultFileUrl ===
                                                            null
                                                            ? "btn-more btn-disabled"
                                                            : "btn-more"
                                                        }
                                                        href={
                                                          product.resultFileUrl ===
                                                            null ||
                                                          product.resultFileUrl ===
                                                            ""
                                                            ? ""
                                                            : product.resultFileUrl
                                                        }
                                                        download={
                                                          product.resultFileUrl ||
                                                          product.resultFileUrl ===
                                                            "" ||
                                                          product.resultFileUrl ===
                                                            null
                                                            ? ""
                                                            : product.resultFileUrl
                                                        }
                                                        target="_blank"
                                                      >
                                                        Download
                                                      </a>
                                                    ) : (
                                                      <a className="btn-more btn-disabled">
                                                        Download
                                                      </a>
                                                    )}
                                                  </div>
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        }
                                      )
                                    : element.productDetails?.map(
                                        (product: any, index: number) => {
                                          return (
                                            <>
                                              <tr key={index}>
                                                <td>
                                                  {product.productName
                                                    ? product.productName
                                                    : "-"}
                                                </td>
                                                <td>
                                                  <div className="btn-group gap-15">
                                                    {product.resultFileUrl ===
                                                      null ||
                                                    product.resultFileUrl ===
                                                      "" ? (
                                                      <a className="btn-more btn-disabled">
                                                        Download
                                                      </a>
                                                    ) : (
                                                      <a className="btn-more btn-disabled">
                                                        Download
                                                      </a>
                                                    )}
                                                  </div>
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        }
                                      )}
                                </table>
                              </td>
                            )}

                          {/* TEST PURCHASED DATE */}
                          {props.isTestPurchasedRegisteredDate && (
                            <td>
                              {props.isTestPurchasedRegisteredDate &&
                              element.registerdate
                                ? moment(
                                    element.registerdate.slice(0, 10)
                                  ).format("MM-DD-YYYY")
                                : ""}
                            </td>
                          )}
                          {/* TEST PURCHASED DETAILS */}
                          {props.isTestPurchasedDetails && (
                            <td>
                              {props.isTestPurchasedDetails &&
                              element.details ? (
                                <a
                                  href={element.details}
                                  target="_blank"
                                  className="btn-link"
                                >
                                  {element.details}
                                </a>
                              ) : (
                                ""
                              )}
                            </td>
                          )}
                          {/* FOCUS */}
                          {props.isFocus && (
                            <td>
                              {props.isFocus && element.focus
                                ? element.focus.map(
                                    (content: any, index: number) => {
                                      return (
                                        <span key={index}>
                                          {index ? ", " : ""}
                                          {content}
                                        </span>
                                      );
                                    }
                                  )
                                : null}
                            </td>
                          )}
                          {/* MEDICAL CONDITIONS */}
                          {props.isMedicalCondition && (
                            <td>
                              {props.isMedicalCondition && element.conditions
                                ? element.conditions.map(
                                    (content: any, index: number) => {
                                      return (
                                        <span key={index}>
                                          {index ? ", " : ""}
                                          {content}
                                        </span>
                                      );
                                    }
                                  )
                                : null}
                            </td>
                          )}
                          {/* HEIGHT */}
                          {props.isHeight && (
                            <td>
                              {props.isHeight && element.height
                                ? element.height
                                : "-"}
                            </td>
                          )}
                          {/* WEIGHT */}
                          {props.isWeight && (
                            <td>
                              {props.isWeight && element.weight
                                ? element.weight + " lbs"
                                : "-"}
                            </td>
                          )}

                          {props.showDrilldown && (
                            <td>
                              <div className="btn-group gap-15">
                                {!props.hideDefaultDrilldownItems && (
                                  <>
                                    {/** VIEW PROFILE */}
                                    {props.viewItem && (
                                      <a
                                        href=""
                                        title="View Profile"
                                        className="btn-more"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          props.viewItem(element.id);
                                        }}
                                      >
                                        View Profile
                                      </a>
                                    )}
                                    {props.editSettings && (
                                      <a
                                        href=""
                                        title="View Profile"
                                        className="btn-more"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          props.editSettings(element.id);
                                        }}
                                      >
                                        Edit
                                      </a>
                                    )}
                                    {/* ACTIVATE/DEACTIVATE */}
                                    {props.controlItem && element.isActive && (
                                      <a
                                        href=""
                                        title={
                                          element.isActive === true
                                            ? "Deactivate User"
                                            : "Activate User"
                                        }
                                        // className={
                                        //   element.isActive === true
                                        //     ? "status-active"
                                        //     : "status-deactive"
                                        // }
                                        onClick={(e) => {
                                          e.preventDefault();
                                          props.controlItem(
                                            element.id,
                                            element.isActive
                                          );
                                        }}
                                      >
                                        {element.isActive === true
                                          ? "Deactivate User"
                                          : "Activate User"}
                                      </a>
                                    )}
                                    {/*RESET PASSWORD*/}
                                    {props.resetPasswordItem && (
                                      <a
                                        href=""
                                        title="Reset Password"
                                        className="status-active"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          props.resetPasswordItem(
                                            element.email
                                          );
                                        }}
                                      >
                                        Reset Password
                                      </a>
                                    )}
                                    {props.editItem && (
                                      <a
                                        title="Edit"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          props.editItem(element.id);
                                        }}
                                      >
                                        <i className="ri-pencil-line"></i>
                                      </a>
                                    )}
                                    {/* DOWNLOAD */}
                                    {/* {props.downloadItem &&
                                      (element.status !== "Results Ready" ? (
                                        <a className="btn-more btn-disabled">
                                          Download
                                        </a>
                                      ) : (
                                        <a
                                          className="btn-more"
                                          href={element.resultFileUrl}
                                          download
                                          target="_blank"
                                        >
                                          Download
                                        </a>
                                      ))} */}

                                    {/* ACTIVATE OR DEACTIVATE */}
                                    {/* {props.deleteItem && (
                                      <a
                                        title="Remove"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          props.deleteItem(element.id);
                                        }}
                                      >
                                        <i className="ri-delete-bin-6-line"></i>
                                      </a>
                                    )} */}
                                  </>
                                )}
                              </div>
                            </td>
                          )}
                          {/* RESET PASSWORD */}
                          {props.resetItem && (
                            <td>
                              <div className="btn-group gap-15">
                                {!props.hideDefaultDrilldownItems && (
                                  <>
                                    <a
                                      href=""
                                      title="Reset Password"
                                      className="btn-more text-nowrap"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        props.resetItem(element.id);
                                      }}
                                    >
                                      Reset Password
                                    </a>
                                  </>
                                )}
                              </div>
                            </td>
                          )}
                          {/* DELETE */}
                          {props.isDeleteItem ? (
                            element.isRegistered === "Not Registered" ? (
                              <td>
                                <button
                                  className="btn-delete"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    props.isDeleteItem(
                                      element.barcodeId,
                                      element.barcodeNumber
                                    );
                                  }}
                                >
                                  <i className="fa-solid fa-trash"></i>
                                </button>
                              </td>
                            ) : (
                              <td>
                                <button
                                  disabled
                                  className="btn-delete"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    props.isDeleteItem(
                                      element.barcodeId,
                                      element.barcodeNumber
                                    );
                                  }}
                                >
                                  <i className="fa-solid fa-trash"></i>
                                </button>
                              </td>
                            )
                          ) : null}

                          {/* {props.isDeleteItem &&
                          element.isRegistered === "Not Registered" ? (
                            <td>
                              <button
                                className="btn-delete"
                                onClick={(e) => {
                                  e.preventDefault();
                                  props.isDeleteItem(element.barcodeId);
                                }}
                              >
                                <i className="fa-solid fa-trash"></i>
                              </button>
                            </td>
                          ) : (
                            <td>
                              <button className="btn-delete" disabled>
                                <i className="fa-solid fa-trash"></i>
                              </button>
                            </td>
                          )} */}
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            ) : (
              <p>{noDataFound}</p>
            )
          ) : (
            <tbody>
              <tr>
                <td colSpan={8}>
                  {" "}
                  <ButtonSpinner isLoading={true} />
                </td>
              </tr>
            </tbody>
          )}
        </table>

        <div className="d-flex align-items-center justify-content-between mt-3">
          <div className="pagination">
            {props.paginateItem && props.tabelData?.data
              ? props.tabelData?.totalRecords >= pageSize && (
                  <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={
                      props.tabelData?.data
                        ? Math.ceil(props.tabelData?.totalRecords / pageSize)
                        : defaultpageCount
                    }
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    forcePage={selectedPage - 1}
                  />
                )
              : null}
          </div>
          {props.tabelData && props.tabelData?.totalRecords >= pageSize && (
            <div className="page-info">
              Page
              <input
                type="text"
                placeholder={selectedPage}
                value={selectedPage}
                // onChange={handlePageClick}
              />
              {/* {(selectedPage - 1) * pageSize + 1} -{" "}
              {(selectedPage - 1) * pageSize + pageSize <
              props.tabelData?.totalRecords
                ? (selectedPage - 1) * pageSize + pageSize
                : props.tabelData?.totalRecords}{" "} */}
              of {/* {props.tabelData?.totalRecords} */}
              {props.tabelData?.data
                ? Math.ceil(props.tabelData?.totalRecords / pageSize)
                : defaultpageCount}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DataTable;
