import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  IProductDetails,
  ITestResult,
  ITestResultDisplay,
} from "../../../../models/testResult";
import { getTestResults } from "../../../../actions/testResultActionCreator";
import { get } from "http";
import { pageSize } from "../../../../constants/constants";
import moment from "moment";

const TestResult = (props: ITestResultDisplay) => {
  const { getTestResults, testResultData, resultMessage, noDealData } = props;

  // const [selectedPage, setSelectedPage] = useState(1);
  let pageSize = 100;
  let selectedPage = 0;
  const [openSection, setOpenSection] = useState(-1);
  const [expandFirstItem, setExpandFirstItem] = useState(true);

  const handleToggleClick = (sectionId: any) => {
    if (openSection === sectionId) {
      setOpenSection(-1);
    } else {
      setOpenSection(sectionId);
    }
  };
  // useEffect(() => {
  //   getTestResults(selectedPage, pageSize);
  // }, [testResultData]);

  useEffect(() => {
    const fetchData = () => {
        getTestResults(selectedPage, pageSize);
    };
    fetchData();
    const intervalId = setInterval(fetchData, 5 * 1000); // 5 seconds
    return () => clearInterval(intervalId);
}, [selectedPage, pageSize]);

  useEffect(() => {
    setOpenSection(0);
  }, []);

  const downloadResultData = (downloadUrl: any) => {
    fetch("http://localhost:3000/dashboard/").then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = downloadUrl;
        a.click();
      });
    });
  };
  

  return (
    <>
      <div className="col-md-12 mt-3">
        <div className="d-flex justify-content-between">
          <h4>Your Test Results</h4>
        </div>
        <div className="card-wrap card-scroll">
          {/*         <div className="card-wrap card-scroll loader">
           */}
          {testResultData && testResultData?.length === 0 ? (
            <>
              <div className="card-wrap p-3">
                <img
                  src="assets/images/no-test.png"
                  className="d-block m-auto"
                  height="90"
                  draggable="false"
                />
                <p className="text-center mt-0">
                  {/* {resultMessage &&
                  resultMessage === "Error while loading tests data code-MD001"
                    ? "No tests registered yet"
                    : "No tests registered yet"} */}
                </p>
              </div>
            </>
          ) : testResultData === undefined ? (
            <div>
              <img
                src="assets/images/no-test.png"
                className="d-block m-auto"
                height="90"
              />
              <p className="text-center mt-0">No tests registered yet</p>
            </div>
          ) : noDealData === "DealIds is null" ? (
            <>
              <img
                src="assets/images/no-test.png"
                className="d-block m-auto"
                height="90"
              />
              <p className="text-center mt-0">No tests registered yet</p>
            </>
          ) : testResultData && testResultData?.length > 0 ? (
            <>
              <div className="card-wrap p-3">
                <div className="test-result">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    {testResultData?.map(
                      (element: ITestResult, index: number) => {
                        return (
                          <>
                            <tbody
                              className={`tbl-accordion-header ${
                                openSection === index ? "open" : ""
                              }`}
                              onClick={() => handleToggleClick(index)}
                            >
                              <tr>
                                <td>
                                  <a data-toggle="toggle">
                                    <i className="fa-solid fa-angle-down"></i>
                                  </a>
                                </td>
                                <td>
                                  <b>{element?.testeeName}</b>
                                </td>
                                <td>{element?.createDate}</td>
                                <td>
                                  {element?.status &&
                                  element?.status == "Results Ready" ? (
                                    <div className="test-status">
                                      <span className="badge text-bg-success">
                                        Result Ready
                                      </span>
                                    </div>
                                  ) : element?.status == "Pending" ? (
                                    <div className="test-status">
                                      <span className="badge bg-orange">
                                        {element?.status}
                                      </span>
                                    </div>
                                  ) : element?.status == "In Progress" ? (
                                    <div className="test-status">
                                      <span className="badge bg-skyblue">
                                        {element?.status}
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="test-status">
                                      <span className="badge text-bg-warning">
                                        {element?.status}
                                      </span>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                            <tbody
                              className={`tbl-accordion-body ${
                                openSection === index ? "open" : ""
                              }`}
                            >
                              <tr>
                                <td
                                rowSpan={200}
                                  // rowSpan={
                                  //   testResultData?.length > 0?
                                  //     testResultData?.length + 2:5
                                      
                                  // }
                                >
                                  <strong>{element?.dealName}</strong>
                                </td>
                              </tr>
                              {element?.testsPurchased?.map(
                                (item: IProductDetails, index: number) => {
                                  return (
                                    <>
                                    
                                      <tr>
                                        <td key={index} colSpan={2} className="border-left">
                                          <strong>{item?.productName}</strong>
                                        </td>
                                        <td className="b-0">
                                          {element?.status ===
                                            "Results Ready" &&
                                          item?.resultLink !== "" &&
                                          item?.resultLink !== null &&
                                          item?.resultLink !== undefined ? (
                                            <div className="test-btns d-flex align-items-end justify-content-between gap-10 ">
                                              <a
                                                href={
                                                  item?.resultLink &&
                                                  item?.resultLink
                                                }
                                                download
                                                target="_blank"
                                              >
                                                <button className="btn-primary">
                                                  Download Result
                                                </button>
                                              </a>
                                            </div>
                                          ) : (
                                            <div className="test-btns d-flex align-items-end justify-content-between gap-10 ">
                                              <a
                                                href={
                                                  item.resultLink &&
                                                  item.resultLink
                                                }
                                                download
                                                target="_blank"
                                              >
                                                <button
                                                  className="btn-primary"
                                                  disabled
                                                >
                                                  Download Result
                                                </button>
                                              </a>
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  );
                                }
                              )}
                            </tbody>
                          </>
                        );
                      }
                    )}
                  </table>
                </div>
              </div>
            </>
          ) : noDealData === "DealIds is null" ? (
            <>
              <img
                src="assets/images/no-test.png"
                className="d-block m-auto"
                height="90"
              />
              <p className="text-center mt-0">No tests registered yet</p>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  getTestResults: (pageNumber: number, pageSize: number) =>
    getTestResults(pageNumber, pageSize),
};

function mapStateToProps(state: any) {
  return {
    testResultData: state.testResult
      ? state.testResult?.testResultData
        ? state.testResult?.testResultData?.testResultData?.data
        : // state.testResult?.testResultData?.data
          null
      : null,
    resultMessage: state.testResult
      ? state.testResult?.testResultData
        ? state.testResult?.testResultData?.message
        : null
      : null,
    noDealData: state.testResult
      ? state.testResult?.testResultData
        ? state.testResult?.testResultData?.testResultData
        : null
      : null,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestResult);
