import { createSlice } from "@reduxjs/toolkit";
import { IProfileSlice } from "../../../models/profile";

const initialProfileState: IProfileSlice = {
  shortProfileData: undefined,
  fullProfileData: undefined,
  fullProfileUpdate: undefined,
  headerProfileData: undefined,
};

const profileSlice = createSlice({
  name: "profile",
  initialState: initialProfileState,
  reducers: {
    setShortProfileData: (state, action: { payload: any }) => {
      state.shortProfileData = action.payload;
    },
    setFullProfileData: (state, action: { payload: any }) => {
      state.fullProfileData = action.payload;
    },
    setFullProfileUpdate: (state, action: { payload: any }) => {
      state.fullProfileUpdate = action.payload;
    },
    setHeaderProfileData: (state, action: { payload: any }) => {
      state.headerProfileData = action.payload;
    },
  },
});

export const {
  setShortProfileData,
  setFullProfileData,
  setFullProfileUpdate,
  setHeaderProfileData,
} = profileSlice.actions;

const profileReducer = profileSlice.reducer;

export type profileState = ReturnType<typeof profileReducer>;

export default profileReducer;
