import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Joi from "joi";
import { connect } from "react-redux";
import FloatingLabelTextInput from "../../../common-components/floatingLabelTextInput";
import {
  IHubsportData,
  IHubspotCreateData,
  IRegisterMailData,
  IStep2,
  IStep2Display,
} from "../../../models/registeration";
import { validateFields } from "../../../helper/joi-validation";
import {
  registerMail,
  resendOTP,
  verifyOTP,
} from "../../../actions/loginActionCreator";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { alertConstants } from "../../../helper/sweet-alert";

const Step2 = (props: IStep2Display) => {
  const navigate = useNavigate();
  const [disableResendMail, setDisableResendMail] = useState(0);

  const {
    verifyOTP,
    currentStep,
    changeStep,
    getMail,
    registerMail,
    hubSpotData,
    resendOTP,
    getDataOfUserForOTP,
  } = props;
  const [isButtonVisible, setButtonVisible] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [errors, setErrors] = useState<any>(undefined);
  const [failure, setFailure] = useState(false);
  const [timesOfResend, setTimesOfResend] = useState<number>(1);
  const [disableResend, setDisableResend] = useState<boolean>(false);

  const [data, setData] = useState<IStep2>({
    id: 0,
    otp: "",
    email: getMail,
  });
  // const [hubspot, setHubspot] = useState<IHubsportData>({
  //   email: hubSpotData && hubSpotData.email ? hubSpotData.email : "",
  //   firstName:
  //     hubSpotData && hubSpotData.firstName ? hubSpotData.firstName : "",
  //   lastName: hubSpotData && hubSpotData.lastName ? hubSpotData.lastName : "",
  //   id: hubSpotData && hubSpotData.id ? hubSpotData.id : 0,
  // });

  const schema = Joi.object().keys({
    mail: Joi.allow(),
    otp: Joi.string()
      .regex(/^\d{6}$/)
      .label("Enter 6 digit number"),
  });

  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    errors && delete errors[event.target.name];
    const _data = {
      ...data,
      [event.target.name]: event.target.value.trim(),
    };
    setData(_data);
  };

  const saveRequest = async () => {
    var metaData: any = {
      email: data.email,
      otp: data.otp,
      phone: getDataOfUserForOTP.mobileNo,
    };
    await verifyOTP(metaData, callback);
  };

  const callback = (response: any) => {
    if (response === true) {
      changeStep(currentStep + 1);
    } else if (response === false) {
      incorrectOTP();
    } else if (response === "Verification failed") {
      incorrectOTP();
    } else {
      somethingWentWrong();
    }
  };

  const onSubmit = async () => {
    setDisableButton(true);
    const validationErrors = validateFields(data, schema);
    if (validationErrors == undefined) {
      saveRequest();
    } else {
      setDisableButton(false);
      setErrors(validationErrors);
    }
  };

  const emailAlreadyRegistered = () => {
    toast.error("Email Id Already registered !!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        setDisableResend(false);
      },
    });
  };

  // const onResendMail = async () => {};
  const inResponse = (response: any) => {
    if (response === false) {
      plsCheckYourMail();
    } else if (response === true) {
      emailAlreadyRegistered();
    } else {
      somethingWentWrong();
    }
  };

  const onResendMail = async () => {
    setDisableResend(true);
    setTimesOfResend(timesOfResend + 1);
    if (timesOfResend === 1 || timesOfResend === 2 || timesOfResend === 3) {
      var properties: any = {
        email: getDataOfUserForOTP.email,
        firstName: "",
        lastName: "",
        phone: getDataOfUserForOTP.mobileNo,
        otp_code: "",
        consent_method: "",
      };
      var resendOTPBody = { properties };
      await resendOTP(getMail, resendOTPBody, inResponse);
    } else {
      console.log("contact admin");
    }
  };

  const plsCheckYourMail = () => {
    toast.success("Please check your Mail !!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        setDisableResend(false);
      },
    });
  };

  const somethingWentWrong = () => {
    toast.error("Something Went Wrong !!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        setDisableButton(false);
        setDisableResend(false);
      },
    });
  };

  const incorrectOTP = () => {
    toast.error("Incorrect OTP", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        setDisableButton(false);
      },
    });
  };

  // useEffect(() => {

  // }, [hubSpotData]);

  return (
    <>
      <div className="login-card">
        <div className="title">
          <h3>Verify OTP</h3>
          <p>
            Check your email
            <span className="fw-bold text-dark"> {data.email}</span> for the OTP
          </p>
        </div>
        <div className="form-group">
          <FloatingLabelTextInput
            // type="Password"
            label={"OTP"}
            onChange={onChange}
            isMandatory={true}
            value={data.otp.trim()}
            name={"otp"}
            placeholder={"OTP"}
          />
          {errors && errors.otp && <div className="errors">{errors.otp}</div>}
          {/* <input type="Password" placeholder="OTP" id="otp" /> */}
          {/* <label htmlFor="otp">OTP</label> */}
          <div className="icon">
            <img src="assets/images/icons/lock.svg" alt="" />
          </div>
        </div>
        <div className="form-group mt-4 mb-3">
          {disableButton === true ? (
            <button onClick={() => onSubmit()} disabled>
              Verify Email
            </button>
          ) : (
            <button onClick={() => onSubmit()}>Verify Email</button>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-between position-relative">
          <div className="txt-secondary fs-14">
            Didn't get the code?
            {timesOfResend === 1 ||
            timesOfResend === 2 ||
            timesOfResend === 3 ||
            timesOfResend === 4 ? (
              disableResend === true ? (
                <a
                  href="#"
                  className="fw-semibold txt-primary m-1"
                  onClick={() => onResendMail()}
                >
                  {/* Need to disable resend */}
                  Resend
                </a>
              ) : (
                <a
                  href="#"
                  className="fw-semibold txt-primary m-1"
                  onClick={() => onResendMail()}
                >
                  Resend
                </a>
              )
            ) : (
              <span>
                <p className="text-red">Please contact administrator!</p>
              </span>
            )}
          </div>
          <div className="forgot">
            <a href="javascript:void(0)" className="fw-semibold txt-primary">
              Need Help?
            </a>
            <div className="tooltip-login">
              <h6>Finding the OTP</h6>
              <p>
                "If you are unable to locate your OTP Code, please check you
                Junk and Spam folders. The email will be sent from
                verify@mdhealth.com. You can also search your inbox for this
                address to locate the email. Please be sure to mark as "Not
                Spam" or "Not Junk" or add to your contact list as important
                updates to your account will also be sent from this email
                address"
              </p>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-center"></div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

const mapDispatchToProps = {
  verifyOTP: (data: IStep2, callback: Function) => verifyOTP(data, callback),
  registerMail: (email: string, callback: Function) =>
    registerMail(email, callback),
  resendOTP: (email: string, data: IHubspotCreateData, callback: Function) =>
    resendOTP(email, data, callback),
};

function mapStateToProps(state: any) {
  return {
    hubSpotData: state.register?.hubSpotData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Step2);
