import { Configuration, PopupRequest } from "@azure/msal-browser";
import { apiConfig } from "./constants/apiConfig";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: apiConfig.ad_clientid ?? '',
        authority: apiConfig.ad_authority ?? '',
        redirectUri: apiConfig.ad_redirecturi ?? '',
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: false,
    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["User.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: apiConfig.ad_graph_me_endpoint ?? '',
};
