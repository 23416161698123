import surveyListService from "../apiServices/admin/surveyListService";
import { alertConstants } from "../helper/sweet-alert";
import {
  setFocusFilter,
  setFullProfileAdminData,
  setMedicalConditionFilter,
  setSurveyListData,
  setSurveyListExportData,
} from "../store/slices/admin/surveyList";

export function getSurveyList(
  pageNumber: number,
  pageSize: number,
  keyword: string,
  focuson?: number,
  conditions?: number
) {
  return async function (dispatch: any, getState: any) {
    let response = await surveyListService.getSurveyList(
      pageNumber,
      pageSize,
      keyword,
      focuson,
      conditions
    );
    if (response?.success === true) {
      dispatch(setSurveyListData(response.data));
    } else {
    }
  };
}

function downloadFile(response: any, name: string) {
  var blob = new Blob([response]);

  let a = document.createElement("a");

  var url = window.URL.createObjectURL(blob);

  a.href = url;

  a.download = `${name} ${new Date()}.xlsx`;

  a.click();

  window.URL.revokeObjectURL(url);

  return true;
}

export function getSurveyListExport(
  keyword?: string,
  focuson?: number,
  conditions?: number
) {
  return async function (dispatch: any, getState: any) {
    let response = await surveyListService.getSurveyListExport(
      keyword,
      focuson,
      conditions
    );

    downloadFile(response, "file");
  };
}

export function getFullProfileAdmin(userId: number) {
  return async function (dispatch: any, getState: any) {
    let response = await surveyListService.getFullProfileAdmin(userId);
    if (response?.success === true) {
      dispatch(setFullProfileAdminData(response.data));
    } else {
    }
  };
}

export function updateFullProfileAdmin(data: FormData, callback: Function) {
  return async function (dispatch: any, getState: any) {
    let response = await surveyListService.editFullProfileAdmin(data);
    if (response === true) {
      callback(true);
    } else if(response === false) {
      callback(false);
    } else {
        callback(alertConstants.failed)
    }
  };
}

