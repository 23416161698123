import profileService from "../apiServices/profile/profileService";
import { alertConstants } from "../helper/sweet-alert";
import { IFullProfile } from "../models/profile";
import {
  setFullProfileData,
  setHeaderProfileData,
  setShortProfileData,
} from "../store/slices/dashboard/profile";

export function getShortProfile() {
  return async function (dispatch: any, getState: any) {
    let response = await profileService.getShortProfile();
    if (response?.success === true) {
      dispatch(setShortProfileData(response?.data));
    } else {
    }
  };
}

export function getFullProfile() {
  return async function (dispatch: any, getState: any) {
    let response = await profileService.getFullProfile();
    if (response?.success === true) {
      dispatch(setFullProfileData(response?.data));
    } else {
    }
  };
}

export function getHeaderProfile() {
  return async function (dispatch: any, getState: any) {
    let response = await profileService.getHeaderProfile();
    if (response?.success === true) {
      dispatch(setHeaderProfileData(response?.data));
    } else {
    }
  };
}

export function updateFullProfile(data: FormData, callback: Function) {
  return async function (dispatch: any, getState: any) {
    let response = await profileService.editFullProfile(data);
    if (response === true) {
      callback(true);
    } else if(response === false) {
      callback(false);
    } else {
        callback(alertConstants.failed)
    }
  };
}
