import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTER_URL } from "../../constants/routerUrl";

const Focus = () => {
  const navigate = useNavigate();

  //function to redirect to medical conditions
  const next = () => {
    navigate(ROUTER_URL.MEDICAL_CONDITIONS);
  };
  return (
    <>
      <div className="login-wrap">
        <header>
          <a href="">
            <img src="assets/images/logo.webp" alt="" />
          </a>
        </header>
        <main>
          <div className="login-card">
            <div className="title">
              <h3>Tell Us More</h3>
              <p>
                Which of the following would you like to focus on? You can
                select up to 4.
              </p>
            </div>
            <div className="row">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <div className="checkbox-round">
                  <input
                    type="checkbox"
                    id="multi-check1"
                    name="multi-check1"
                    value="multi-check"
                  />
                  <label
                  //   for="multi-check1"
                  >
                    Weight Management
                  </label>
                </div>

                <div className="checkbox-round">
                  <input
                    type="checkbox"
                    id="multi-check2"
                    name="multi-check2"
                    value="multi-check"
                  />
                  <label
                  //   for="multi-check2"
                  >
                    Energy
                  </label>
                </div>

                <div className="checkbox-round">
                  <input
                    type="checkbox"
                    id="multi-check3"
                    name="multi-check3"
                    value="multi-check"
                  />
                  <label
                  //   for="multi-check3"
                  >
                    Detoxify
                  </label>
                </div>

                <div className="checkbox-round">
                  <input
                    type="checkbox"
                    id="multi-check4"
                    name="multi-check4"
                    value="multi-check"
                  />
                  <label
                  //   for="multi-check4"
                  >
                    Improve Gut Health
                  </label>
                </div>

                <div className="checkbox-round">
                  <input
                    type="checkbox"
                    id="multi-check5"
                    name="multi-check5"
                    value="multi-check"
                  />
                  <label
                  //   for="multi-check5"
                  >
                    Improve Nutrition
                  </label>
                </div>

                <div className="checkbox-round">
                  <input
                    type="checkbox"
                    id="multi-check7"
                    name="multi-check7"
                    value="multi-check"
                  />
                  <label
                  //   for="multi-check7"
                  >
                    None of the Above
                  </label>
                </div>

                <div className="checkbox-round">
                  <input
                    type="checkbox"
                    id="multi-check6"
                    className="other-options"
                    name="multi-check6"
                    value="multi-check"
                  />
                  <label
                  //   for="multi-check6"
                  >
                    Other
                  </label>
                </div>
              </div>
              <div id="others-textbox" style={{ display: "none" }}>
                <div className="w-100">
                  <div className="form-group pad-2">
                    <input type="text" placeholder="others" id="others" />
                    <label
                    // for="others"
                    >
                      Specify Other
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group mt-4 mb-3" onClick={next}>
                <button>Next</button>
              </div>
            </div>
          </div>
        </main>
        <footer>
          <p>&copy; 2023 Medical Weight Loss by Healthogenics.</p>
        </footer>
      </div>
    </>
  );
};

export default Focus;
