import barcodeListingService from "../apiServices/admin/barcodeListingService";
import settingsService from "../apiServices/admin/settingsService";
import surveyListService from "../apiServices/admin/surveyListService";
import { alertConstants } from "../helper/sweet-alert";
import { setBarcodeListingData } from "../store/slices/admin/barcodeListing";
import { setSettingsData } from "../store/slices/admin/settings";
export function getBarcodeListing(
  keyword?: any,
  pageNumber?: any,
  pageSize?: any,
  sortbyname?: boolean,
  sortbybarcode?: boolean,
  sortbycreateddate?: boolean,
  sortbyRegistred?: boolean,
  sortbyRegisteredDate?: boolean,
  sortbydealid?: boolean,
  sortbyusername?: boolean
) {
  return async function (dispatch: any, getState: any) {
    let response = await barcodeListingService.getBarcodeListingIndex(
      keyword,
      pageNumber,
      pageSize,
      sortbyname,
      sortbybarcode,
      sortbycreateddate,
      sortbyRegistred,
      sortbyRegisteredDate,
      sortbydealid,
      sortbyusername
    );
    if (response?.status === 200) {
      dispatch(setBarcodeListingData(response?.data));
    } else {
    }
  };
}

export function uploadExcelSheet(data: any, callback: Function) {
  return async function (dispatch: any, getState: any) {
    let response: any = await barcodeListingService.uploadExcel(data);
    if (response?.data?.status === true) {
      callback(response?.data);
    } else if (response?.data?.status === false) {
      callback(response?.data);
    } else {
      callback(alertConstants.somethingWentWrong);
    }
  };
}

export function deleteBarcode(id: number, callback: Function) {
  return async function (dispatch: any, getState: any) {
    let response = await barcodeListingService.deleteBarcode(id);
    if (response === false) {
      callback(alertConstants.failed);
    } else {
      callback(response);
    }
  };
}
