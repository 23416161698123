import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Joi from "joi";
import { connect } from "react-redux";
import FloatingLabelTextInput from "../../../common-components/floatingLabelTextInput";
import {
  IFinalData,
  IRegisterMailData,
  IStep1Display,
} from "../../../models/registeration";
import { validateFields } from "../../../helper/joi-validation";
import { ROUTER_URL } from "../../../constants/routerUrl";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import {
  getVerifiedEmail,
  registerMail,
} from "../../../actions/loginActionCreator";
import { alertConstants } from "../../../helper/sweet-alert";

const Step1 = (props: IStep1Display) => {
  const navigate = useNavigate();
  const {
    // registerMail,
    currentStep,
    changeStep,
    getVerifiedEmail,
    emailData,
    verifiedEmailData,
  } = props;
  const [errors, setErrors] = useState<any>(undefined);
  const [failure, setFailure] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  localStorage.clear();
  localStorage.removeItem("hubspot_email");
  localStorage.removeItem("emailData?.firstName");
  localStorage.removeItem(" emailData?.lastName");
  localStorage.removeItem("emailData?.phone");

  const [data, setData] = useState<IRegisterMailData>({
    id: 0,
    email: "",
  });

  const schema = Joi.object().keys({
    email: Joi.string()
      .regex(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
      .required()
      .label("Enter valid email"),
  });

  const saveRequest = async () => {
    await getVerifiedEmail(data.email, callback);
    var metaData: IRegisterMailData = {
      email: data.email,
    };
    let mail = metaData.email;
    props.sendDataToParent(mail);
  };

  const callback = (response: any) => {
    if (currentStep === 1) {
      if (response === false) {
        emailAlreadyRegistered();
      } else if (
        response.firstName !== null ||
        undefined ||
        response.lastName === null ||
        undefined ||
        response.phone !== null ||
        undefined
      ) {
        localStorage.setItem("hubspot_email", emailData?.email);
        localStorage.setItem("hubspot_firstName", emailData?.firstName);
        localStorage.setItem("hubspot_lastName", emailData?.lastName);
        localStorage.setItem("hubspot_phone", emailData?.phone);
        changeStep(props.currentStep + 1);
      } else if (
        response?.firstName === null ||
        response?.lastName === null ||
        response?.phone === null
      ) {
        changeStep(props.currentStep + 1);
      } else {
        somethingWentWrong();
      }
    }
  };

  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    debugger;
    errors && delete errors[event.target.name];
    // Remove Unicode control characters and invisible characters
    var trimmedEmail = event.target.value.replace(
      /[\p{C}|\p{M}|\p{Z}|\p{S}]/gu,
      ""
    );
    const _data = {
      ...data,
      //spaceRemoval
      [event.target.name]: trimmedEmail.replace(/\s/g, ""),
    };
    setData(_data);
  };

  const onSubmit = async () => {
    setDisableButton(true);
    const validationErrors = validateFields(data, schema);
    if (validationErrors == undefined) {
      setDisableButton(true);
      saveRequest();
    } else {
      setDisableButton(false);
      setErrors(validationErrors);
    }
  };

  const login = () => {
    navigate(ROUTER_URL.LOGIN);
  };

  useEffect(() => {
    if (currentStep === 1) {
      console.log(emailData && emailData);

      if (emailData?.message === "Email is already registered.") {
        emailAlreadyRegistered();
      } else if (
        disableButton === true &&
        emailData &&
        emailData?.firstName === null &&
        emailData?.lastName === null &&
        emailData?.phone === null
      ) {
        changeStep(props.currentStep + 1);
      } else if (
        disableButton === true &&
        emailData &&
        emailData?.firstName !== undefined &&
        emailData?.lastName !== undefined &&
        emailData?.phone !== undefined
      ) {
        localStorage.setItem("hubspot_email", emailData?.email);
        localStorage.setItem("hubspot_firstName", emailData?.firstName);
        localStorage.setItem("hubspot_lastName", emailData?.lastName);
        localStorage.setItem("hubspot_phone", emailData?.phone);
        changeStep(props.currentStep + 1);
      } else {
        console.log("error");
      }
    }
  }, [emailData && emailData]);

  const emailAlreadyRegistered = () => {
    toast.error("Email Id already registered !!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        setDisableButton(false);
      },
    });
  };

  const plsCheckYourMail = () => {
    toast.success("Please check your Mail !!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {},
    });
  };

  const somethingWentWrong = () => {
    toast.error("Something Went Wrong !!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        setDisableButton(false);
      },
    });
  };

  return (
    <>
      <div className="login-card">
        <div className="title">
          <h3>Create your account</h3>
          <p>Please enter your Email Id to create your account</p>
        </div>
        <div className="form-group">
          {" "}
          <FloatingLabelTextInput
            label={"Email"}
            onChange={onChange}
            isMandatory={true}
            value={data.email.trim()}
            name={"email"}
            placeholder={"Email"}
          />
          {errors && errors.email && (
            <div className="errors">{errors.email}</div>
          )}
          <div className="icon">
            <img src="assets/images/icons/email.svg" alt="" />
          </div>
        </div>
        {disableButton === true ? (
          <div className="form-group mt-4 mb-3">
            <button onClick={() => onSubmit()} disabled>
              Continue
            </button>
          </div>
        ) : (
          <div className="form-group mt-4 mb-3">
            <button onClick={() => onSubmit()}>Continue</button>
          </div>
        )}{" "}
        {/* <div className="form-group mt-4 mb-3">
          <button onClick={() => onSubmit()}>Continue</button>
        {" "}
        </div> */}
        <div className="d-flex align-items-center justify-content-center">
          <span className="txt-secondary fs-14">
            Already have an account?
            <a className="fw-semibold txt-primary m-1" onClick={login} href="#">
              Sign in here
            </a>
          </span>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </>
  );
};

const mapDispatchToProps = {
  // registerMail: (email: string, callback: Function) =>
  //   registerMail(email, callback),
  getVerifiedEmail: (email: string) => getVerifiedEmail(email),
};

function mapStateToProps(state: any) {
  return {
    emailData: state.loginEmail?.emailData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
