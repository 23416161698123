import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTER_URL } from "../../../../constants/routerUrl";
import { IShortProfileDisplay } from "../../../../models/profile";
import { getShortProfile } from "../../../../actions/profileActionCreator";
import FullProfile from "./component/fullProfile";
import { BASE_URL_IMAGE } from "../../../../constants/constants";

const Profile = (props: IShortProfileDisplay) => {
  const navigate = useNavigate();
  const { shortProfileData, getShortProfile } = props;
  const [isFullProfileVisible, setIsFullProfileVisible] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);

  // useEffect(() => {
  //   getShortProfile();
  // }, [shortProfileData&&shortProfileData]);

  useEffect(() => {
    const fetchData = () => {
      getShortProfile();
    };
    fetchData();
    const intervalId = setInterval(fetchData, 5 * 1000); // 5 seconds
    return () => clearInterval(intervalId);
  }, []);

  const applyProfileVisible = () => {
    setIsFullProfileVisible(true);
  };

  // const viewProfile = () => {
  //   navigate(ROUTER_URL.FULL_PROFILE, { state: { id: id, isView: false } });
  // };

  return (
    <>
      <div className="col-md-6 mt-3">
        <div className="d-flex justify-content-between">
          <h4>User Profile</h4>
        </div>
        <div className="card-profile">
          <div className="profile-top d-flex align-items-center justify-content-end">
            <button
              type="button"
              className="btn-white btn-flex"
              // onClick={viewProfile}
              onClick={(event) => applyProfileVisible()}
            >
              Full Profile <i className="fa-solid fa-arrow-right"></i>
            </button>
            <div className="img">
              <img src="assets/images/profile.jpg" alt="" />
            </div>
          </div>
          <div className="profile-btm d-flex align-items-center justify-content-between">
            <div className="profile">
              <div className="img">
                <img
                  src={
                    shortProfileData?.imagePath
                      ? BASE_URL_IMAGE + shortProfileData?.imagePath
                      : "assets/images/profile.jpg"
                  }
                  alt=""
                  draggable="false"
                />
              </div>
              <h5 className="mb-1">
                {shortProfileData && shortProfileData?.fullName
                  ? shortProfileData?.fullName
                  : "-"}
              </h5>
              {/* <p>
                {shortProfileData && shortProfileData.mobileNo
                  ? shortProfileData.mobileNo
                  : "-"}
              </p> */}
            </div>
            <div className="d-flex align-items-center justify-content-between gap-15">
              <div className="stats stats-blue">
                <div className="d-flex justify-content-end mb-2">
                  <img
                    src="assets/images/icons/brief.svg"
                    alt=""
                    draggable="false"
                  />
                </div>
                <h6>
                  {shortProfileData && shortProfileData?.kitsPurchased
                    ? shortProfileData?.kitsPurchased
                    : "-"}{" "}
                  <span>Kits Purchased</span>
                </h6>
              </div>
              <div className="stats stats-green">
                <div className="img d-flex justify-content-end mb-2">
                  <img
                    src="assets/images/icons/chart.svg"
                    alt=""
                    draggable="false"
                  />
                </div>
                <h6>
                  {shortProfileData && shortProfileData?.resultPublished
                    ? shortProfileData?.resultPublished
                    : "-"}{" "}
                  <span>Result Published</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FullProfile
        isProfileVisible={isFullProfileVisible}
        onClose={() => setIsFullProfileVisible(false)}
      />
    </>
  );
};

const mapDispatchToProps = {
  getShortProfile: () => getShortProfile(),
};

function mapStateToProps(state: any) {
  return {
    shortProfileData: state.profile?.shortProfileData
      ? state.profile.shortProfileData
      : "",
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
