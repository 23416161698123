import React from "react";
import ReactPlayer from "react-player";
import { IVideoDisplay } from "../../../models/dashboard";

const HowItWorks = (props: IVideoDisplay) => {
  const{video}=props;
  return (
    <>
      <div className="col-md-6 mt-3">
        <div className="d-flex justify-content-between">
          <h4>Healthogenics</h4>
        </div>
        <div>
          <div className="vimeo-blk">
            <iframe
              loading="lazy"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: "0",
                left: "0",
                border: "none",
                padding: "0",
                margin: "0",
              }}
              // src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFqahtjCro&#x2F;watch?embed&autoplay=1"
              //  src="https://www.youtube.com/embed/V9yYvVto8Cs?si=_tbh2bfqo2Uguv8J?embed"
              src={(video!=="")?video+"?embed":""}
           
              // allow="autoplay fullscreen picture-in-picture"
              allow="autoPlay"
              allowFullScreen
            ></iframe>
          </div>
          {/* <a
          href="https://www.canva.com/design/DAFqahtjCro/watch"
            // href="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFqahtjCro&#x2F;watch?utm_content=DAFqahtjCro&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link"
            target="_blank"
            rel="noopener"
          ></a> */}
          {/* <div className="vimeo-blk">
            <iframe
              src="https://player.vimeo.com/video/846130445?h=69b45697fc&byline=0&portrait=0"
              frameBorder={0}
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script> */}
        </div>
      </div>
    </>
  );
};

export default HowItWorks;
