export const apiConfig = {
    siteUrl: process.env.REACT_APP_SITEURL,
    authority: process.env.REACT_APP_OIDC_AUTHORITY,
    client_id: process.env.REACT_APP_OIDC_CLIENTID,
    redirect_uri: process.env.REACT_APP_OIDC_REDIRECTURI,
    response_type: process.env.REACT_APP_OIDC_RESPONSETYPE,
    scope: process.env.REACT_APP_OIDC_SCOPE,
    loadUserInfo: process.env.REACT_APP_LOAD_USERINFO,
    response_mode: process.env.REACT_APP_RESPONSE_TYPE,

    api_url: process.env.REACT_APP_APIURL,
    ad_authority: process.env.REACT_APP_AD_AUTHORITY,
    ad_clientid: process.env.REACT_APP_AD_CLIENTID,
    ad_redirecturi: process.env.REACT_APP_AD_REDIRECTURI,
    ad_graph_me_endpoint: process.env.REACT_APP_AD_GRAPH_ME_ENDPOINT
}
