import React, { useEffect, useState } from "react";
import Footer from "./component/footer";
import Header from "./component/header";
import { connect } from "react-redux";
import {
  getSettingsList,
  updateSettings,
} from "../../actions/adminSettingsActionCreator";
import {
  ISettingDataDisplay,
  ISettingEditDisplay,
  ISettingsData,
  IUrl,
} from "../../models/admin/settings";
import { commonStatusList, pageSize } from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import FloatingLabelTextInput from "../../common-components/floatingLabelTextInput";
import { ROUTER_URL } from "../../constants/routerUrl";
import { validateFields } from "../../helper/joi-validation";
import Joi from "joi";
import FloatingLabelDropDown from "../../common-components/floatingLabelDropdown";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { ISettingEdit, ISettingUpdate } from "../../models/ICommon";

const SettingsEdit = (props: ISettingEditDisplay) => {
  const { getSettingsList, updateSettings, settingsData } = props;
  const [errors, setErrors] = useState<any>(undefined);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [data, setData] = useState<any>({
    id: 0,
    name: "",
    value: "",
    // isEnable: false,
  });
  const navigate = useNavigate();
  const cancelButton = () => {
    navigate(ROUTER_URL.ADMIN_SETTINGS);
  };
  useEffect(() => {
    getSettingsList();
  }, []);

  useEffect(() => {
    let adminConfigId = Number(localStorage.getItem("adminConfig"));
    const desiredItem: any =
      settingsData?.data &&
      settingsData.data.find((item: any) => item.id === adminConfigId);
    setData({
      id: desiredItem?.id,
      name: desiredItem?.name,
      value: desiredItem.value,
      isEnable: desiredItem.isEnable,
    });
  }, [settingsData && settingsData]);

  const schema = Joi.object().keys({
    name: Joi.string().allow(),
    value: Joi.string().required().label("Enter valid url"),
  });

  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    errors && delete errors[event.target.name];
    const _requestDetails = {
      ...data,
      [event.target.name]: event.target.value,
    };
    if (event.target.name == "value") {
      setDisableButton(false);
    }
    setData(_requestDetails);
  };

  const callback = (response: any) => {
    if (response === "success") {
      UserSuccessfullyRegistered();
    } else if (response === "failed") {
      updateFailed();
    } else {
      somethingWentWrong();
    }
  };

  const UserSuccessfullyRegistered = () => {
    toast.success("Updated successfully!!", {
      position: "top-right",
      autoClose: 0.5,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        navigate(ROUTER_URL.ADMIN_SETTINGS);
        setDisableButton(false);
      },
    });
  };

  const updateFailed = () => {
    toast.error("Update failed !!", {
      position: "top-right",
      autoClose: 0.5,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        setDisableButton(false);
      },
    });
  };

  const somethingWentWrong = () => {
    toast.error("Something Went Wrong !!", {
      position: "top-right",
      autoClose: 0.5,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        setDisableButton(false);
      },
    });
  };

  const saveRequest = async () => {
    var metaData: any = {
      id: data.id,
      value: data.value,
      isEnable: true,
    };
    await updateSettings(metaData, callback);
  };

  const onSubmit = async () => {
    setDisableButton(true);
    const validationErrors = validateFields(data, schema);
    if (validationErrors === undefined) {
      await saveRequest();
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <>
      <Header />
      <main>
        <div className="container">
          <div className="admin-title">
            <h4>Edit Settings</h4>
          </div>
          <div className="card form-normal p-3 mb-3">
            <div className="row">
              <label htmlFor="name"></label>

              <div className="col-md-4">
                <div className="form-group">
                  <FloatingLabelTextInput
                    label={"Name"}
                    onChange={onChange}
                    isMandatory={true}
                    value={data?.name}
                    name={"name"}
                    placeholder={"Name"}
                    disable
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <FloatingLabelTextInput
                    label={"Value"}
                    onChange={onChange}
                    isMandatory={true}
                    value={data?.value}
                    name={"value"}
                    placeholder={"Value"}
                  />
                  {errors && errors.value && (
                    <div className="errors">{errors.value}</div>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="d-flex align-items-center gap-2">
                  {disableButton === true ? (
                    <button
                      className="btn-primary"
                      onClick={() => {
                        onSubmit();
                      }}
                      disabled
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      className="btn-primary"
                      onClick={() => {
                        onSubmit();
                      }}
                    >
                      Save
                    </button>
                  )}

                  <button className="btn-secondary" onClick={cancelButton}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

const mapDispatchToProps = {
  getSettingsList: (pageNumber: number, pageSize: number, keyword: string) =>
    getSettingsList(pageNumber, pageSize, keyword),
  updateSettings: (data: ISettingUpdate, callback: Function) =>
    updateSettings(data, callback),
};

function mapStateToProps(state: any) {
  return {
    settingsData: state.adminSettings?.settingsData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsEdit);
