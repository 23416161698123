import { IApiResponse } from "../../models/IAPIResponse";
import { ITestResult } from "../../models/testResult";
import { urlBinder } from "../../helper/url-binder";
import apiService from "../../helper/api-service";
import { TestResult } from "../../constants/apiUrl";

export default new (class testResultService {
  public getTestResults = async (
    pageNumber: number,
    pageSize: number
  ): Promise<IApiResponse<Array<ITestResult>>> => {
    try {
      let endPoint = urlBinder(TestResult.GET_TEST_RESULT, [
        pageNumber.toString(),
        pageSize.toString(),
      ]);
      const response = await apiService.get(endPoint);
      if (response?.status === 200 && response?.data === "DealIds is null") {
        return response as any;
      } else {
        return response as IApiResponse<Array<ITestResult>>;
      }
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<Array<ITestResult>>;
      } else {
        throw error;
      }
    }
  };
})();
