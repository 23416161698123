export const ROUTER_URL = {
  ROOT: "/",

  //dashboard
  DASHBOARD: "/dashboard",

  //sampleRegistration
  SAMPLE_REGISTRATION: "/sampleRegistration",

  //purchaseNewKit
  PURCHASE_NEW_KIT: "/purchaseNewKit",

  //testResult
  TEST_RESULT: "/testResult",

  //recommendedSupplement
  RECOMMENDED_SUPPLEMENT: "/recommendedSupplement",

  //howItWorks
  HOW_IT_WORKS: "/howItWorks",

  //Instruction
  INSTRUCTION: "/instruction",

  //profile
  PROFILE: "/profile",

  //fullProfile
  FULL_PROFILE: "/fullProfile",

  //contactDetails
  CONTACT_DETAIL: "/contactDetails",

  //login
  LOGIN: "/",

  //callback
  AUTHCALLBACK: "/auth-callback",

  //registrationPages
  CREATE_ACCOUNT: "/createAccount",

  //userDetails
  USER_DETAILS: "/userDetails",

  //verifyOTP
  VERIFY_OTP: "/verifyOTP",

  //focus
  FOCUS: "/focus",

  //medicalConditions
  MEDICAL_CONDITIONS: "/medicalConditions",

  //bodyComposition
  BODY_COMPOSITION: "/bodyComposition",

  //editFullProfile
  EDIT_FULL_PROFILE: "/editFullProfile",

  //ADMIN PAGES

  //testPurchased
  ADMIN_TEST_PURCHASED: "/testPurchased",

  //userList
  ADMIN_USER_LIST: "/userList",

  //surveyList
  ADMIN_SURVEY_LIST: "/surveyList",

  //barcode listing
  ADMIN_BARCODE_LISTING: "/barcodeManagement",

  //settings
  ADMIN_SETTINGS: "/settings",

  //testResultDownload
  ADMIN_TEST_RESULT_DOWNLOAD: "/testResultDownload",

  //REGISTRATION
  USER_REGISTER: "/registration",

  //FORGOT PASSWORD
  FORGOT_PASSWORD: "/forgotPassword",

  //EMAIL FOR FORGOT PASSWORD
  EMAIL_FOR_FORGOT_PASSWORD: "/emailForgotPassword",

  //BARCODE REGISTER
  BARCODE_REGISTER: "/barcodeRegister",

  //KIT REGISTER FOR SELF
  KIT_REGISTER_FOR_SELF: "/kitRegisterForSelf",

  //REGISTRATION FOR OTHERS
  REGISTRATION_FOR_OTHERS: "/registrationForOthers",

  //EDIT SETTINGS
  SETTINGS_EDIT: "/editSettings",
};
