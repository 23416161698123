export const getAlertData = (type: string) => {
  switch (type) {
    case "error": {
      return { type: "error", title: "Failure" };
    }
    case "success": {
      return { type: "success", title: "Success" };
    }
    case "warning": {
      return { type: "warning", title: "Warning" };
    }
    case "cancelCheck": {
      return { type: "warning", title: "Do you want to cancel ?" };
    }
    case "deleteCheck": {
      return { type: "warning", title: "Do you want to delete this item ?" };
    }
    case "deleteSuccess": {
      return { type: "success", title: "Item deleted successfully" };
    }
    case "deleteFailure": {
      return { type: "error", title: "Error in deleting Item" };
    }
  }
};

export const alertConstants = {
  // loginSuccessful: "Login successful!",
  // loginFailed: "Invalid email or password.",
  success: "success",
  errror: "error",
  warning: "warning",
  failed: "failed",
  cancelCheck: "cancelCheck",
  deleteCheck: "deleteCheck",
  deleteSuccess: "deleteSuccess",
  deleteFailure: "deleteFailure",
  somethingWentWrong: "somethingWentWrong",
};
