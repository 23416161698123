import React, { useEffect, useState } from "react";
import Header from "./component/header";
import Footer from "./component/footer";
import { connect } from "react-redux";
import DataTable from "../../common-components/dataTable";
import { useNavigate } from "react-router-dom";
import FullProfileAdmin from "./component/fullProfileAdmin";
import { ExcelFileTypes, pageSize } from "../../constants/constants";
import {
  IBarcodeListingDisplay,
  IExcel,
  ISorting,
} from "../../models/admin/barcodeListing.ts";
import {
  deleteBarcode,
  getBarcodeListing,
  uploadExcelSheet,
} from "../../actions/adminBarcodeListingActionCreator";
import { useDropzone } from "react-dropzone";
import { blob } from "stream/consumers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import ButtonSpinner from "../../common-components/buttonSpinner";
import BarcodeUploadResponse from "./component/barcodeUploadResponse";
import BarcodeDeleteConfirm from "./component/barcodeDeleteConfirm";

const BarcodeListing = (props: IBarcodeListingDisplay) => {
  const {
    uploadExcelSheet,
    barcodeListingData,
    getBarcodeListing,
    deleteBarcode,
  } = props;
  const [selectedPage, setSelectedPage] = useState(1);
  const [isFullProfileVisible, setIsFullProfileVisible] = useState(false);
  const [userid, setUserid] = useState(0);
  const [keyWord, setKeyWord] = useState(false);
  const [reload, setReload] = useState("");
  const navigate = useNavigate();
  const [data, setData] = useState<ISorting>({
    keyword: "",
    sortbyname: false,
    sortbybarcode: false,
    sortbycreateddate: false,
    sortbyRegistred: false,
    sortbyRegisteredDate: false,
    sortbydealid: false,
    sortbyusername: false,
  });
  const [fileData, setFileData] = useState<IExcel>({ file: Blob });
  const [file, setFile] = useState<any>([]);
  const [success, setSuccess] = useState<any>([]);
  const [barcodeId, setBarcodeId] = useState(0);
  const [barcodeNumber, setBarcodeNumber] = useState("");

  const [sortbyBarcodeNumberFlag, setSortbyBarcodeNumberFlag] = useState(0);
  const [sortbyProductNameFlag, setSortbyProductNameFlag] = useState(0);
  const [sortbyRegisterationStatusFlag, setSortbyRegisterationStatusFlag] =
    useState(0);
  const [sortbyCreatedDateFlag, setSortbyCreatedDateFlag] = useState(0);
  const [sortbyRegisteredDateFlag, setSortbyRegisteredDateFlag] = useState(0);
  const [sortbyDealIdFlag, setSortbyDealIdFlag] = useState(0);
  const [sortbyRegisteredByFlag, setSortbyRegisteredByFlag] = useState(0);
  const [isActive, setActive] = useState<boolean>(false);
  const [isLoader, setLoader] = useState<boolean>(false);
  const [isResponseVisible, setIsResponseVisible] = useState<boolean>(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState([]);
  // const [errorMessage, setErrorMessage] = useState("");
  const [deleteMessage, setDeleteMessage] = useState("");
  const [order, setOrder] = useState("No");
  const [flag, setFlag] = useState("");
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [barcodeDataChanged, setBarcodeDataChanged] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(0);

  let pageSize = 5;
  //function to execute onChange event
  const onChange = (
    event:
      | any
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (event.keyCode == 13) {
      setKeyWord(true);
    } else {
      setKeyWord(false);
      const _data = {
        ...data,
        [event.target.name]: event.target.value,
      };

      setData(_data);
    }
  };

  const uploadErrorResponse = (message: any) => {
    setUploadErrorMessage(message);
    setIsResponseVisible(true);
  };

  const callback = (response: any) => {
    if (response.status === true) {
      setSuccessMessage(response?.message);
      successfullyRegistered(response?.message);
    } else if (response?.status === false) {
      setReload("reload");
      setLoader(false);
      setUploadErrorMessage(response?.messages);
      uploadErrorResponse(response?.messages);
    } else {
      somethingWentWrong();
    }
  };

  // const removeFile = (index: number) => {
  //   let fileN = [...file];
  //   fileN.splice(index, 1);
  //   setFile(fileN);
  //   fileN = file.splice(index, 1);
  //   const _Data = {
  //     ...JSON.parse(JSON.stringify(fileData)),
  //     file: [...file],
  //   };
  //   setFile(_Data);
  // };

  const uploadExcelFile = async (_file: any) => {
    const data = new FormData();
    data.append("file", _file);

    await uploadExcelSheet(data, callback);
    // await _file?.map((file: any, index: number) => {
    //   return( removeFile(index))
    // });
  };

  const removeFile = (index: number) => {
    let fileN = [...file];
    fileN.splice(index, 1);
    setFile(file);
    fileN = fileData.file.splice(index, 1);
    const _barcodeUploadData = {
      ...JSON.parse(JSON.stringify(fileData)),
      file: [...file],
    };
    setFileData(_barcodeUploadData);
  };

  const onDrop = (acceptedFiles: any) => {
    setLoader(true);
    let item = acceptedFiles[0];
    if (ExcelFileTypes.includes(item.type)) {
      setFile(acceptedFiles);
      const _BarcodeUploadData = {
        ...JSON.parse(JSON.stringify(fileData)),
        file: acceptedFiles[0],
      };
      setFileData(_BarcodeUploadData);
      uploadExcelFile(acceptedFiles[0]);
    } else {
      setLoader(false);
      alert("Not an Excel file");
    }
  };

  const { getInputProps } = useDropzone({ onDrop });

  const onPageChange = (pageNumber: number) => {
    setSelectedPage(pageNumber);
  };

  const updateFailed = (message: string) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 0,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        setReload("reload");
        setLoader(false);
      },
    });
  };

  const somethingWentWrong = () => {
    toast.error("Something Went Wrong!!", {
      position: "top-right",
      autoClose: 0,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        setSuccessMessage("");
        setLoader(false);
      },
    });
  };

  const successfullyRegistered = (message: string) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 0,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        setSuccessMessage("");
        getBarcodeListingIndex();
        setLoader(false);
      },
    });
  };

  const sort = async (order: string, flag: string) => {
    setOrder(order);
    setFlag(flag);
  };

  //   useEffect(() => {
  //     setBarcodeDataChanged(true);
  // }, [barcodeListingData]); // Only include barcodeListingData as a dependency here
  const getBarcodeListingIndex = () => {
    getBarcodeListing(
      data.keyword,
      data.keyword == "" ? selectedPage : 1,
      pageSize,
      flag === "sortbyProductNameFlag"
        ? order === "ASC"
          ? true
          : false
        : null,
      flag === "sortbyBarcodeNumberFlag"
        ? order === "ASC"
          ? true
          : false
        : null,
      flag === "sortbyCreatedDateFlag"
        ? order === "ASC"
          ? true
          : false
        : null,
      flag === "sortbyRegisterationStatusFlag"
        ? order === "ASC"
          ? true
          : false
        : null,
      flag === "sortbyRegisteredDateFlag"
        ? order === "ASC"
          ? true
          : false
        : null,
      flag === "sortbyDealIdFlag" ? (order === "ASC" ? true : false) : null,
      flag === "sortbyRegisteredByFlag"
        ? order === "ASC"
          ? true
          : false
        : null
    );
  };

  //SORTING BARCODE NUMBER
  useEffect(() => {
    getBarcodeListingIndex();
  }, [data.keyword, flag, order, selectedPage, isConfirmationVisible]);

  useEffect(() => {
    // Call getBarcodeListingIndex every 5 minutes
    // const intervalId = setInterval(getBarcodeListingIndex, 5 * 60 * 1000); // 5 minutes in milliseconds
    const intervalId = setInterval(getBarcodeListingIndex, 5 * 1000);
    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [data.keyword, flag, order, selectedPage, isConfirmationVisible]); // Dependencies for useEffect

  // useEffect(() => {
  //   getBarcodeListing(
  //     data.keyword,
  //     selectedPage,
  //     pageSize,
  //     null,
  //     null,
  //     null,
  //     null,
  //     null,
  //     null,
  //     null
  //   );
  // }, [barcodeListingData && barcodeListingData]);

  const isDeleteItem = (id: number, barcode: string) => {
    setBarcodeId(id);
    setBarcodeNumber(barcode);
    setReload("reload");
    setLoader(false);
    setIsConfirmationVisible(true);
    // deleteBarcode(id, deleteCallback);
  };

  const switchFlag = (order: string, flag: string) => {
    sort(order, flag);
    // switch (flag) {
    //   case "sortbyBarcodeNumberFlag":
    //     sortByBarcodeNumber(order, flag);
    //     // :  ? setSortbyBarcodeNumberFlag("ASC")
    //     //   setSortbyBarcodeNumberFlag("DESC");
    //     //setActive(true);
    //     break;
    //   case "sortbyProductNameFlag":
    //     order === "asc"
    //       ? setSortbyProductNameFlag("ASC")
    //       : setSortbyProductNameFlag("DESC");
    //     setActive(true);
    //     break;
    //   case "sortbyRegisterationStatusFlag":
    //     order === "asc"
    //       ? setSortbyRegisterationStatusFlag("ASC")
    //       : setSortbyRegisterationStatusFlag("DESC");
    //     setActive(true);
    //     break;
    //   case "sortbyCreatedDateFlag":
    //     order === "asc"
    //       ? setSortbyCreatedDateFlag("ASC")
    //       : setSortbyCreatedDateFlag("DESC");
    //     setActive(true);
    //     break;
    //   case "sortbyRegisteredDateFlag":
    //     order === "asc"
    //       ? setSortbyRegisteredDateFlag("ASC")
    //       : setSortbyRegisteredDateFlag("DESC");
    //     setActive(true);
    //     break;
    //   case "sortbyDealIdFlag":
    //     order === "asc"
    //       ? setSortbyDealIdFlag("ASC")
    //       : setSortbyDealIdFlag("DESC");
    //     setActive(true);
    //     break;
    //   case "sortbyRegisteredByFlag":
    //     order === "asc"
    //       ? setSortbyRegisteredByFlag("ASC")
    //       : setSortbyRegisteredByFlag("DESC");
    //     setActive(true);
    //     break;
    //   default:
    //     console.log("error");
    // }
  };

  const isToSortRegisteredByFunAsc = (order: string, flag: string) => {
    setOrder(order);
    setFlag(flag);
  };

  const isToSortRegisteredByFunDesc = (order: string, flag: string) => {
    setOrder(order);
    setFlag(flag);
  };

  const isToSortDealIdFunAsc = (order: string, flag: string) => {
    setOrder(order);
    setFlag(flag);
  };

  const isToSortDealIdFunDesc = (order: string, flag: string) => {
    setOrder(order);
    setFlag(flag);
  };

  const isToSortRegisteredDateFunAsc = (order: string, flag: string) => {
    setOrder(order);
    setFlag(flag);
  };

  const isToSortRegisteredDateFunDesc = (order: string, flag: string) => {
    setOrder(order);
    setFlag(flag);
  };
  const isToSortCreatedDateFunAsc = (order: string, flag: string) => {
    setOrder(order);
    setFlag(flag);
  };

  const isToSortCreatedDateFunDesc = (order: string, flag: string) => {
    setOrder(order);
    setFlag(flag);
  };

  const isToSortRegisteredFunAsc = (order: string, flag: string) => {
    setOrder(order);
    setFlag(flag);
  };

  const isToSortRegisteredFunDesc = (order: string, flag: string) => {
    setOrder(order);
    setFlag(flag);
  };
  const isToSortProductNameFunAsc = (order: string, flag: string) => {
    setOrder(order);
    setFlag(flag);
  };
  const isToSortProductNameFunDesc = (order: string, flag: string) => {
    setOrder(order);
    setFlag(flag);
  };

  const isToSortBarcodeNumberFunAsc = (order: string, flag: string) => {
    setOrder(order);
    setFlag(flag);
  };

  const isToSortBarcodeNumberFunDesc = (order: string, flag: string) => {
    setOrder(order);
    setFlag(flag);
  };

  const deleteCallback = (response: any) => {
    if (response?.status === 200) {
      setDeleteMessage(response?.data);
      successfullyRegistered(response?.data);
    } else if (response === false) {
      setDeleteMessage(response?.data);
      updateFailed(response?.data);
    } else {
      somethingWentWrong();
    }
  };

  return (
    <>
      <Header />
      <main>
        <div className="container">
          <div className="admin-title">
            <h4>Barcode Management</h4>
          </div>
          <div className="filter-wrap d-flex align-items-center justify-content-between mb-3">
            <div className="search">
              <input
                type="text"
                placeholder="Search"
                value={data.keyword}
                name="keyword"
                onChange={onChange}
                onKeyUp={onChange}
              />{" "}
              <button>
                <img src="assets/images/icons/search.svg" alt="" />
              </button>
            </div>
            <div className="btn-wrap">
              <label
                htmlFor="file-upload"
                className="custom-file-upload btn-primary"
              >
                <i className="fa-regular fa-file-excel pe-2"></i>Upload Barcode
              </label>
              <input
                type="file"
                id="file-upload"
                className="d-none"
                {...getInputProps()}
                name="file"
                accept=".xlsx,.xls"
                onClick={(event) => {
                  event.currentTarget.value = "";
                }}
              />
              <label htmlFor="imageUpload"></label>
            </div>
          </div>
          <div className="data-table mb-3">
            {isLoader === true ? (
              <ButtonSpinner isLoading={true} />
            ) : (
              <DataTable
                noDataFound={"No data found"}
                tabelData={barcodeListingData}
                onPageChange={onPageChange}
                bgColor={"status"}
                selectedPage={selectedPage}
                paginateItem={true}
                //SORTING BY BARCODE NUMBER
                isToSortBarcodeNumber={"sortbyBarcodeNumberFlag"}
                isToSortBarcodeNumberFunAsc={isToSortBarcodeNumberFunAsc}
                isToSortBarcodeNumberFunDesc={isToSortBarcodeNumberFunDesc}
                //SORTING BY PRODUCT NAME
                isToSortProductName={"sortbyProductNameFlag"}
                isToSortProductNameFunAsc={isToSortProductNameFunAsc}
                isToSortProductNameFunDesc={isToSortProductNameFunDesc}
                //SORTING BY REGISTRATION STATUS
                isToSortRegistered={"sortbyRegisterationStatusFlag"}
                isToSortRegisteredFunAsc={isToSortRegisteredFunAsc}
                isToSortRegisteredFunDesc={isToSortRegisteredFunDesc}
                //SORTING BY CREATED DATE
                isToSortCreatedDate={"sortbyCreatedDateFlag"}
                isToSortCreatedDateFunAsc={isToSortCreatedDateFunAsc}
                isToSortCreatedDateFunDesc={isToSortCreatedDateFunDesc}
                //SORTING BY REGISTERED DATE
                isToSortRegisteredDate={"sortbyRegisteredDateFlag"}
                isToSortRegisteredDateFunAsc={isToSortRegisteredDateFunAsc}
                isToSortRegisteredDateFunDesc={isToSortRegisteredDateFunDesc}
                //SORTING BY DEAL ID
                isToSortDealId={"sortbyDealIdFlag"}
                isToSortDealIdFunAsc={isToSortDealIdFunAsc}
                isToSortDealIdFunDesc={isToSortDealIdFunDesc}
                //SORTING BY REGISTERED BY
                isToSortRegisteredBy={"sortbyRegisteredByFlag"}
                isToSortRegisteredByFunAsc={isToSortRegisteredByFunAsc}
                isToSortRegisteredByFunDesc={isToSortRegisteredByFunDesc}
                isDeleteItem={isDeleteItem}
              />
            )}
          </div>
        </div>
      </main>
      <Footer />

      <BarcodeDeleteConfirm
        isConfirmationVisible={isConfirmationVisible}
        id={barcodeId}
        onClose={() => setIsConfirmationVisible(false)}
        barcode={barcodeNumber}
      />
      <BarcodeUploadResponse
        isResponseVisible={isResponseVisible}
        message={uploadErrorMessage && uploadErrorMessage}
        onClose={() => setIsResponseVisible(false)}
      />
      <FullProfileAdmin
        isProfileVisible={isFullProfileVisible}
        userid={userid}
        onClose={() => setIsFullProfileVisible(false)}
      />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

const mapDispatchToProps = {
  getBarcodeListing: (
    keyword?: any,
    pageNumber?: any,
    pageSize?: any,
    sortbyname?: boolean,
    sortbybarcode?: boolean,
    sortbycreateddate?: boolean,
    sortbyRegistred?: boolean,
    sortbyRegisteredDate?: boolean,
    sortbydealid?: boolean,
    sortbyusername?: boolean
  ) =>
    getBarcodeListing(
      keyword,
      pageNumber,
      pageSize,
      sortbyname,
      sortbybarcode,
      sortbycreateddate,
      sortbyRegistred,
      sortbyRegisteredDate,
      sortbydealid,
      sortbyusername
    ),
  uploadExcelSheet: (data: any, callback: Function) =>
    uploadExcelSheet(data, callback),
  deleteBarcode: (id: number, callback: Function) =>
    deleteBarcode(id, callback),
};

function mapStateToProps(state: any) {
  return {
    barcodeListingData: state.adminBarcodeList?.barcodeListingData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BarcodeListing);
