import { applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import { appReducer } from './reducer'
import { configureStore } from '@reduxjs/toolkit'

// const persistConfig = {
//     key: 'root',
//     storage,
// }
// const persistedReducer = persistReducer(persistConfig, appReducer)
const store = configureStore({ reducer: appReducer, middleware: 
    (getDefaultMiddleware) => [...getDefaultMiddleware()]
})
// const persistor = persistStore(store)
const appStore = { store };

export default appStore