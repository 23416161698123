import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTER_URL } from "../../constants/routerUrl";

const UserDetails = () => {
  const navigate = useNavigate();

  //function to create new user and redirect to Focus
  const createUser = () => {
    navigate(ROUTER_URL.FOCUS);
  };

  //function to login, only if the user is already registered
  const login = () => {
    navigate(ROUTER_URL.LOGIN);
  };

  return (
    <>
      <div className="login-wrap">
        <header>
          <a href="">
            <img src="assets/images/logo.webp" alt="" />
          </a>
        </header>
        <main>
          <div className="login-card">
            <div className="title">
              <h3>Fill in Your Details</h3>
              <p>
                To complete the registration, please fill out the required
                information.
              </p>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <input type="text" placeholder="First Name" id="firstname" />
                  <label
                  //   for="firstname"
                  >
                    First Name
                  </label>
                  <div className="icon">
                    <img src="assets/images/icons/lock.svg" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group pad-2">
                  <input type="text" placeholder="Last Name" id="lastname" />
                  <label
                  //   for="lastname"
                  >
                    Last Name
                  </label>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <input type="text" placeholder="Email" id="email" />
                  <label
                  //   for="email"
                  >
                    Email
                  </label>
                  <div className="icon">
                    <img src="assets/images/icons/email.svg" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <input
                    type="number"
                    placeholder="Mobile Number"
                    id="number"
                  />
                  <label
                  //   for="number"
                  >
                    Mobile Number
                  </label>
                  <div className="icon">
                    <img src="assets/images/icons/phone.svg" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <input type="Password" placeholder="Password" id="password" />
                  <label
                  //   for="password"
                  >
                    Password
                  </label>
                  <div className="icon">
                    <img src="assets/images/icons/lock.svg" alt="" />
                  </div>
                  <div className="show-password">
                    <a href="">
                      <img src="assets/images/icons/eye.svg" alt="" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="checkbox-wrap d-flex align-items-start">
                    <input
                      type="checkbox"
                      className="mt-1 me-1"
                      id="rememberme"
                      name="rememberme"
                      value="rememberme"
                    />
                    <label
                    //  for="rememberme"
                    >
                      I agree to the
                      <a href="" className="fw-semibold txt-primary">
                        terms of services
                      </a>
                      and the
                      <a href="" className="fw-semibold txt-primary">
                        privacy policy
                      </a>
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group mt-4 mb-3">
                <button onClick={createUser}>Create</button>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <span className="txt-secondary fs-14">
                  Already have an account?
                  <a className="fw-semibold txt-primary m-1" onClick={login}>
                    Sign in
                  </a>
                </span>
              </div>
            </div>
          </div>
        </main>
        <footer>
          <p>&copy; 2023 Medical Weight Loss by Healthogenics.</p>
        </footer>
      </div>
    </>
  );
};

export default UserDetails;
