import React from "react";
interface IHeader {
  currentStep: number;
  changeStep: any;
}

const Step6Header = (props: IHeader) => {
  const { currentStep, changeStep } = props;

  const changePageTo3 = () => {
    changeStep(currentStep - 3);
  };
  const changePageTo4 = () => {
    changeStep(currentStep - 2);
  };

  const changePageTo5 = () => {
    changeStep(currentStep - 1);
  };

  const changePageTo6 = () => {
    changeStep(currentStep);
  };

  return (
    <>
      <div className="login-pagination">
        <ul>
          <li className="active">
            <a>step 1</a>
          </li>
          <li className="active">
            <a>step 2</a>
          </li>
          <li className="active">
            <a
            // onClick={changePageTo3}
            >
              step 3
            </a>
          </li>
          <li className="active">
            <a
            // onClick={changePageTo4}
            >
              step 4
            </a>
          </li>
          <li className="active">
            <a
            // onClick={changePageTo5}
            >
              step 5
            </a>
          </li>
          <li className="active">
            <a onClick={changePageTo6}>step 6</a>
          </li>
        </ul>
        <p className="text-center">
          Step <span className="fw-bold">{currentStep} of 6</span>
        </p>
      </div>
    </>
  );
};

export default Step6Header;
