import React, { useEffect, useState } from "react";
import FloatingLabelTextInput from "../../../common-components/floatingLabelTextInput";
import { useNavigate } from "react-router-dom";
import Joi from "joi";
import Modal from "react-modal";
import { validateFields } from "../../../helper/joi-validation";
import {
  IResetPasswordAdmin,
  IResetPasswordAdminDisplay,
} from "../../../models/admin/userList";
import { alertConstants } from "../../../helper/sweet-alert";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { changePassword } from "../../../actions/loginActionCreator";
import { resetPasswordUserList } from "../../../actions/adminUserListActionCreator";

const AdminResetPassword = (props: IResetPasswordAdminDisplay) => {
  const navigate = useNavigate();
  const { forgotPasswordData, resetPasswordUserList, onClose, isProfileVisible } =
    props;
  const [errors, setErrors] = useState<any>(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState<any>(undefined);
  let userMail = localStorage.getItem("userMail");
  let forgotPasswordToken: any = localStorage.getItem("forgotPasswordToken");
  const [disableButton, setDisableButton] = useState(false);
  const [data, setData] = useState<IResetPasswordAdmin>({
    id: 0,
    email: userMail,
    password: "",
    confirmPassword: "",
  });
  const schema = Joi.object().keys({
    password: Joi.string()
      .min(8) // Minimum length of 8 characters
      .pattern(/^(?=.*[A-Z])[A-Za-z\d~!@#$%^&*()_\-+=\\|}\][{';":\/?.><`,]+$/)
      .label(
        "Password must contain minimum 8 characters and must contain atleast an uppercase letter"
      ),
    email: Joi.allow(),
    confirmPassword: Joi.string()
      .min(8) // Minimum length of 8 characters
      .pattern(/^(?=.*[A-Z])[A-Za-z\d~!@#$%^&*()_\-+=\\|}\][{';":\/?.><`,]+$/)
      .label(
        "Password must contain minimum 8 characters and must contain atleast an uppercase letter"
      ),
  });

  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    errors && delete errors[event.target.name];
    const _requestDetails = {
      ...data,
      [event.target.name]: event.target.value,
    };
    setData(_requestDetails);

    // data.confirmPassword&&data.password?(const incorrectPassword):null
  };

  const handleLogin = async () => {
    setDisableButton(true);
    const validationErrors = validateFields(data, schema);
    if (validationErrors == undefined) {
      await resetPasswordUserList(
        userMail,
        data.confirmPassword,
        callback
      );
    } else {
      setErrors(validationErrors);
      setDisableButton(false);
    }
  };

  const callback = (response: boolean) => {
    if (response==true) {
      updatedSuccessfully();
    } else if (response==false) {
      failed();
    } else {
      somethingWentWrong();
    }
  };

  const updatedSuccessfully = () => {
    toast.success("Password updated successfully!!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        onClose()
      },
    });
  };

  const failed = () => {
    toast.error("Password update failed", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light", 
      onClose: () => {
        onClose()
      },

    });
  };

  const somethingWentWrong = () => {
    toast.error("Something Went Wrong!!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        onClose()
      },
    });
  };


  return (
    <>
      <Modal isOpen={isProfileVisible} className="modal fade">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5">Reset Password</h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  const _reloadData = {
                    ...data,
                    password: "",
                    confirmPassword: "",
                  };
                  setData(_reloadData);
                  onClose();
                  // document.body.classList.remove("flow-hidden");
                }}
              ></button>
            </div>
            <div className="modal-body">
              <p className="mb-2">
                Reset the password for the user <strong>{userMail}</strong>
              </p>
              <div className="form-group">
                <FloatingLabelTextInput
                  label={"Password"}
                  onChange={onChange}
                  isMandatory={true}
                  value={data.password}
                  name={"password"}
                  placeholder={"Password"}
                  type={`${showPassword ? "text" : "password"}`}
                />
                {errors && errors.password && (
                  <div className="errors">{errors.password}</div>
                )}
                <div className="icon">
                  <img src="assets/images/icons/lock.svg" alt="" />
                </div>
                <div className="show-password" onClick={() => setShowPassword(!showPassword)}>
                  <a>
                    {showPassword==true?(<img
                    src="assets/images/icons/eye.svg"
                    className="password-show"
                    alt=""
                  />):(<img
                    src="assets/images/icons/eye-off.svg"
                    className="password-hide"
                    alt=""
                  />)}
                  
                  
                  </a>
                </div>
              </div>

              <div className="form-group">
                <FloatingLabelTextInput
                  label={"Confirm Password"}
                  onChange={onChange}
                  isMandatory={true}
                  value={data.confirmPassword}
                  name={"confirmPassword"}
                  placeholder={"Password"}
                  type={`${showConfirmPassword ? "text" : "password"}`}
                />
                {errors && errors.password && (
                  <div className="errors">{errors.password}</div>
                )}
                <div className="icon">
                  <img src="assets/images/icons/lock.svg" alt="" />
                </div>
                <div className="show-password" onClick={() =>
               setShowConfirmPassword(!showConfirmPassword)
                      }>         
                  <a>
                  {showConfirmPassword==true?(<img
                    src="assets/images/icons/eye.svg"
                    className="password-show"
                    alt=""
                  />):(<img
                    src="assets/images/icons/eye-off.svg"
                    className="password-hide"
                    alt=""
                  />)}
                   
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="modal-footer">
              <button type="button" className="btn btn-primary">
                Save Changes
              </button>
            </div> */}
            {data.password != "" &&
            data.confirmPassword != "" &&
            data.password != data.confirmPassword ? (
              <div className="errorsConfirmPassword">
                Password does not match
              </div>
            ) : null}
            {data.password != "" &&
            data.confirmPassword != "" &&
            data.password == data.confirmPassword &&
            disableButton == false ? (
              <div className="modal-footer">
                <button onClick={handleLogin} className="btn btn-primary">
                  Reset Password
                </button>
              </div>
            ) : (
              <div className="modal-footer">
                <button
                  onClick={handleLogin}
                  disabled
                  className="btn btn-primary"

                  // onClick={register}
                >
                  Reset Password
                </button>
              </div>
            )}{" "}
          </div>
        </div>
      </Modal>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

const mapDispatchToProps = {
  // changePassword: (
  //   email: string,
  //   password: string,
  //   forgotpasswordtoken: string | (string | null)[] | null,
  //   callback: Function
  // ) => changePassword(email, password, forgotpasswordtoken, callback),
  resetPasswordUserList: (
    email: string,
    password: string,
    callback: Function
  ) => resetPasswordUserList(email, password, callback),

};

export default connect(null, mapDispatchToProps)(AdminResetPassword);
