import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FloatingLabelTextInput from "../../../common-components/floatingLabelTextInput";
import FloatingLabelDropDown from "../../../common-components/floatingLabelDropdown";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "react-toastify/dist/ReactToastify.css";
import { validateFields } from "../../../helper/joi-validation";
import ButtonSpinner from "../../../common-components/buttonSpinner";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Joi from "joi";
import { connect } from "react-redux";
import { getGender } from "../../../actions/common/commonActionCreator";
import {
  IBodyCompositionForOthersDisplay,
  IFinalDataForOthers,
  IGetBarcode,
  IHeight,
} from "../../../models/barcodeRegister";
import { ROUTER_URL } from "../../../constants/routerUrl";
import {
  registerForOthers,
  registerKitForSelf,
} from "../../../actions/registerKitActionCreator";
import { IData } from "../../../models/registeration";

const BodyCompositionForOthers = (props: IBodyCompositionForOthersDisplay) => {
  const navigate = useNavigate();
  const { getGender, gender, registerKitForSelf, currentStep, changeStep } =
    props;
  const [isLoading, showLoading] = useState(false);
  const [errors, setErrors] = useState<any>(undefined);
  const [disableButton, setDisableButton] = useState(false);
  const [sendRequest, setSendRequest] = useState(false);
  let barcodeData: any = localStorage.getItem("barcode_data");
  const barcodeDataParsed: any = JSON.parse(barcodeData);
  const [isBarcode, setBarcodeData] = useState<IGetBarcode>({
    barcode: barcodeDataParsed && barcodeDataParsed.barcode,
    name: barcodeDataParsed && barcodeDataParsed.personName,
  });

  //fetching data from local Storage
  let savedData: any = localStorage.getItem("savedDataForOthers");
  const step5data: any = JSON.parse(savedData);
  const [data, setData] = useState(step5data);
  const schema = Joi.object().keys({
    gender: Joi.number().min(1).required().label("Enter valid gender"),
    dob: Joi.date()
      .max(moment().toDate().toString())
      .required()
      .label("Enter valid dob"),
    weight: Joi.number().min(1).required().label("Enter valid weight"),
    focusOn: Joi.array().min(1).required().label("Select atleast one option"),
    preCondition: Joi.array()
      .min(1)
      .required()
      .label("Select atleast one option"),
    focusOnName: Joi.allow(),
    height: Joi.allow(),
    //trial
    feet: Joi.number().min(1).max(9).required().label("Enter valid feet"),
    inch: Joi.number().min(1).max(12).required().label("Enter valid inch"),
  });

  const [height, setHeight] = useState<IHeight>({
    inch: 0,
    feet: 0,
  });

  const feetValues = Array.from({ length: 8 }, (_, index) => index + 1);
  const inchValues = Array.from({ length: 11 }, (_, index) => index + 1);

  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    errors && delete errors[event.target.name];
    const _data = {
      ...data,
      [event.target.name]: event.target.value,
    };
    setData(_data);
  };

  const onChangeHeight = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    errors && delete errors[event.target.name];
    const _height = {
      ...height,
      [event.target.name]: event.target.value,
    };
    setHeight(_height);
  };

  useEffect(() => {
    getGender();
  }, []);

  const saveRequest = async () => {
    const isSelfregistered = false;
    var savedData: any = {
      ...data,
      gender: Number(data.gender),
      dob: moment(data.dob).format("YYYY-MM-DD").toString(),
      weight: Number(data.weight),
      height: data.feet.toString() + " Feet " + data.inch.toString() + " Inch ",
    };
    localStorage.setItem("savedDataForOthers", JSON.stringify(savedData));
    await registerKitForSelf(
      isSelfregistered,
      isBarcode.name,
      isBarcode.barcode,
      savedData,
      callback
    );
  };

  const callback = (response: any) => {
    if (response) {
      setDisableButton(true);
      UserSuccessfullyRegistered();
      // navigate(ROUTER_URL.LOGIN);
      // changeStep(props.currentStep + 1);

      // if (isContinue) {
      //   isContinue = false;
      //   props.changeStep(props.currentStep + 1);
      // } else {
      //   setMessage(language.profileUpdatedSuccessfully);
      //   setSuccessCode(1);
      // }
    } else {
      showToastMessage();
      // changeStep(props.currentStep + 1);
    }
  };

  const onSubmit = async () => {
    const validationErrors = validateFields(data, schema);
    if (validationErrors == undefined) {
      setSendRequest(true);
      saveRequest();
    } else {
      setErrors(validationErrors);
    }
  };

  // const dobChange = (event: any) => {
  //   setData({
  //     ...data,
  //     dOB: new Date(moment(event).format("YYYY-MM-DD")),
  //   });
  // };

  const showToastMessage = () => {
    toast.error("Something went wrong!!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const UserSuccessfullyRegistered = () => {
    toast.success("User successfully Registered!!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        navigate(ROUTER_URL.DASHBOARD);
      },
    });
  };
  const dobChange = (event: any) => {
    setData({
      ...data,
      dob: new Date(moment(event).format("MM-DD-YYYY")),
    });
    //validateFields();
  };

  return (
    <>
      <div className="login-card">
        <div className="title">
          <h3>Body Composition</h3>
          <p>
            Update <strong>{data.fullName}</strong>'s body compositions to help
            us understand more
          </p>
        </div>
        <div className="row">
          <div className="col-md-12 mb-2">
            <h4 className="text-center mb-3">How tall are you?</h4>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group pad-2">
                  {/* <FloatingLabelTextInput
                    label={"Feet"}
                    onChange={onChangeHeight}
                    isMandatory={true}
                    value={height.feet}
                    name={"feet"}
                    placeholder={"Feet"}
                    minValue={1}
                    maxLength={9}
                  /> */}
                  <select
                    value={data.feet}
                    placeholder={"Feet"}
                    onChange={onChange}
                    name={"feet"}
                  >
                    <option value={0}> </option>
                    {feetValues.map((number) => (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="feet">Feet</label>
                  {errors && errors.feet && (
                    <div className="errors">{errors.feet}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group pad-2">
                  {/* <FloatingLabelTextInput
                    label={"Inch"}
                    onChange={onChangeHeight}
                    isMandatory={true}
                    value={height.inch}
                    name={"inch"}
                    placeholder={"Inch"}
                    minValue={1}
                    maxValue={9}
                  /> */}
                  <select
                    value={data.inch}
                    placeholder={"Inch"}
                    onChange={onChange}
                    name={"inch"}
                  >
                    <option value={0}> </option>
                    {inchValues.map((number) => (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="inch">Inch</label>
                  {errors && errors.inch && (
                    <div className="errors">{errors.inch}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mb-2">
            <h4 className="text-center mb-3">What is your current weight?</h4>
            <div className="form-group pad-right">
              <FloatingLabelTextInput
                label={"Weight"}
                onChange={onChange}
                isMandatory={true}
                value={data.weight}
                name={"weight"}
                placeholder={"Weight"}
              />
              {errors && errors.weight && (
                <div className="errors">{errors.weight}</div>
              )}

              <span className="highlights">lbs</span>
            </div>
          </div>

          <div className="col-md-12 mb-2">
            <h4 className="text-center mb-3">Few more details</h4>
            <div className="form-group pad-right">
              <DatePicker
                dateFormat={"MM-dd-yyyy"}
                placeholderText="mm-dd-yyyy"
                selected={new Date(data.dob)}
                className="date"
                name="dob"
                onChange={(date) => dobChange(date)}
                showMonthDropdown
                showYearDropdown
                maxDate={new Date()}
                dropdownMode="select"
              ></DatePicker>

              {errors && errors.dob && (
                <div className="errors">{errors.dob}</div>
              )}
              {/* <input className="date" type="date" placeholder="dob" id="dob" />
              <label htmlFor="dob">Date of Birth</label> */}
              <label htmlFor="dob" className="active">
                Date of Birth
              </label>
            </div>
            <div className="form-group pad-right">
              <FloatingLabelDropDown
                label={"Gender"}
                onChange={onChange}
                isMandatory={false}
                value={data.gender}
                name={"gender"}
                optionsList={gender}
                // placeholder="Purchased"
                // id="Purchased"
              />
              {/* <label htmlFor="gender">Gender</label> */}
            </div>
            {errors && errors.gender && (
              <div className="errors">{errors.gender}</div>
            )}
          </div>
          {disableButton === true ? (
            <div className="form-group mt-4 mb-3">
              <button onClick={() => onSubmit()} disabled>
                <ButtonSpinner isLoading={isLoading} />
                Finish
              </button>
            </div>
          ) : sendRequest === true ? (
            <div className="form-group mt-4 mb-3">
              <button disabled onClick={() => onSubmit()}>
                <ButtonSpinner isLoading={isLoading} />
                Finish
              </button>
            </div>
          ) : (
            <div className="form-group mt-4 mb-3">
              <button onClick={() => onSubmit()}>
                <ButtonSpinner isLoading={isLoading} />
                Finish
              </button>
            </div>
          )}
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

const mapDispatchToProps = {
  getGender: () => getGender(),
  registerKitForSelf: (
    isSelfregistered: boolean,
    name: string,
    barcode: string,
    data: IFinalDataForOthers,
    callback: any
  ) => registerKitForSelf(isSelfregistered, name, barcode, data, callback),
};

function mapStateToProps(state: any) {
  return {
    gender: state.commonDropdowns?.gender,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BodyCompositionForOthers);
