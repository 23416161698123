import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { ROUTER_URL } from "../../constants/routerUrl";
import FloatingLabelTextInput from "../../common-components/floatingLabelTextInput";
import { createLogin, resetPassword } from "../../actions/loginActionCreator";
import { ILogin, ILoginDisplay } from "../../models/login";
import Joi from "joi";
import { validateFields } from "../../helper/joi-validation";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../auth-config";
import { useCookies } from "react-cookie";
import { IForgotPassword } from "../../models/forgotPassword";
import { alertConstants } from "../../helper/sweet-alert";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const Login = (props: ILoginDisplay) => {
  const navigate = useNavigate();
  const { loginData, createLogin, resetPassword } = props;
  const [errors, setErrors] = useState<any>(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const [cookies, setCookie] = useCookies(["rememberMe"]);
  const [rememberMe, setRememberMe] = useState<any>(undefined);
  const [disable, setDisable] = useState(false);
  const [data, setData] = useState<ILogin>({
    id: 0,
    email: "",
    password: "",
  });

  const schema = Joi.object().keys({
    password: Joi.string()
      .min(8) // Minimum length of 8 characters
      .pattern(/^(?=.*[A-Z])[A-Za-z\d~!@#$%^&*()_\-+=\\|}\][{';":\/?.><`,]+$/)
      .label("Enter valid password"),
    email: Joi.string()
      .regex(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
      .required()
      .label("Enter valid email"),
  });

  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    errors && delete errors[event.target.name];
    const _requestDetails = {
      ...data,
      [event.target.name]: event.target.value,
    };
    setData(_requestDetails);
  };

  const register = () => {
    const validationErrors = validateFields(data, schema);
    if (validationErrors == undefined) {
      saveRequest();
    } else {
      setErrors(validationErrors);
    }
  };

  const { instance } = useMsal();

  const login_with_ad = () => {
    instance.loginRedirect(loginRequest);
  };

  const saveRequest = async () => {
    var metaData: ILogin = {
      email: data.email,
      password: data.password,
    };
    await createLogin(metaData, callback);
  };

  const callback = (response: any) => {
    if (alertConstants.success) {
      let role = localStorage.getItem("Role");
      if (role === "User") {
        navigate(ROUTER_URL.DASHBOARD);
      } else if (role === "Admin") {
        navigate(ROUTER_URL.ADMIN_TEST_PURCHASED);
      } else if (alertConstants.warning) {
        invalidData();
      } else if (alertConstants.errror) {
        somethingWentWrong();
      } else {
        somethingWentWrong();
      }
    } else if (alertConstants.warning) {
      invalidData();
    } else if (alertConstants.errror) {
      somethingWentWrong();
    } else {
      somethingWentWrong();
    }
  };

  const invalidData = () => {
    toast.error("Invalid email or password!!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        setDisable(false);
      },
    });
  };

  const somethingWentWrong = () => {
    toast.error("Something went Wrong!!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        setDisable(false);
      },
    });
  };

  const handleLogin = async () => {
    setDisable(true);
    if (data.email == "admin@gmail.com" && data.password == "admin2023") {
      navigate(ROUTER_URL.ADMIN_TEST_PURCHASED);
    } else {
      const validationErrors = validateFields(data, schema);
      if (validationErrors == undefined) {
        if (rememberMe) {
          let cookieData: ILogin = {
            id: data.id,
            email: data.email,
            password: data.password,
          };
          setCookie("rememberMe", cookieData, { maxAge: 7 * 24 * 60 * 60 }); // Set the cookie to expire in 7 days
        }
        saveRequest();
      } else {
        setDisable(false);
        setErrors(validationErrors);
      }
    }
  };

  const alreadyRegistered = () => {
    navigate(ROUTER_URL.USER_REGISTER);
  };

  useEffect(() => {
    // createLogin(data, callback);
  }, []);

  const forgetPassword = () => {
    navigate(ROUTER_URL.EMAIL_FOR_FORGOT_PASSWORD);
    // if (data.email === "") {
    //   pleaseEnterYourEmail();
    // } else {
    //   resetPassword(data.email, inReturn);
    // }
  };

  // const inReturn = (response: any) => {
  //   if (alertConstants.success) {
  //     let forgotPasswordToken = localStorage.getItem("forgotPasswordToken");
  //     if (forgotPasswordToken !== null) {
  //       pleaseCheckYourEmail();
  //     }
  //   } else if (alertConstants.errror) {
  //     somethingWentWrong();
  //   } else {
  //     somethingWentWrong();
  //   }
  // };

  const pleaseEnterYourEmail = () => {
    toast.error("Please enter your Email!!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const pleaseCheckYourEmail = () => {
    toast.success("Please check your Email to reset password!!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const onChangeMail = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    debugger;
    errors && delete errors[event.target.name];
    //Remove Unicode control characters and invisible characters
    var trimmedEmail = event.target.value.replace(
      /[\p{C}|\p{M}|\p{Z}|\p{S}]/gu,
      ""
    );
    const _data = {
      ...data,
      //spaceRemoval
      [event.target.name]: trimmedEmail.replace(/\s/g, "").trim(),
    };
    setData(_data);
  };

  return (
    <>
      <div className="login-wrap">
        <header>
          <a
            href="#"
            draggable="false"
            style={{ pointerEvents: "none" }}
            onDragStart={(e) => e.preventDefault()}
          >
            <img src="assets/images/logo.webp" alt="" draggable="false" />
          </a>
        </header>
        <main>
          <div className="login-card">
            <div className="title">
              <h3>Sign In</h3>
            </div>
            <div className="form-group">
              <FloatingLabelTextInput
                label={"Email"}
                onChange={onChangeMail}
                isMandatory={true}
                value={data.email.trim()}
                name={"email"}
                placeholder={"Email"}
              />
              {errors && errors.email && (
                <div className="errors">{errors.email}</div>
              )}

              <div className="icon">
                <img src="assets/images/icons/email.svg" alt="" />
              </div>
            </div>
            <div className="form-group">
              <FloatingLabelTextInput
                label={"Password"}
                onChange={onChange}
                isMandatory={true}
                value={data.password}
                name={"password"}
                placeholder={"Password"}
                type={`${showPassword ? "text" : "password"}`}
              />
              {errors && errors.password && (
                <div className="errors">{errors.password}</div>
              )}
              <div className="icon">
                <img src="assets/images/icons/lock.svg" alt="" />
              </div>
              <div
                className="show-password"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword == true ? (
                  <img
                    src="assets/images/icons/eye.svg"
                    className="password-show"
                    alt=""
                  />
                ) : (
                  <img
                    src="assets/images/icons/eye-off.svg"
                    className="password-hide"
                    alt=""
                  />
                )}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              {/* <div className="checkbox-wrap d-flex align-items-start">
                <input
                  type="checkbox"
                  className="mt-1 me-1"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  // checked={rememberMe}
                  // onChange={(e) => setRememberMe(e.target.checked)}
                  // value={data.rememberMe}
                />
                <label
                // for="rememberme"
                >
                  Remeber Me
                </label>
              </div> */}
              <div className="forgot">
                <a
                  href="#"
                  onClick={forgetPassword}
                  className="fw-semibold txt-primary"
                >
                  {" "}
                  Forgot Password?
                </a>
              </div>
            </div>
            <div className="form-group mt-4 mb-3">
              {disable === true ? (
                <button
                  onClick={handleLogin}
                  disabled
                  // onClick={register}
                >
                  Login
                </button>
              ) : (
                <button
                  onClick={handleLogin}

                  // onClick={register}
                >
                  Login
                </button>
              )}
            </div>

            {/* <div className="form-group mt-4 mb-3">
              <button onClick={login_with_ad}>Login with AD</button>
            </div> */}

            <div className="d-flex align-items-center justify-content-center">
              <span className="txt-secondary fs-14">
                Don't have an account?
                <a
                  href="#"
                  className="fw-semibold txt-primary m-1"
                  onClick={alreadyRegistered}
                >
                  Register here
                </a>
              </span>
            </div>
          </div>
        </main>
        <footer>
          <p>@2023 Healthogenics</p>
        </footer>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

const mapDispatchToProps = {
  createLogin: (data: ILogin, callback: Function) =>
    createLogin(data, callback),
  resetPassword: (email: string, callback: Function) =>
    resetPassword(email, callback),
};

export default connect(null, mapDispatchToProps)(Login);
