import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Joi from "joi";
import FloatingLabelTextInput from "../../../common-components/floatingLabelTextInput";
import {
  ICommon,
  IFinalData,
  IStep4Display,
} from "../../../models/registeration";
import { getFocuses } from "../../../actions/common/commonActionCreator";
import { validateFields } from "../../../helper/joi-validation";
import { event } from "jquery";

const Step4 = (props: IStep4Display) => {
  const navigate = useNavigate();
  const {
    getFocuses,
    focuses,
    passData,
    currentStep,
    changeStep,
    oldData,
    newData,
    step3data,
    setMainData,
  } = props;

  const [errors, setErrors] = useState<any>(undefined);
  const [data, setData] = useState(step3data);
  const [items, setItems] = useState<any>([]);
  const [numbers, setNumbers] = useState([]);
  const [specifyOtherField, setSpecifyOtherField] = useState(false);
  const [removeName, setRemoveName] = useState();
  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    errors && delete errors[event.target.name];
    const _data = {
      ...data,
      [event.target.name]: event.target.value,
    };
    setRemoveName(data.focusOnName);
    setData(_data);
    
  };

  const schema = Joi.object().keys({
    firstName: Joi.string()
      .regex(/^[A-Za-z'\- ]+$/)
      .required()
      .label("Enter valid name"),
    isAgreeTerms: Joi.allow(),
    lastName: Joi.string()
      .regex(/^[A-Za-z'\- ]+$/)
      .required()
      .label("Enter valid name"),
    password: Joi.string()
      .min(8) // Minimum length of 8 characters
      .pattern(/^(?=.*[A-Z])[A-Za-z\d~!@#$%^&*()_\-+=\\|}\][{';":\/?.><`,]+$/)
      .label(
        "Password must contain minimum 8 characters and must contain atleast an uppercase letter"
      ),
    email: Joi.string().required().label("Enter valid email"),
    mobileNo: Joi.string()
      .length(10)
      .pattern(/^[0-9]+$/)
      .required()
      .label("Enter valid mobile number"),
    gender: Joi.allow(),
    dob: Joi.allow(),
    weight: Joi.allow(),
    focusOn: Joi.array().min(1).required().label("Select atleast one option"),
    preCondition: Joi.allow(),
//   focusOnName: Joi.when('focusOn', {
//     is: Joi.array().items(Joi.number().valid(6)).required(),
//     then: Joi.string().required().label("Please fill in the field"),
//     otherwise: Joi.string().allow('').optional()
// }),
focusOnName: Joi.when('focusOn', {
  is: Joi.array().items(Joi.number().valid(6)).required(),
  then: Joi.string().required().label("Please fill in the field"),
  otherwise: Joi.string().allow('').optional()
}),
    feet: Joi.allow(),
    inch: Joi.allow(),
    height: Joi.allow(),
  });

  useEffect(() => {
    getFocuses();
  }, []);
  const saveRequest = async () => {
    let step4Out = data;
    setMainData({
      ...passData,
      focusOn: data.focusOn,
      focusOnName: data.focusOnName,
    });
    props.sendDataToParent(step4Out);
    await changeStep(currentStep + 1);
  };

  const onSubmit = async () => {
    data.focusOn = items;
    setData({ ...data, focusOn: [...items] });
    const validationErrors = validateFields(data, schema);
    if (validationErrors == undefined&&((items.includes(6) && data.focusOnName!=="")||(!items.includes(6)&&data.focusOnName==""))) {
      saveRequest();
    } else {
      setErrors(validationErrors);
    }
  };
  const emptyString = () => {};
  const addFoccus = (id: any) => {
    if (id !== 7) {
      if (items.includes(id)) {
        setItems(
          //items.filter((it: number) => it != 7).filter((it: number) => it != id)
          items.filter((it: number) => it !== 7 && it !== id)
        );
      } else {
        setItems([...items.filter((it: number) => it != 7), id]);
      }
      if (id == 6) {
        if (items.includes(6)) {
          setSpecifyOtherField(false);
          const _data = {
            ...data,
            focusOnName:"",
          };
          setData(_data);
        } else {
          setSpecifyOtherField(true);
        }
      } else {
        !items.includes(6) && setSpecifyOtherField(false);
      }
    } else {
      setItems([7]);
      setSpecifyOtherField(false);
      const _data = {
        ...data,
        focusOnName: "",
      };
      setData(_data);
    }
  };

  useEffect(() => {
    setData({ ...passData });
  }, []);

  return (
    <>
      <div className="login-card">
        <div className="title">
          <h3>Tell us more</h3>

          <p>Which of the following would you like to focus on?</p>
        </div>
        <div className="row">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            {focuses?.map((element: ICommon, index: number) => {
              return (
                <>
                  <div className="checkbox-round">
                    <input
                      type="checkbox"
                      // id="multi-check1"
                      name="id"
                      checked={items && items.includes(element.id)}
                      //value={data.focusOn}
                      // onChange={() => onChange}

                      onClick={(e) => addFoccus(element.id)}
                    />
                    <label htmlFor="multi-check1">{element.name}</label>
                  </div>
                  {element.id && element.id === 6
                    ? specifyOtherField && (
                        // <div id="others-textbox" style={{ display: "none" }}>
                        <div className="w-100">
                          <div className="form-group pad-2">
                            <FloatingLabelTextInput
                              label={"Specify other"}
                              onChange={onChange}
                              // onChange={handleRadioButtonChange}
                              isMandatory={false}
                              value={
                                data.focusOnName
                                // data.focusOnName !== null
                                //   ? data.focusOnName
                                //   : ""
                              }
                              name={"focusOnName"}
                            />
                            {errors && errors.focusOnName &&(
                              <div className="errors">{errors.focusOnName}</div>
                            )}
                          </div>
                        </div>
                      ) // </div>
                    : null}
                </>
              );
            })}
          </div>
          {errors && errors.focusOn && (
            <div className="errors">{errors.focusOn}</div>
          )}

          <div className="form-group mt-4 mb-3">
            <button onClick={() => onSubmit()}>Next</button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  //   createLogin: (data: ILogin, callback: Function) =>
  //     createLogin(data, callback),
  getFocuses: () => getFocuses(),
};

function mapStateToProps(state: any) {
  return {
    focuses: state.commonDropdowns?.focuses,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Step4);
