import React, { useEffect, useState } from "react";
import FloatingLabelTextInput from "../../../../../common-components/floatingLabelTextInput";
import Joi from "joi";
import {
  IBarcodeDataToState,
  IBarcodeRegister,
  IBarcodeRegisterDisplay,
} from "../../../../../models/barcodeRegister";
import { validateFields } from "../../../../../helper/joi-validation";
import { connect } from "react-redux";
import { registerBarcode } from "../../../../../actions/registerKitActionCreator";
import { alertConstants } from "../../../../../helper/sweet-alert";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ROUTER_URL } from "../../../../../constants/routerUrl";
import { getFullProfile } from "../../../../../actions/profileActionCreator";

const BarcodeRegister = (props: IBarcodeRegisterDisplay) => {
  const navigate = useNavigate();

  const { registerBarcode, getFullProfile, fullProfileData } = props;
  const [errors, setErrors] = useState<any>(undefined);
  const [sendRequest, setSendRequest] = useState(false);
  const [data, setData] = useState<IBarcodeRegister>({
    userId: 0,
    barcode: "",
  });

  const [barcodeData, setBarcodeData] = useState<IBarcodeDataToState>({
    userId: 0,
    barcode: "",
    personName: "",
  });

  const schema = Joi.object().keys({
    barcode: Joi.string().required().label("Enter valid barcode"),
    // barcode: Joi.string()
    //   .regex(/^\d{10}$/)
    //   .label("Enter 10 digit number"),
  });

  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    errors && delete errors[event.target.name];
    const _data = {
      ...data,
      [event.target.name]: event.target.value,
    };
    setData(_data);
  };

  const saveRequest = async () => {
    var barcodeData: IBarcodeRegister = {
      userId: fullProfileData && fullProfileData.id ? fullProfileData.id : 0,
      barcode: data.barcode.trim(),
    };
    var metaData: IBarcodeDataToState = {
      userId: fullProfileData && fullProfileData.id ? fullProfileData.id : 0,
      barcode: data.barcode.trim(),
      personName: "",
    };

    localStorage.setItem(
      "barcode_data",
      JSON.stringify(data.barcode !== null ? metaData : "")
    );
    setSendRequest(true);
    await registerBarcode(barcodeData, callback);
  };

  const callback = (response: any) => {
    if (response === "Barcode registered successfully!") {
      navigate(ROUTER_URL.KIT_REGISTER_FOR_SELF);
    } else if (response === "Barcode is Already registered!") {
      barcodeIsAlreadyRegistered();
      setSendRequest(false);
    } else if (response === "Invalid Barcode Number!") {
      setSendRequest(false);
      invalidBarcode();
    } else if (response === "You haven't purchased any kits to register!") {
      youHaventPurchasedAnyKits();
      setSendRequest(false);
    } else if (response === "No associated line items!") {
      noAssociatedLineItems();
      setSendRequest(false);
    } else if (
      response === "Test with this barcode has already been registered!"
    ) {
      alertMessage(response);
      setSendRequest(false);
    } else if (alertConstants.failed) {
      somethingWentWrong();
      setSendRequest(false);
    } else {
      somethingWentWrong();
      setSendRequest(false);
    }
  };

  const barcodeIsAlreadyRegistered = () => {
    toast.error("Barcode is Already registered!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const invalidBarcode = () => {
    toast.error("Invalid barcode", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const youHaventPurchasedAnyKits = () => {
    toast.error("You haven't purchased any kits to register!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const noAssociatedLineItems = () => {
    toast.error("Issue in registering test. Please contact admin.", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const somethingWentWrong = () => {
    toast.error("Something went wrong!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const alertMessage = (message: string) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const onSubmit = async () => {
    setSendRequest(true);
    const validationErrors = validateFields(data, schema);

    if (validationErrors == undefined) {
      saveRequest();
    } else {
      setErrors(validationErrors);
      setSendRequest(false);
    }
  };

  useEffect(() => {
    getFullProfile();
  }, []);

  return (
    <>
      <div className="login-wrap">
        <main>
          <div className="login-card">
            <div className="logo">
              <img src="assets/images/logo.webp" alt="" draggable="false" />
            </div>
            <div className="title">
              <h3>Register Your Test</h3>
              <p>Please enter your barcode number</p>
            </div>
            <div className="form-group">
              <FloatingLabelTextInput
                label={"Barcode"}
                onChange={onChange}
                isMandatory={true}
                value={data.barcode.trim()}
                name={"barcode"}
                placeholder={"Barcode"}
              />
              {errors && errors.barcode && (
                <div className="errors">{errors.barcode}</div>
              )}
              {/* <input type="text" placeholder="Email" id="email" />
              <label htmlFor="email">Barcode</label> */}
              <div className="icon">
                <img
                  src="assets/images/icons/barcode.png"
                  alt=""
                  className="barcode"
                />
              </div>
            </div>
            {sendRequest === true ? (
              <div className="form-group mt-4 mb-3">
                <button disabled onClick={() => onSubmit()}>
                  Continue
                </button>
              </div>
            ) : (
              <div className="form-group mt-4 mb-3">
                <button onClick={() => onSubmit()}>Continue</button>
              </div>
            )}
          </div>
        </main>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

const mapDispatchToProps = {
  registerBarcode: (data: IBarcodeRegister, callback: Function) =>
    registerBarcode(data, callback),
  getFullProfile: () => getFullProfile(),
};

function mapStateToProps(state: any) {
  return {
    fullProfileData: state.profile?.fullProfileData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BarcodeRegister);
