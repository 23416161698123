import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { ROUTER_URL } from "../../constants/routerUrl";
import FloatingLabelTextInput from "../../common-components/floatingLabelTextInput";
import { changePassword, createLogin } from "../../actions/loginActionCreator";
import { ILogin, ILoginDisplay } from "../../models/login";
import Joi from "joi";
import { validateFields } from "../../helper/joi-validation";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../auth-config";
import { useCookies } from "react-cookie";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import {
  IForgotPassword,
  IForgotPasswordDisplay,
} from "../../models/forgotPassword";
import queryString from "query-string";
import { alertConstants } from "../../helper/sweet-alert";

const ForgotPassword = (props: IForgotPasswordDisplay) => {
  const navigate = useNavigate();
  let queries = queryString && queryString.parse(window.location.search);
  const { forgotPasswordData, changePassword } = props;
  const [errors, setErrors] = useState<any>(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [cookies, setCookie] = useCookies(["rememberMe"]);
  const [rememberMe, setRememberMe] = useState<any>(undefined);
  const [data, setData] = useState<IForgotPassword>({
    id: 0,
    email: queries.email ? queries.email : "",
    password: "",
    confirmPassword: "",
  });
  const code = queries.code;

  const schema = Joi.object().keys({
    password: Joi.string()
      .min(8) // Minimum length of 8 characters
      .pattern(/^(?=.*[A-Z])[A-Za-z\d~!#$%^&*()_\-+=\\|}\][{';":\/?.><`,@]+$/)
      .label(
        "Password must contain minimum 8 characters and must contain atleast an uppercase letter"
      ),
    email: Joi.allow(),
    confirmPassword: Joi.string()
      .min(8) // Minimum length of 8 characters
      .pattern(/^(?=.*[A-Z])[A-Za-z\d~!#$%^&*()_\-+=\\|}\][{';":\/?.><`,@]+$/),
  });

  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    errors && delete errors[event.target.name];
    const _requestDetails = {
      ...data,
      [event.target.name]: event.target.value,
    };
    setData(_requestDetails);

    // data.confirmPassword&&data.password?(const incorrectPassword):null
  };

  const onChangeMail = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    debugger;
    errors && delete errors[event.target.name];
    //Remove Unicode control characters and invisible characters
    var trimmedEmail = event.target.value.replace(
      /[\p{C}|\p{M}|\p{Z}|\p{S}]/gu,
      ""
    );
    const _data = {
      ...data,
      //spaceRemoval
      [event.target.name]: trimmedEmail.replace(/\s/g, "").trim(),
    };
    setData(_data);
  };

  const onChangeEmail = (key: any) => {
    const _requestDetails = {
      ...data,
      email: key,
    };
    setData(_requestDetails);
  };
  const invalidEmail = () => {
    toast.error("Invalid email!!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const register = () => {
    const validationErrors = validateFields(data, schema);
    if (validationErrors == undefined) {
      saveRequest();
    } else {
      setErrors(validationErrors);
    }
  };

  const updatedSuccessfully = () => {
    toast.success("Password Updated successfully!!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        navigate(ROUTER_URL.LOGIN);
      },
    });
  };

  const failed = () => {
    toast.error("Password update failed", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        setDisableButton(false);
      },
    });
  };

  const somethingWentWrong = () => {
    toast.error("Something Went Wrong!!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        setDisableButton(false);
      },
    });
  };

  const saveRequest = async () => {
    // var metaData = {
    //   email: data.email,
    //   password: data.password,
    // };
  };

  const callback = (status: any) => {
    if (status === "success") {
      setDisableButton(true);
      updatedSuccessfully();
    } else if (status === "error") {
      failed();
      setDisableButton(false);
    } else {
      somethingWentWrong();
      setDisableButton(false);
    }
  };
  const handleLogin = async () => {
    setDisableButton(true);
    const validationErrors = validateFields(data, schema);
    if (validationErrors == undefined) {
      await changePassword(data.email, data.password, queries.code, callback);
    } else {
      setErrors(validationErrors);
      setDisableButton(false);
    }
  };
  // const onEmailValid = (
  //   event:
  //     | React.ChangeEvent<HTMLInputElement>
  //     | React.ChangeEvent<HTMLSelectElement>
  //     | React.ChangeEvent<HTMLSelectElement>
  //     | React.ChangeEvent<HTMLSelectElement>
  //     | React.ChangeEvent<HTMLTextAreaElement>
  // ) => {
  //   if (queries.email == data.email) {
  //     const _requestDetails = {
  //       ...data,
  //       [event.target.name]: event.target.value,
  //     };
  //     setData(_requestDetails);
  //   }
  // };

  useEffect(() => {
    // createLogin(data, callback);
  }, []);

  return (
    <>
      <div className="login-wrap">
        <header>
          <a
            href="#"
            draggable="false"
            style={{ pointerEvents: "none" }}
            onDragStart={(e) => e.preventDefault()}
          >
            <img src="assets/images/logo.webp" alt="" draggable="false" />
          </a>
        </header>
        <main>
          <div className="login-card">
            <div className="title">
              <h3>Reset Password</h3>
            </div>
            <div className="form-group">
              <FloatingLabelTextInput
                label={"Email"}
                onChange={onChangeMail}
                isMandatory={true}
                value={queries.email}
                name={"email"}
                placeholder={"Email"}
                disable
              />
              {errors && errors.email && (
                <div className="errors">{errors.email}</div>
              )}
              <div className="icon">
                <img src="assets/images/icons/email.svg" alt="" />
              </div>
            </div>
            <div className="form-group">
              <FloatingLabelTextInput
                label={"Password"}
                onChange={onChange}
                isMandatory={true}
                value={data.password}
                name={"password"}
                placeholder={"Password"}
                type={`${showPassword ? "text" : "password"}`}
              />
              {errors && errors.password && (
                <div className="errors">{errors.password}</div>
              )}
              <div className="icon">
                <img src="assets/images/icons/lock.svg" alt="" />
              </div>
              <div
                className="show-password"
                onClick={() => setShowPassword(!showPassword)}
              >
                <a>
                  {showPassword == true ? (
                    <img
                      src="assets/images/icons/eye.svg"
                      className="password-show"
                      alt=""
                    />
                  ) : (
                    <img
                      src="assets/images/icons/eye-off.svg"
                      className="password-hide"
                      alt=""
                    />
                  )}
                </a>
              </div>
            </div>
            <div className="form-group">
              <FloatingLabelTextInput
                label={"Confirm Password"}
                onChange={onChange}
                isMandatory={true}
                value={data.confirmPassword}
                name={"confirmPassword"}
                placeholder={"Password"}
                type={`${showConfirmPassword ? "text" : "password"}`}
              />
              {/* {errors && errors.password && (
                <div className="errors">{errors.password}</div>
              )} */}
              <div className="icon">
                <img src="assets/images/icons/lock.svg" alt="" />
              </div>
              <div
                className="show-password"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                <a>
                  {showConfirmPassword == true ? (
                    <img
                      src="assets/images/icons/eye.svg"
                      className="password-show"
                      alt=""
                    />
                  ) : (
                    <img
                      src="assets/images/icons/eye-off.svg"
                      className="password-hide"
                      alt=""
                    />
                  )}
                </a>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between"></div>
            {data.password != "" &&
            data.confirmPassword != "" &&
            data.password != data.confirmPassword ? (
              <div className="errors">Password does not match</div>
            ) : null}
            {data.password != "" &&
            data.confirmPassword != "" &&
            data.password == data.confirmPassword &&
            disableButton == false ? (
              <div className="form-group mt-4 mb-3">
                <button onClick={handleLogin}>Reset Password</button>
              </div>
            ) : (
              <div className="form-group mt-4 mb-3">
                <button
                  onClick={handleLogin}
                  disabled

                  // onClick={register}
                >
                  Reset Password
                </button>
              </div>
            )}{" "}
            {/* <div className="form-group mt-4 mb-3">
              <button onClick={login_with_ad}>Login with AD</button>
            </div> */}
          </div>
        </main>
        <footer>
          <p>@ 2023 Healthogenics</p>
        </footer>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

const mapDispatchToProps = {
  changePassword: (
    email: string,
    password: string,
    forgotpasswordtoken: string | (string | null)[] | null,
    callback: Function
  ) => changePassword(email, password, forgotpasswordtoken, callback),
};

export default connect(null, mapDispatchToProps)(ForgotPassword);
