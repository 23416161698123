import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { ROUTER_URL } from "../../constants/routerUrl";
import FloatingLabelTextInput from "../../common-components/floatingLabelTextInput";
import { createLogin, resetPassword } from "../../actions/loginActionCreator";
import { IEmail, IEmailForForgotPasswordDisplay } from "../../models/login";
import Joi from "joi";
import { validateFields } from "../../helper/joi-validation";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../auth-config";
import { useCookies } from "react-cookie";
import { IForgotPassword } from "../../models/forgotPassword";
import { alertConstants } from "../../helper/sweet-alert";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const EmailForForgotPassword = (props: IEmailForForgotPasswordDisplay) => {
  const navigate = useNavigate();
  const { resetPassword } = props;
  const [errors, setErrors] = useState<any>(undefined);
  const [disableButton, setDisableButton] = useState(false);
  const [data, setData] = useState<IEmail>({
    id: 0,
    email: "",
  });
  const schema = Joi.object().keys({
    email: Joi.string()
      .regex(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
      .required()
      .label("Enter valid email"),
  });
  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    errors && delete errors[event.target.name];
    const _requestDetails = {
      ...data,
      [event.target.name]: event.target.value,
    };
    setData(_requestDetails);
  };

  const onChangeMail = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    debugger;
    errors && delete errors[event.target.name];
    //Remove Unicode control characters and invisible characters
    var trimmedEmail = event.target.value.replace(
      /[\p{C}|\p{M}|\p{Z}|\p{S}]/gu,
      ""
    );
    const _data = {
      ...data,
      //spaceRemoval
      [event.target.name]: trimmedEmail.replace(/\s/g, ""),
    };
    setData(_data);
  };

  const sendMailToResetPassword = () => {
    setDisableButton(true);
    const validationErrors = validateFields(data, schema);
    if (validationErrors == undefined) {
      saveRequest();
    } else {
      setErrors(validationErrors);
      setDisableButton(false);
    }
  };

  const saveRequest = async () => {
    await resetPassword(data.email, callback);
  };

  const callback = (response: any) => {
    if (response === true) {
      pleaseCheckYourEmail();
    }
    //  else if(response===false){
    //  invalidUser();
    //  }
    else {
      somethingWentWrong();
    }
  };

  const pleaseCheckYourEmail = () => {
    toast.success("Please check your Email to reset password!!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        setDisableButton(true);
        navigate(ROUTER_URL.LOGIN);
      },
    });
  };

  const invalidUser = () => {
    toast.error("Invalid User!!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        setDisableButton(false);
      },
    });
  };

  const somethingWentWrong = () => {
    toast.error("Something Went Wrong!!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        setDisableButton(false);
      },
    });
  };

  const login = () => {
    navigate(ROUTER_URL.LOGIN);
  };

  return (
    <>
      <div className="login-wrap">
        <header>
          <a
            href="#"
            draggable="false"
            style={{ pointerEvents: "none" }}
            onDragStart={(e) => e.preventDefault()}
          >
            <img src="assets/images/logo.webp" alt="" draggable="false" />
          </a>
        </header>
        <main>
          <div className="login-card">
            <div className="title">
              <h3>Forgot Password?</h3>
            </div>
            <div className="form-group">
              <FloatingLabelTextInput
                label={"Email"}
                onChange={onChangeMail}
                isMandatory={true}
                value={data.email.trim()}
                name={"email"}
                placeholder={"Email"}
              />
              {errors && errors.email && (
                <div className="errors">{errors.email}</div>
              )}

              <div className="icon">
                <img src="assets/images/icons/email.svg" alt="" />
              </div>
            </div>
            {/*reset password*/}
            {data.email === "" || disableButton == true ? (
              <div className="form-group mt-4 mb-3">
                <button onClick={sendMailToResetPassword} disabled>
                  Submit
                </button>
              </div>
            ) : (
              <div className="form-group mt-4 mb-3">
                <button onClick={sendMailToResetPassword}> Submit</button>
              </div>
            )}

            <div className="d-flex align-items-center justify-content-center">
              <span className="txt-secondary fs-14">
                Already have an account?
                <a
                  className="fw-semibold txt-primary m-1"
                  onClick={login}
                  href="#"
                >
                  Sign in here
                </a>
              </span>
            </div>
          </div>
        </main>
        <footer>
          <p>@2023 Healthogenics</p>
        </footer>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

const mapDispatchToProps = {
  resetPassword: (email: string, callback: Function) =>
    resetPassword(email, callback),
};

export default connect(null, mapDispatchToProps)(EmailForForgotPassword);
