import { IApiResponse } from "../../models/IAPIResponse";
import APIService from "../../helper/api-service";
import { CommonData } from "../../constants/apiUrl";
import { ICommon } from "../../models/admin/testPurchased";

export default new (class CommonService {
  public getFocuses = async (): Promise<IApiResponse<ICommon>> => {
    try {
      let endPoint = CommonData.GET_FOCUS_ON;
      const response = await APIService.get(endPoint);
      return response.data as IApiResponse<ICommon>;
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<ICommon>;
      } else {
        throw error;
      }
    }
  };

  public getMedicalConditions = async (): Promise<IApiResponse<ICommon>> => {
    try {
      let endPoint = CommonData.GET_MEDICAL_CONDITIONS;
      const response = await APIService.get(endPoint);
      return response.data as IApiResponse<ICommon>;
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<ICommon>;
      } else {
        throw error;
      }
    }
  };

  public getStatuses = async (): Promise<IApiResponse<ICommon>> => {
    try {
      let endPoint = CommonData.GET_STATUS;
      const response = await APIService.get(endPoint);
      return response?.data as IApiResponse<ICommon>;
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<ICommon>;
      } else {
        throw error;
      }
    }
  };

  public getTestNames = async (): Promise<IApiResponse<ICommon>> => {
    try {
      let endPoint = CommonData.GET_TEST_NAME;
      const response = await APIService.get(endPoint);
      return response.data as IApiResponse<ICommon>;
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<ICommon>;
      } else {
        throw error;
      }
    }
  };

  public getGender = async (): Promise<IApiResponse<ICommon>> => {
    try {
      let endPoint = CommonData.GET_GENDER;
      const response = await APIService.get(endPoint);
      return response.data as IApiResponse<ICommon>;
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<ICommon>;
      } else {
        throw error;
      }
    }
  };
})();
