import { IApiResponse } from "../../models/IAPIResponse";
import { urlBinder } from "../../helper/url-binder";
import apiService from "../../helper/api-service";
import { SurveyList } from "../../constants/apiUrl";
import {
  ICommon,
  IFullProfileAdmin,
  ISurveyList,
} from "../../models/admin/surveyList";

export default new (class surveyListService {
  public getSurveyList = async (
    pageNumber: number,
    pageSize: number,
    keyword: string,
    focuson?: number,
    conditions?: number
  ): Promise<IApiResponse<Array<ISurveyList>>> => {
    try {
      let endPoint = urlBinder(SurveyList.GET_SURVEY_LIST, [
        pageNumber.toString(),
        pageSize.toString(),
        keyword,
        focuson,
        conditions,
      ]);
      const response = await apiService.get(endPoint);
      return response.data as IApiResponse<Array<ISurveyList>>;
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<Array<ISurveyList>>;
      } else {
        throw error;
      }
    }
  };

  public getSurveyListExport = async (
    keyword?: string,
    focuson?: number,
    conditions?: number
  ): Promise<IApiResponse<any>> => {
    try {
      let endPoint = urlBinder(SurveyList.GET_SURVEY_LIST_EXPORT, [
        keyword,
        focuson,
        conditions,
      ]);
      const response = await apiService.get(endPoint, {
        responseType: "blob",
      });
      return response.data as IApiResponse<any>;
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<any>;
      } else {
        throw error;
      }
    }
  };

  public getFullProfileAdmin = async (
    userId: number
  ): Promise<IApiResponse<Array<IFullProfileAdmin>>> => {
    try {
      let endPoint = urlBinder(SurveyList.GET_FULL_PROFILE_ADMIN, [userId]);
      const response = await apiService.get(endPoint);
      return response.data as IApiResponse<Array<IFullProfileAdmin>>;
    } catch (error: any) {
      if (error.response) {
        return error.response as IApiResponse<Array<IFullProfileAdmin>>;
      } else {
        throw error;
      }
    }
  };

  public editFullProfileAdmin = async (
    data: FormData
  ): Promise<boolean> => {
    try {
      let endPoint = urlBinder(
        SurveyList.EDIT_FULL_PROFILE_ADMIN,
        []
      );
      const response = await apiService.put(endPoint, data);
      return response?.data as boolean;
    } catch (error: any) {
      if (error.response) {
        return error.response as boolean;
      } else {
        throw error;
      }
    }
  };
})();
