import React from "react";

const Footer = () => {
  return (
    <>
      {" "}
      <footer>
        {/* <p>&copy; 2023 Healthogenics</p> */}
        <p>@ 2023 Healthogenics</p>
      </footer>
    </>
  );
};

export default Footer;
